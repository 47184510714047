import _ from "lodash";
import React from "react";

export const DropdownPagination: React.FC<{
  totalPages: number;
  currentPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onPageChange: (page: number) => void;
}> = (props) => {
  return (
    <div className="mt-3 d-flex justify-content-center align-items-center">
      {props.hasPreviousPage && (
        <button
          onClick={() => {
            props.onPageChange(props.currentPage - 1);
          }}
          className="btn btn-sm btn-outline-primary me-2"
        >
          Prev
        </button>
      )}
      <div>
        Showing
        <select
          onChange={(e) => {
            props.onPageChange(parseInt(e.target.value));
          }}
          value={props.currentPage}
          className="mx-2"
        >
          {props.totalPages > 1 &&
            _.times(props.totalPages).map((pNumber) => {
              return (
                <option
                  key={pNumber}
                  value={pNumber + 1}
                  // onClick={() => {
                  //   props.onPageChange(pNumber + 1);
                  // }}
                >
                  {pNumber + 1}
                </option>
              );
            })}
        </select>
        of {props.totalPages} pages
      </div>
      {props.hasNextPage && (
        <button
          onClick={() => {
            props.onPageChange(props.currentPage + 1);
          }}
          className="btn btn-outline-primary btn-sm ms-2"
        >
          Next
        </button>
      )}
    </div>
  );
};
