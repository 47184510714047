import { toast } from "react-toastify";
import auth from "../libs/auth";
import { AppConstant } from "../utils/constant";

const uri = "/flightApi/";
// window.location.hostname !== "localhost"
// process.env.NODE_ENV === "production"
// `${window.location.protocol}//${window.location.hostname}/flightApi/`
// ``
// : `http://localhost:9890/`;
// : `http://192.168.1.30:9890/`;
class APIUtils {
  getBaseUrl() {
    return uri;
  }

  async callPostAPI(apiUrl, payload = {}, baseUrl) {
    let options = await this.postOptions(payload);
    return this.callApi(apiUrl, options, baseUrl);
  }

  async callGetAPI(apiUrl, payload, baseUrl) {
    let options = await this.getOptions(payload);
    return this.callApi(apiUrl, options, baseUrl);
  }

  callApi(apiUrl, options, baseUrl) {
    let url = `${baseUrl ? baseUrl : this.getBaseUrl()}${apiUrl}`;
    return fetch(url, options);
  }

  async postOptions(payload) {
    const options = {};
    options.method = "POST";
    options.body = JSON.stringify(payload);
    options.headers = await this.getHeadersPost();
    return options;
  }

  async getOptions() {
    const options = {};
    options.method = "GET";
    options.headers = await this.getHeadersPost();

    return options;
  }

  async getHeadersPost() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const domain = window.location.host;
    const authToken = auth.userKey;
    if (authToken) headers.append("auth-token", authToken);
    const siteId = auth.siteId;
    if (siteId) headers.append("site-id", siteId);
    if (window.shouldlog) headers.append("shouldlog", true);
    let userKey = await auth.userKey;
    if (domain) headers.append("domain-name", domain);
    if (userKey) {
      headers.set(AppConstant.USER_KEY, userKey);
    }
    return headers;
  }
}

export default new APIUtils();
