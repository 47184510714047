import React from "react";

export const ErrorText: React.FC<{
  error?: string;
}> = (props) => {
  return props.error ? (
    <div>
      <div className="text-danger">{props.error}</div>
    </div>
  ) : null;
};
