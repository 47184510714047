import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import { RootState } from "redux/store";

export interface PaymentState {
  expiryTime: Moment | null;
  amount: number;
  remark: string | null;
  segments?: any;
  serviceName?: "flightBooking" | "fixedDepartureBooking" | "keyValue" | null;
  traceId: string | null;
  orderId: string | null;
  redirectTo: string | null;
  supplierSiteId: string | null;
  transactionId: any;
  // If this is set to true, payment will autodeduct and processed
  // otherwise user has to press "Pay From Wallet"
  isPayingOnline: boolean;
}

const initialState: PaymentState = {
  expiryTime: null,
  amount: 0,
  orderId: null,
  traceId: null,
  redirectTo: null,
  remark: null,
  serviceName: null,
  supplierSiteId: null,
  transactionId: null,
  isPayingOnline: false,
};

export const paymentSlice = createSlice({
  name: "payments",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPaymentAmount: (
      state,
      action: PayloadAction<{
        amount: number;
        remark: string;
        segments?: any;
        serviceName: "flightBooking" | "fixedDepartureBooking" | "keyValue";
        traceId: string;
        orderId: string;
        redirectTo: string;
        supplierSiteId: string | null;
      }>
    ) => {
      state.expiryTime = moment().add(15, "minutes");
      state.amount = action.payload.amount;
      state.redirectTo = action.payload.redirectTo;
      state.remark = action.payload.remark;
      state.serviceName = action.payload.serviceName;
      state.orderId = action.payload.orderId;
      state.traceId = action.payload.traceId;
      state.segments = action.payload.segments;
      state.supplierSiteId = action.payload.supplierSiteId;
      state.isPayingOnline = false;
      state.transactionId = null;
    },

    // DONOT change function name as it is also used in common.saga
    setTransactionId: (state, action: PayloadAction<number>) => {
      state.transactionId = { result: action.payload };
    },

    clearPaymentAmount: (state) => {
      state = initialState;
    },

    // If this action is dispatched, payment will autodeducted and processed
    // otherwise user has to press "Pay From Wallet"
    setIsPayingOnline: (state, action: PayloadAction<boolean>) => {
      state.isPayingOnline = action.payload;
    },
  },
});

export const PaymentActions = paymentSlice.actions;

export const selectPaymentSlice = (state: RootState) => {
  if (state.payment.expiryTime) {
    if (moment(state.payment.expiryTime).diff(moment(), "minutes") > 0) {
      return state.payment;
    }
  }
  return null;
};

export default paymentSlice.reducer;
