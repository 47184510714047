import { joiResolver } from "@hookform/resolvers/joi";
import { updateCustomerDetails } from "api/user";
import { BackdropDialog } from "components/common/BackdropDialog/BackdropDialog";
import { ErrorText } from "components/errors/ErrorText";
import Joi from "joi";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IntlTelInputWrapper } from "./IntlTelInputWrapper";
import { Spinner } from "react-bootstrap";

type PhoneWithPhoneCode = {
  phone: string;
  phoneCode: string;
};

enum PhoneNumberLabelEnum {
  Primary = "Primary",
  Secondary = "Secondary",
  Emergency = "Emergency",
}

type FormValues = {
  customerId: number;
  firstName: string;
  lastName: string;
  primaryPhone: PhoneWithPhoneCode;
  secondaryPhone: PhoneWithPhoneCode;
  emergencyPhone: PhoneWithPhoneCode;
  email: string;
  panNumber: string;
  gstNumber: string;
  companyName: string;
};

const getChangeDetailSchema = (isTravelAgent: boolean) => {
  const detailSchema: any = {
    customerId: Joi.number().required().label("Id"),
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().label("Last Name"),
    primaryPhone: Joi.object<PhoneWithPhoneCode>({
      phone: Joi.string()
        .allow("")
        .allow(null)
        .optional()
        .label("PrimaryPhone"),
      phoneCode: Joi.string().allow("").allow(null).optional(),
    }),
    secondaryPhone: Joi.object<PhoneWithPhoneCode>({
      phone: Joi.string()
        .allow("")
        .allow(null)
        .optional()
        .label("SecondaryPhone"),
      phoneCode: Joi.string().allow("").allow(null).optional(),
    }),
    emergencyPhone: Joi.object<PhoneWithPhoneCode>({
      phone: Joi.string()
        .allow("")
        .allow(null)
        .optional()
        .label("EmergencyPhone"),
      phoneCode: Joi.string().allow("").allow(null).optional(),
    }),
    email: Joi.string().allow("").allow(null).optional().label("Email"),
    panNumber: Joi.string()
      .optional()
      .allow(null)
      .allow("")
      .label("Pan Number"),
    gstNumber: Joi.string()
      .optional()
      .allow(null)
      .allow("")
      .label("Gst Number"),
    companyName: Joi.string()
      .allow("")
      .allow(null)
      .optional()
      .label("Company Name"),
  };

  if (isTravelAgent) {
    detailSchema["companyName"] = Joi.string().required().label("Company Name");
  }

  return Joi.object(detailSchema);
};
type errorKeys = "primaryPhone" | "secondaryPhone" | "emergencyPhone";
export const ChangeDetailsPopup: React.FC<{
  selectedUser: any;
  onClose: (shouldRefresh?: boolean) => void;
}> = (props) => {
  const getSelectedUserNum = (label: PhoneNumberLabelEnum) => {
    return props.selectedUser.phoneNumbers.find((pn) => pn.label === label)
      ?.phoneNumber;
  };
  const getSelectedUserPhoneCode = (label: PhoneNumberLabelEnum) => {
    const phoneCode = props.selectedUser.phoneNumbers.find(
      (pn) => pn.label === label
    )?.phoneCountryCode;
    return phoneCode === "INVALID" ? undefined : phoneCode;
  };
  const {
    handleSubmit,
    register,
    watch,
    getValues,
    formState,
    control,
    setError,
    clearErrors,
    setValue,
  } = useForm<FormValues>({
    resolver: joiResolver(
      getChangeDetailSchema(props.selectedUser.userType === "travelAgent")
    ),
    defaultValues: {
      customerId: props.selectedUser.users_id,
      firstName: props.selectedUser.firstName,
      lastName: props.selectedUser.lastName,
      primaryPhone: {
        phone: getSelectedUserNum(PhoneNumberLabelEnum.Primary),
        phoneCode: getSelectedUserPhoneCode(PhoneNumberLabelEnum.Primary),
      },
      secondaryPhone: {
        phone: getSelectedUserNum(PhoneNumberLabelEnum.Secondary),
        phoneCode: getSelectedUserPhoneCode(PhoneNumberLabelEnum.Secondary),
      },
      emergencyPhone: {
        phone: getSelectedUserNum(PhoneNumberLabelEnum.Emergency),
        phoneCode: getSelectedUserPhoneCode(PhoneNumberLabelEnum.Emergency),
      },
      email: props.selectedUser.email,
      panNumber: props.selectedUser.panNumber,
      gstNumber: props.selectedUser.gstNumber,
      companyName: props.selectedUser.companyName,
    },
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const errors = formState.errors;

  const setFieldError = async (key: errorKeys, isValid: boolean) => {
    if (isValid || !getValues(`${key}.phone`)) {
      clearErrors(key);
    } else {
      setError(key, {
        message: "Please enter a valid phone number",
      });
    }
  };

  const onSubmit = async (data: FormValues) => {
    if (!data.email && !data.primaryPhone.phone) {
      setError("email", {
        type: "manual",
        message: "Email or Primary Phone is required",
      });
      setError("primaryPhone", {
        type: "manual",
        message: "Email or Primary Phone is required",
      });
      return;
    }
    if (Object.keys(errors).length > 0) {
      return;
    }
    setIsUpdating(true);
    try {
      const updateUser = await updateCustomerDetails(data);
      if (updateUser.data?.success) {
        props.onClose(true);
      } else {
        throw new Error(updateUser.data.errorMessage);
      }
    } catch (error: any) {
      toast.error(error.message || "Something went wrong");
    }
    setIsUpdating(false);
  };

  return (
    <BackdropDialog
      open={true}
      onClose={() => {
        props.onClose();
      }}
    >
      <div className="text-start">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid">
            <h5 className="mb-3 text-center">
              <strong>Change Details</strong>
            </h5>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("firstName")}
                  />
                  <ErrorText error={errors.firstName?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("lastName")}
                  />
                  <ErrorText error={errors.lastName?.message} />
                </div>
              </div>
            </div>
            <div className="form-group">
              <IntlTelInputWrapper
                fieldId="primary-phone"
                label="Primary Phone"
                defaultValue={getValues("primaryPhone.phone")}
                dialCode={getValues("primaryPhone.phoneCode")}
                onChange={(phoneNumber, phoneCode, isValid) => {
                  setValue("primaryPhone.phone", phoneNumber);
                  setValue("primaryPhone.phoneCode", phoneCode);
                  setFieldError("primaryPhone", isValid);
                }}
                errorMessage={
                  errors.primaryPhone?.message ||
                  errors.primaryPhone?.phone?.message
                }
              />
            </div>
            <div className="form-group">
              <IntlTelInputWrapper
                fieldId="secondary-phone"
                label="Secondary Phone"
                defaultValue={getValues("secondaryPhone.phone")}
                dialCode={getValues("secondaryPhone.phoneCode")}
                onChange={(phoneNumber, phoneCode, isValid) => {
                  setValue("secondaryPhone.phone", phoneNumber);
                  setValue("secondaryPhone.phoneCode", phoneCode);
                  setFieldError("secondaryPhone", isValid);
                }}
                errorMessage={
                  errors.secondaryPhone?.message ||
                  errors.secondaryPhone?.phone?.message
                }
              />
            </div>
            <div className="form-group">
              <IntlTelInputWrapper
                fieldId="emergency-phone"
                label="Emergency Phone"
                defaultValue={getValues("emergencyPhone.phone")}
                dialCode={getValues("emergencyPhone.phoneCode")}
                onChange={(phoneNumber, phoneCode, isValid) => {
                  setValue("emergencyPhone.phone", phoneNumber);
                  setValue("emergencyPhone.phoneCode", phoneCode);
                  setFieldError("emergencyPhone", isValid);
                }}
                errorMessage={
                  errors.emergencyPhone?.message ||
                  errors.emergencyPhone?.phone?.message
                }
              />
            </div>
            <div className="form-group pt-2">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                {...register("email")}
              />
              <ErrorText error={errors.email?.message} />
            </div>

            {/* <pre>{JSON.stringify(props.selectedUser, null, 2)}</pre> */}
            {props.selectedUser.userType === "travelAgent" && (
              <div className="form-group">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("companyName")}
                />
                <ErrorText error={errors.companyName?.message} />
              </div>
            )}

            <div className="form-group">
              <label htmlFor="panNumber">Pan Number</label>
              <input
                type="text"
                className="form-control"
                {...register("panNumber")}
              />
              <ErrorText error={errors.panNumber?.message} />
            </div>

            <div className="form-group">
              <label htmlFor="gstNumber">Gst Number</label>
              <input
                type="text"
                className="form-control"
                {...register("gstNumber")}
              />
              <ErrorText error={errors.gstNumber?.message} />
            </div>
          </div>
          <div className="text-center mt-3">
            <button
              className={`btn btn-primary ${
                Object.keys(errors).length ? "btn-secondary" : "btn-primary"
              }`}
              disabled={Object.keys(errors).length ? true : false}
              type="submit"
            >
              {isUpdating ? (
                <div className="px-2">
                  <Spinner className="mx-4" size="sm" />
                </div>
              ) : (
                "Save changes"
              )}
            </button>
          </div>
        </form>
      </div>
    </BackdropDialog>
  );
};
