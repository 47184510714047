import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export interface ISuppliersState {
  refreshCounter: number;
}

const initialState: ISuppliersState = {
  refreshCounter: 0,
};

export const FixedDepartureQueueSlice = createSlice({
  name: "fixedDepartureQueue",
  initialState,
  reducers: {
    refreshFdQueue: (state) => {
      state.refreshCounter = state.refreshCounter + 1;
    },
  },
});

export const FixedDepartureQueueActions = FixedDepartureQueueSlice.actions;

export default FixedDepartureQueueSlice.reducer;
