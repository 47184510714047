import { SearchFlight } from "../actionTypes/searchFlight";
import { mapJSONData } from "../libs/functions";
import { ReturnTypeValues } from "../utils/journeyTypes";

const initialState = {};

export default function SearchFlightScreen(state = initialState, action) {
  switch (action.type) {
    case SearchFlight.GET_CALENDAR_FARE:
    case SearchFlight.SEARCH_FLIGHT:
      const { Sources, JourneyType } = action.payload;
      return {
        ...state,
        searchBy: {
          ...mapJSONData(action.payload),
          // isLCCSpecialReturn:
          //   JourneyType == ReturnTypeValues.LCC &&
          //   Sources &&
          //   state.sources &&
          //   state.sources.some(
          //     (c) => c.isLCCSpecialReturn && Sources.includes(c.value)
          //   ),
        },
        isLCCSpecialReturn:
          // eslint-disable-next-line eqeqeq
          JourneyType == ReturnTypeValues.LCC &&
          Sources &&
          state.sources &&
          state.sources.some(
            (c) => c.isLCCSpecialReturn && Sources.includes(c.value)
          ),
      };
    case SearchFlight.SEARCH_FLIGHT_SUCCESS:
      return {
        ...state,
        ...action.result,
      };

    case SearchFlight.SEARCH_FLIGHT_SET_SHOW_LOADER:
      return {
        ...state,
        loadingResults: action.result
      };
    default:
      return { ...state };
  }
};
