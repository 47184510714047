import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";

const UserSelect: React.FC<{
  label: any;
  value: any;
  name: any;
  handleChange: any;
  options: any;
  addFirstItem?: any;
  errors?: any;
  disabled?: any;
}> = ({
  label,
  value,
  name,
  handleChange,
  options,
  addFirstItem = undefined,
  errors = undefined,
  disabled = undefined,
}) => {
  const onChange = (event) => {
    handleChange(name, event.target.value);
  };

  const error = errors ? errors[name] : null;

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel htmlFor="outlined-age-native-simple" shrink={true}>
        {label}
      </InputLabel>
      <Select
        native
        labelId="label"
        value={value ? value : ""}
        label={label}
        disabled={disabled}
        inputProps={{
          name: "class",
          id: name,
        }}
        onChange={onChange}
        error={error ? true : false}
      >
        {addFirstItem && <option>Select</option>}
        {options &&
          options.map((c, index) => (
            <option value={c.value} key={index}>
              {c.text}
            </option>
          ))}
      </Select>

      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default UserSelect;
