import React from 'react'
import 'assets/css/comingSoon/comingSoon.css'
import img from 'assets/images/common/comingSoon.jpeg'
export default function ComingSoon (){

    return(
        <div className="comingSoonPage">
            <div className="imgBox">
            <img src={img} alt="Coming soon" />
            </div>
            <h1>
                Coming Soon
            </h1>
            
        </div>
    )
}