import React from "react";
import { Slider, Typography } from "@material-ui/core";

export default function UserSlider({
  label,
  value,
  name,
  handleChange,
  minValue,
  maxValue,
  marks,
}) {
  const onChange = (event, value) => {
    handleChange(name, value);
  };

  return (
    <>
      <Typography id="discrete-slider-always" gutterBottom>
        {label}
      </Typography>
      <Slider
        value={value ? value : null}
        onChange={onChange}
        step={1}
        defaultValue={[minValue, maxValue]}
        min={minValue}
        max={maxValue}
        marks={marks}
      />
    </>
  );
};
