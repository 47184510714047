import { BookFlight } from "../actionTypes/bookFlight";

const initialState = {};

export default function bookFlightReducer(state: any = initialState, action) {
  switch (action.type) {
    case BookFlight.INIT_BOOK_FLIGHT:
      return {
        ...initialState,
      };
    case BookFlight.BOOK_FLIGHT_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    case BookFlight.SET_FARE_DETAILS:
      const { fareDetailResult } = action.result;
      return {
        ...state,
        fareDetailResult: {
          ...state.fareDetailResult,
          ...fareDetailResult,
        },
      };
    case BookFlight.SET_IS_BOOKING_IN_PROCESS:
      return {
        ...state,
        isBookingInProcess: action.payload,
      };
    case BookFlight.SET_PASSENGER_DETAILS:
      return {
        ...state,
        passengerDetails: action.payload,
      };
    case BookFlight.SET_PAYMENT_ORDER_ID:
      return {
        ...state,
        paymentOrderId: action.payload,
      };
    default:
      return { ...state };
  }
}
