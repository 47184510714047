import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { CheckCircle, ChevronRight } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { MarkupAndDiscountApi } from "api/flightApis/MarkupAndDiscount.api";
import { SET_SITE_USER_GROUPS } from "redux/actions/siteDataActions";
import { ILEGroup } from "./flightApis/AirlineDiscountMarkup/types";
import { toast } from "react-toastify";
import { SET_LOADING } from "redux/actions/commonActions";
import _ from "lodash";
import { changeRelationConfig } from "api/supplier";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  nonSelected: {
    backgroundColor: "transparent",
    color: "#333",
  },
});

export const CustomerGroupChangeDialog: React.FC<{
  isB2B: boolean;
  open: boolean;
  onClose: Function;
  onChange: Function;
  LEGroupMappings: any;
  legalEntityId: number | undefined;
  selectedGroupId: number | undefined;
  getLegalEntityGroupMapping: Function;
  LERelationConfig?: {
    isTdsDeductable: boolean;
  };
}> = (props) => {
  const [open, setOpen] = useState(props.open);
  // const [deductTdsState, setDeductTdsState] = useState(false);
  // const [newGroupInput, setNewGroupInput] = useState("");
  // const [selectedGroupId, setSelectedGroupId] = useState<number>();

  const classes = useStyles();

  const dispatch = useDispatch();
  // const [groupLimit, setGroupLimit] = useState<number>(5);

  const userGroupsList = useSelector((state: any) => state.userGroups);

  const getUsersGroup = async () => {
    const response = await MarkupAndDiscountApi.getWlUserGroupsApiCall();
    // console.log({ getUsersGroup: response });
    dispatch(SET_SITE_USER_GROUPS(response.result));
  };

  useEffect(() => {
    getUsersGroup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfGroupMapped = (group) => {
    const isMapped = _.find(props.LEGroupMappings, (obj) => {
      if (obj.legalEntityGroupsId === group.id) return true;
    });
    if (isMapped) return true;
    else return false;
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
  };

  // useEffect(() => {
  //   if (props.selectedGroupId) {
  //     setSelectedGroupId(props.selectedGroupId);
  //   }
  // }, [props.selectedGroupId]);

  const handleGroupItemClick = async (groupId: number) => {
    // props.onClose();
    dispatch(SET_LOADING(true));
    try {
      if (props.legalEntityId) {
        const response =
          await MarkupAndDiscountApi.mapLEIdWithWlLEGroupIdApiCall(
            groupId,
            props.legalEntityId
          );
        if (response.success) {
          // setSelectedGroupId(groupId);
          props.getLegalEntityGroupMapping(props.legalEntityId);
          toast.success("Group successfully changed");
        } else {
          if (response.errorMessage) {
            toast.error(response.errorMessage);
          } else {
            toast.error("Some error occurred");
          }
        }
        props.onChange();
      }
    } catch (err) {}
    dispatch(SET_LOADING(false));
  };

  const handleTdsDeductChange = async (deductTds: boolean) => {
    // props.onClose();
    dispatch(SET_LOADING(true));
    try {
      if (props.legalEntityId) {
        const response = await changeRelationConfig({
          // isTdsDeductable: !props.LERelationConfig?.isTdsDeductable,
          isTdsDeductable: deductTds,
          userLEID: props.legalEntityId,
        });
        if (response.data.success) {
          // setSelectedGroupId(groupId);
          props.getLegalEntityGroupMapping(props.legalEntityId);
          // setDeductTdsState(dedTds => !dedTds)
          toast.success("Tds deduction successfully changed.");
        } else {
          if (response.data.errorMessage) {
            throw new Error(response.data.errorMessage);
          } else {
            throw new Error();
          }
        }
        props.onChange();
      }
    } catch (err: any) {
      toast.error(err.message || "Some error occurred");
    }
    dispatch(SET_LOADING(false));
  };

  // const handleGroupChange = (e: any) => {
  //   setNewGroupInput(e.target.value);
  // }

  // const addNewGroup = () => {
  //   if (renamingGroupId) {
  //     let newGroupList = groupList.map(gr => {
  //       let newGroupId = newGroupInput;
  //       if (gr._id === renamingGroupId) {
  //         newGroupId = renamingGroupId;
  //         return {
  //           _id: newGroupInput,
  //           groupName: newGroupInput
  //         }
  //       }
  //       return gr;
  //     })
  //     setRenamingGroupId(undefined)
  //     setNewGroupInput("");
  //     setGroupList(newGroupList);
  //   } else if (groupLimit > groupList.length) {
  //     groupList.push({
  //       _id: newGroupInput,
  //       groupName: newGroupInput
  //     })
  //     setNewGroupInput("");
  //   }
  // }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Group</DialogTitle>
        <DialogContent>
          <List>
            {/* <pre>{JSON.stringify(userGroupsList, null, 2)}</pre> */}
            {userGroupsList?.userGroups?.length &&
              userGroupsList.userGroups.map((gr: ILEGroup, index) => {
                let isSelected = checkIfGroupMapped(gr);
                // gr.id === selectedGroupId;
                return (
                  <>
                    <ListItem
                      button
                      onClick={() => handleGroupItemClick(gr.id)}
                      key={index}
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={
                            isSelected ? classes.avatar : classes.nonSelected
                          }
                        >
                          {isSelected ? <CheckCircle /> : <ChevronRight />}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={gr.groupName} />
                    </ListItem>
                  </>
                );
              })}
          </List>
          {props.isB2B && (
            <>
              <Typography>
                <b>Tax Settings</b>
              </Typography>
              <button
                onClick={() => {
                  handleTdsDeductChange(true);
                }}
                className={`btn btn-${
                  props.LERelationConfig?.isTdsDeductable === true
                    ? "success"
                    : "outline-dark"
                } me-2`}
              >
                Deduct TDS
              </button>
              <button
                onClick={() => {
                  handleTdsDeductChange(false);
                }}
                className={`btn btn-${
                  props.LERelationConfig?.isTdsDeductable === false
                    ? "success"
                    : "outline-dark"
                }`}
                // className="btn btn-outline-danger"
              >
                Do not Deduct TDS
              </button>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(props.LERelationConfig?.isTdsDeductable)}
                    onChange={() => {
                      handleTdsDeductChange();
                    }}
                    name="deductTds"
                    color="primary"
                  />
                }
                label=" Deduct TDS"
              /> */}
            </>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};
