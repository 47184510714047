import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import { common } from "redux/actionTypes/commonTypes";
import { wlUserBalance, paymentByUserApi } from "../../api/user";
import { getBankDetailsApi } from "../../api/siteData";
import { paymentActionTypes } from "redux/actionTypes/paymentTypes";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

function* setAlertMessage(action) {
  yield put({
    type: common.SHOW_ALERT_MESSAGE,
    payload: { message: action.payload, show: true },
  });
}

function* getWlUserWalletBalance(action) {
  const wlBalance = yield call(wlUserBalance, action.payload);
  if (wlBalance) {
    yield put({
      type: common.SET_WL_USER_WALLET_BALANCE,
      payload: wlBalance.data,
    });
  }
}

function* getBankDetails(action) {
  const bankDetails = yield call(getBankDetailsApi, action.payload);
  if (bankDetails) {
    yield put({
      type: common.SET_BANK_DETAILS,
      payload: bankDetails,
    });
  }
}

function* getPaymentEntry(action) {
  yield put({
    type: common.PAYMENT_LOADING,
    payload: true,
  });
  const payment = yield call(paymentByUserApi, action.payload);
  yield put({
    type: common.PAYMENT_LOADING,
    payload: false,
  });
  if (!payment) {
    toast.error("Payment unsuccessful");
  } else if (payment.data.err) {
    toast.error(payment.data.err);
  } else {
    // yield put({
    //   type: common.GET_WL_USER_WALLET_BALANCE,
    //   userId: action.payload.userId,
    // });
    yield put({
      type: "payments/setTransactionId",
      payload: payment.data.result,
    });
    yield put(
      push(action.payload.redirectTo, {
        transactionId: payment.data,
        orderId: action.payload.orderId, // in case of Fixed departure
        traceId: action.payload.traceId, // in case of Fixed departure
      })
    );
  }
}

export function* watchCommonActions() {
  yield takeEvery(common.SET_ALERT_MESSAGE, setAlertMessage);
  yield takeEvery(common.GET_WL_USER_WALLET_BALANCE, getWlUserWalletBalance);
  yield takeEvery(common.GET_BANK_DETAILS, getBankDetails);
  yield takeLatest(paymentActionTypes.GET_PAYMENT_ENTRY, getPaymentEntry);
}
