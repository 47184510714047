import React from 'react'
import Grid from '@material-ui/core/Grid'

export default function index(props) {
    return (
        <Grid item {...props} elevation={3}>
            {props.children}
        </Grid>
    )
}
