import Cookies from "js-cookie";
import { AppConstant } from "../utils/constant";
import { getAuthToken } from "tools/functions";

const { USER_KEY, SEARCH_ID } = AppConstant;

class auth {
  get isAuthenticated() {
    return !!getAuthToken();
  }
  get userKey() {
    return getAuthToken();
  }

  get searchId() {
    return localStorage.getItem(SEARCH_ID);
  }

  setItem(key, data) {
    localStorage.setItem(key, data);
  }

  get siteId() {
    const siteData = JSON.parse(localStorage.getItem("siteData"));
    // if (siteData) {
      return siteData?._id;
    // }
  }

  get userDetails() {
    try {
      const user = JSON.parse(localStorage.getItem("userData"));
      if (user) {
        return user?.staff;
      }
      return {}
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  get loggedInUserDetails() {
    try {
      const user = JSON.parse(localStorage.getItem("userData"));
      if (user) {
        return user;
      }
      return {}
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  get userType() {
    try {
      const user = JSON.parse(localStorage.getItem("userData"));
      if (user) {
        return user?.userType;
      }
      return {}
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  get siteDetails() {
    try {
      const site = JSON.parse(localStorage.getItem("siteData"));
      if (site) {
        return site;
      }
      return {}
    } catch (e) {
      console.log(e);
      return {};
    }
  }
}

export default new auth();
