import React from 'react'
import {Paper as MuiPaper} from '@material-ui/core'

export const Paper = (props) => {
    const {children} = props
    return (
        <MuiPaper {...props} >
            {children}
        </MuiPaper>
    )
}

