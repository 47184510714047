import { takeLatest, call, put } from "redux-saga/effects";
import TicketAPI from "../api/TicketAPI";
import callAPI from "./helper";
import { Ticket } from "../actionTypes/ticket";

function* getTickets(action) {
  yield call(callAPI, TicketAPI.getTickets, action, setResult);
}

function* getBookedFlights(action) {
  yield call(callAPI, TicketAPI.getBookedFlights, action, setResult);
}

function* getTicketDetails(action) {
  yield call(callAPI, TicketAPI.getTicketDetails, action, setResult);
}

function* releaseFlight(action) {
  yield call(callAPI, TicketAPI.releaseFlight, action, setResult);
}

function* changeRequest(action) {
  yield call(callAPI, TicketAPI.changeRequest, action, setResult);
}

function* updateBookingDetails(action) {
  yield call(callAPI, TicketAPI.updateBookingDetails, action, setResult);
}

function* getCC(action) {
  yield call(callAPI, TicketAPI.getCC, action, action.cb);
}

function* getCRStatus(action) {
  yield call(callAPI, TicketAPI.getCRStatus, action, updateCRStatusToTicket)
}

function* updateCRStatusToTicket(result) {
  yield put({ type: Ticket.UPDATE_CR_STATUS, result });
}

function* setResult(result) {
  yield put({ type: Ticket.TICKET_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Ticket.GET_BOOKED_FLIGHTS, getBookedFlights);
  yield takeLatest(Ticket.RELEASE_FLIGHT, releaseFlight);
  yield takeLatest(Ticket.GET_TICKETS, getTickets);
  yield takeLatest(Ticket.GET_TICKET_DETAILS, getTicketDetails);
  yield takeLatest(Ticket.CHANGE_REQUEST, changeRequest);
  yield takeLatest(Ticket.UPDATE_BOOKING_DETAILS, updateBookingDetails);
  yield takeLatest(Ticket.GET_CC, getCC);
  yield takeLatest(Ticket.GET_CR_STATUS, getCRStatus);
}
