import { useAppSelector } from "flight-react/hooks/reduxHooks";
import React from "react";
import StaffMenu from "./StaffMenu";
import UserMenu from "./userMenu";

export const CommonMenu = () => {
  const userData = useAppSelector((state) => state.user);

  return <>{userData.userType === "staff" ? <StaffMenu /> : <UserMenu />}</>;
};
