import { registerServiceWorker } from "components/afterLogin/push-notification/pushNotification";
import { useAppSelector } from "flight-react/hooks/reduxHooks";
import { useEffect } from "react";

export const usePushNotificationSubscription = () => {
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user.userType === "staff") {
      const timer = setTimeout(() => {
        registerServiceWorker();
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [user.id]);
};
