import searchFlight from "./searchFlight";
import bookFlight from "./bookFlight";
import ticket from "./ticket";
import progress from "./progress";
import response from "./response";
import payment from "../slices/payment.slice";
import order from "../slices/order.slice";
import halfSignup from "../slices/halfSignup.slice";

export const flightReducers = {
  searchFlight,
  bookFlight,
  ticket,
  progress,
  response,
  payment,
  order,
  halfSignup,
};
