import React, { useEffect, useState } from "react";
import { formatDisplayDateTime } from "../../../libs/functions";
import MuiGrid from "../../../components/Grid/item";
import FlightDetailSummary from "./FlightDetailSummary";

// const headers = [
//   { id: "flightNumber", label: "Flight No" },
//   { id: "airline", label: "Airline" },
//   { id: "originCityCode", label: "Origin" },
//   { id: "destinationCityCode", label: "Destination" },
//   { id: "departureTime", label: "Departure Time" },
//   { id: "arrivalTime", label: "Arrival Time" },
//   { id: "fareClass", label: "Class" },
// ];

export default function FlightDetail({ selectedFlight }) {
  const [flightDetails, setFlightDetails] = useState([]);

  useEffect(() => {
    if (selectedFlight) {
      const { segments } = selectedFlight;
      setFlightDetails(
        segments.map((c) => ({
          ...c,
          departureTime: formatDisplayDateTime(c.departureTime),
          arrivalTime: formatDisplayDateTime(c.arrivalTime),
          airline: `${c.airlineName} (${c.airlineCode})`,
        }))
      );
    }
  }, [selectedFlight]);

  return (
    <>
      <MuiGrid xs={12}></MuiGrid>
      <MuiGrid xs={12}>
        {flightDetails &&
          flightDetails.map((flight, i) => (
            <FlightDetailSummary key={i} flight={flight} />
          ))}

        {/* <UserTable headers={headers} rows={flightDetails} /> */}
      </MuiGrid>
      <MuiGrid xs={12}></MuiGrid>
    </>
  );
};
