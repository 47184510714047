import React from "react";
import { Spinner } from "./tools/Spinner";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
export default function CommonComponents() {
  const commonData = useSelector((state) => state.common);

  return (
    <>
      <ToastContainer />
      <Spinner loading={commonData.loading} />
    </>
  );
}
