import { getRequest, postRequest } from "api/tools/httpRequests";
import { AxiosResponse } from "axios";
import { IJSONResponse } from "components/afterLogin/dashboard/flightApis/AirlineDiscountMarkup/types";
import { IUserData } from "types/common.types";

export const generatePasswordWlUser = async (data) => {
  const r = await postRequest("wl/user/generatePassword", data);
  return r;
};

export const postForgetPassword = async (
  data,
  headers: { "captcha-token": string }
) => {
  const r = await postRequest(
    "wl/user/forgetPassword",
    data,
    undefined,
    headers
  );
  return r;
};

export const wlUserSearchDb = async (data) => {
  return await postRequest("wl/user/searchWlUser", data);
};

export const getUserById = async (id: number) => {
  const response = await postRequest(`wl/user/getUserById`, { id });
  return response;
};

export const getUserByUUID = async (uuid: string) => {
  const response: AxiosResponse<IJSONResponse<IUserData>> = await postRequest(
    `wl/user/getUserByUUID`,
    { uuid }
  );
  return response;
};

export const loginBySessionId = async (sessionId: string) => {
  const response = await postRequest(`wl/user/loginBySessionId`, {
    sessionId,
  });
  return response;
};

export const logout = async () => {
  const response = await postRequest(`wl/user/logout`, {});
  return response;
};

export const getUserBySessionId = async (sessionId: string) => {
  const response = await postRequest(`wl/user/getUserBySessionId`, {
    sessionId,
  });
  return response;
};

export const transactionSearchByPNR = async (data) => {
  return await postRequest("wl/user/searchTransactionByPNR", data);
};

export const addWlUserWalletEntry = async (data) => {
  return await postRequest("wl/user/addWlUserWalletEntry", data);
};

export const getWlUserWalletEntry = async (data) => {
  return await postRequest("wl/user/getWlUserWalletEntry", data);
};

export const getMyWalletEntry = async (data) => {
  return await postRequest("wl/user/getMyWalletEntry", data);
};

export const wlUserBalance = async (data) => {
  return await postRequest("wl/user/getWlUserWalletBalance", data);
};

export const paymentByUserApi = async (data) => {
  return await postRequest("wl/user/userPaymentEntry", data);
};

export const getCustomers = async (data) => {
  return await postRequest("wl/user/getCustomers", data);
};

export const getEmailSubscribers = async (data) => {
  return await postRequest("wl/user/getEmailSubscribers", data);
};

export const toggleBlockCustomers = async (data) => {
  return await postRequest("wl/user/toggleBlock", data);
};
export const updateCustomerDetails = async (data) => {
  return await postRequest("wl/user/updateDetails", data);
};

export const toggleFlightSearchExemption = async (data) => {
  return await postRequest("wl/user/toggleFlightSearchExemption", data);
};
export const getCustomerMonthlyFlightDetails = async (data) => {
  return await postRequest("wl/user/getCustomerMonthlyFlightDetails", data);
};

export const getCustomerDataToStaff = async (data) => {
  return await postRequest("wl/user/getCustomerDataToStaff", data);
};

export const getWlUserGroups = async () => {
  return await postRequest("wl/user/", {});
};

export const supplierJumpLoginApiCall = async (supplierId) => {
  return await postRequest("wl/user/jumplogin", {
    supplierId,
  });
};

export const getMyLegalEntity = async () => {
  return await getRequest("wl/user/getMyLegalEntity", {});
};

export const sendGoogleLoginRequest = async (props: { token: string }) => {
  return await postRequest("wl/user/googleLogin", props);
};
export const sendFacebookLoginRequest = async (props: {
  token: string;
  email: string | undefined;
}) => {
  return await postRequest("wl/user/facebookLogin", props);
};

export const userSignup = async (
  signupData,
  headers: { "captcha-token": string }
) => {
  return await postRequest("wl/user/signup", signupData, undefined, headers);
};

// export const userHalfSignup = async (signupData) => {
//   return await postRequest("user/createDetailPendingCustomer", signupData);
// };
export const userHalfSignupVerifyOtp = async (signupData) => {
  return await postRequest("wl/user/halfSigmup", signupData);
};

export const userPartialSignup = async (signupData) => {
  return await postRequest("user/createDetailPendingCustomer", signupData);
};

export const userPartialSignupForDmc = async (signupData) => {
  return await postRequest(
    "user/createDetailPendingCustomerForDmc",
    signupData
  );
};

export const userValidateOTP = async ({
  userName,
  otp,
}: {
  otp: string;
  userName: string;
}) => {
  return await postRequest("user/verifyOtpForUser", {
    userName,
    otp,
  });
};

export const userResendOtp = async ({ userName }: { userName: string }) => {
  return await postRequest("user/requestOtpForLogin", {
    userName,
  });
};

export const getPermissionFromSession = async () => {
  const response = await postRequest(`rbac/getPermissionsFromSession`, {});
  return response;
};
