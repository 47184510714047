import { Ticket } from "../actionTypes/ticket";

const initialState = {};

export default function TicketReducer(state = initialState, action) {
  switch (action.type) {
    case Ticket.TICKET_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
      
    case Ticket.UPDATE_CR_STATUS:
      const tickets = state.tickets.map((ticket) => {

        if(ticket.passenger.find(pax => {
          return pax.ticket.ticketId === action.result.changeRequestInfo.ticketId
        })){
          return {
            ...ticket,
            supplierChangeReqStatus: action.result.changeRequestInfo
          };
        }

        return ticket;
      });

      return {
        tickets,
      };
    default:
      return { ...state };
  }
}
