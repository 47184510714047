import { ErrorText } from "components/errors/ErrorText";
import React, { useRef } from "react";
import Select from "react-select";

export interface IOption {
  label: string;
  value: string;
  [key: string]: any;
}

export const SelectWrapper: React.FC<{
  options: Array<IOption>;
  onChange: (value: any) => void;
  styles?: any;
  onInputChange?: (input: string) => void;
  value?: string | number | undefined;
  className?: string;
  controlClassName?: string;
  inputClassName?: string;
  isLoading?: boolean;
  placeholder?: string;
  errorMessage?: string;
}> = (Props) => {
  const timer = useRef<NodeJS.Timer>();

  const customStyles = {
    control: (base) => ({
      ...base,
      paddingTop: "4px",
      paddingBottom: "4px",
      borderRadius: "5px",
    }),
  };

  return (
    <>
      <Select
        placeholder={Props.placeholder}
        onChange={(selected) => {
          Props.onChange(selected);
        }}
        onInputChange={(input) => {
          if (timer.current) {
            clearTimeout(timer.current);
          }
          timer.current = setTimeout(() => {
            Props.onInputChange && Props.onInputChange(input);
          }, 500);
        }}
        className={`${Props.className} text-dark`}
        value={Props.options?.filter((option) => {
          return option.value === Props.value;
        })}
        options={Props.options}
        isClearable
        isLoading={Props.isLoading}
        styles={Props.styles ? Props.styles : customStyles}
      />
      <ErrorText error={Props.errorMessage} />
    </>
  );
};
