import { common } from "../actionTypes/commonTypes";

const initialState = {
  loading: false,
  paymentLoading: false,
  alert: {
    showErrorMessage: false,
    errorMessage: "",
  },
  userWalletBalance: 0,
  bankDetails: {},
  headerPosition: "static",
};

export default function CommonReducer(state = initialState, action) {
  switch (action.type) {
    case common.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case common.PAYMENT_LOADING:
      return {
        ...state,
        paymentLoading: action.payload,
      };

    case common.SHOW_ALERT_MESSAGE:
      let showErrorMessage = false;
      if (action.payload.message) {
        showErrorMessage = true;
      }
      return {
        ...state,
        alert: {
          showErrorMessage,
          errorMessage: action.payload.message,
        },
      };

    case common.HIDE_ALERT_MESSAGE:
      return {
        ...state,
        alert: {
          ...state.alert,
          showErrorMessage: false,
        },
      };

    case common.SET_WL_USER_WALLET_BALANCE:
      return {
        ...state,
        userWalletBalance: action.payload,
      };

    case common.SET_BANK_DETAILS:
      return {
        ...state,
        bankDetails: action.payload,
      };

    case common.SET_HEADER_POSITION:
      return {
        ...state,
        headerPosition: action.payload.position,
      };

    default:
      return state;
  }
}
