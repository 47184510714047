import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Cancel, CheckCircleOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  DialogContainer: {
    position: "fixed",
    left: 0,
    width: "100%",
    top: 0,
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  DialogInner: {
    paddingTop: 50,
    paddingBottom: 50,
    position: "fixed",
    top: "50%",
    transform: "translateY(-50%)",
  },
  DialogContent: {
    backgroundColor: "#fff",
    width: 500,
    overflowY: "auto",
    height: "100%",
    maxHeight: "90vh",
    padding: 15,
    borderRadius: 10,
    textAlign: "center",

    // maxWidth: "79%",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
}));

export const BackdropDialog: React.FC<{
  open: boolean;
  onClose?: () => void | undefined;
  alertType?: "success" | "error";
}> = (props) => {
  const classes = useStyles();

  const [openState, setOpenState] = useState(false);
  // const [openState, setOpenState] = useState(false)

  useEffect(() => {
    setOpenState(props.open);

    document.body.style.overflow = props.open ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [props.open]);

  return openState ? (
    <div className={classes.DialogContainer}>
      <div className={classes.DialogInner}>
        <div className="position-relative">
          {props.onClose && (
            <CloseIcon
              onClick={props.onClose}
              style={{
                position: "absolute",
                right: 10,
                top: 10,
                cursor: "pointer",
              }}
            />
          )}
          <div className={classes.DialogContent}>
            {props.alertType &&
              (props.alertType === "success" ? (
                <CheckCircleOutline
                  style={{
                    fontSize: 50,
                  }}
                  color="primary"
                />
              ) : props.alertType === "error" ? (
                <Cancel
                  style={{
                    fontSize: 50,
                  }}
                  color="error"
                />
              ) : null)}
            {props.children}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
