import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export interface SignupState {
  isPopupOpen: boolean;
  submitFormTimes: number;
  isOtpSent: boolean;
}

const initialState: SignupState = {
  isPopupOpen: false,
  submitFormTimes: -1,
  isOtpSent: false,
};

export const halfSignupSlice = createSlice({
  name: "halfSignup",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPopupOpen: (state) => {
      state.isPopupOpen = true;
    },
    setPopupClose: (state) => {
      state.isPopupOpen = false;
    },
    submitForm: (state) => {
      state.submitFormTimes++;
    },
    resetSubmitFormTimes: (state) => {
      state.submitFormTimes = -1;
      // state.isPopupOpen = false;
    },
    setOtpSent: (state, action: PayloadAction<boolean>) => {
      state.isOtpSent = action.payload;
    },
  },
});

export const HalfSignupActions = halfSignupSlice.actions;

export const selectHalfSignup = (state: RootState) => {
  return state.halfSignup;
};

export default halfSignupSlice.reducer;
