import { groupBy, map, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserCard } from "../../components/Container";
import { UserHeader } from "../../components/Header";
import { UserLabel as DisplayLabel } from "../../components/Label";
import { formatPrice } from "../../libs/functions";
import { getPaxType } from "../../utils/paxType";
import { FlightDetail } from "../Common";
import usePassenger from "../Common/usePassenger";
import MuiGrid from "../../components/Grid/item";
import MuiLink from "../../components/Link";
import { setFlight } from "../../actions/bookFlight";
import auth from "../../libs/auth";

const UserLabel = ({ label, value }) => (
  <DisplayLabel label={label} value={value} />
);

export const PassengerTypeFare = ({
  fareDetails,
  currency,
  hideTransactionFee,
}) => {
  return (
    <>
      {fareDetails &&
        fareDetails.map((c) => (
          <div className="col-12" key={c.passengerType}>
            <UserLabel
              label={getPaxType(c.passengerType)}
              value={formatPrice(c.baseFare, currency)}
            />
            <UserLabel label="Tax" value={formatPrice(c.tax, currency)} />
            {!hideTransactionFee && (
              <UserLabel
                label="T. Fee and S.Charges"
                value={formatPrice(c.offeredOtherCharges, currency)}
              />
            )}
            <UserLabel
              label="Total"
              value={formatPrice(c.publishedPrice, currency)}
            />
            <hr />
          </div>
        ))}
    </>
  );
};

// const PassengerTypeFareDetails = ({ fareDetails, currency }) => {
//   return (
//     <>
//       {fareDetails &&
//         fareDetails.map((c) => (
//           <div className="col-12" key={c.passengerType}>
//             <div className="row">
//               <div className="col-6"></div>
//               <div className="col-6">Fare Breakup</div>
//               <div className="col-6">{getPaxType(c.passengerType)}</div>
//               <div className="col-6">{formatPrice(c.baseFare, currency)}</div>
//               {c.taxDetails && (
//                 <>
//                   <div className="col-6">OT Tax</div>
//                   <div className="col-6">{c.taxDetails.ot}</div>
//                   <div className="col-6">YQ Tax</div>
//                   <div className="col-6">{c.taxDetails.yq}</div>
//                   <div className="col-6">YR Tax</div>
//                   <div className="col-6">{c.taxDetails.yr}</div>
//                 </>
//               )}
//               <div className="col-6">T. Fee and S.Charges</div>
//               <div className="col-6">
//                 {formatPrice(c.publishedOtherCharges, currency)}
//               </div>
//               <div className="col-6">Total</div>
//               <div className="col-6">
//                 {formatPrice(c.publishedFare, currency)}
//               </div>
//             </div>
//             <hr />
//           </div>
//         ))}
//     </>
//   );
// };

const TotalFareDetails = ({
  totalFare,
  commissionEarned,
  markup,
  gstOnMarkup,
  tds,
  discount,
  totalPayable,
  totalPayableAmt,
  currency,
}) => {
  return (
    <div className="col-12">
      <UserLabel label="Total Fare" value={formatPrice(totalFare, currency)} />
      {/* <UserLabel
        label="Service Fee"
        value={formatPrice(serviceFee, currency)}
      />
      <UserLabel
        label="Other Charges"
        value={formatPrice(otherCharges, currency)}
      /> */}
      {/* <UserLabel
        label="Comm. Earned (-)"
        value={formatPrice(commissionEarned, currency)}
      /> */}
      {/* <UserLabel
        label="Transaction Fee (-)"
        value={formatPrice(transactionFee, currency)}
      /> */}
      {/* <UserLabel
        label="TDS (+)"
        value={formatPrice(tdsOnCommission, currency)}
      /> */}
      {/* <UserLabel
        label="PLB Earned (-)"
        value={formatPrice(plbEarned, currency)}
      /> */}
      {/* <UserLabel
        label="TDS on PLB (+)"
        value={formatPrice(tdsOnPLB, currency)}
      /> */}
      {/* <UserLabel
        label="Incntv Earned (-)"
        value={formatPrice(incentiveEarned, currency)}
      /> */}
      {markup > 0 ? (
        <>
          <UserLabel
            label="Service Fee"
            value={formatPrice(markup, currency)}
          />
          <UserLabel
            label="GST on Service Fee"
            value={formatPrice(gstOnMarkup, currency)}
          />
        </>
      ) : (
        <>
          <UserLabel label="TDS (+)" value={formatPrice(tds, currency)} />
          <UserLabel
            label="Discount (-)"
            value={formatPrice(totalFare + tds - totalPayableAmt, currency)}
          />
        </>
      )}
      <UserLabel
        label="Total Payable"
        value={formatPrice(totalPayableAmt, currency)}
      />
      {/* {commissionEarned > 0 && (
        <UserLabel
          label="Total Commission Earned"
          value={formatPrice(commissionEarned, currency)}
        />
      )} */}
    </div>
  );
};

export default function SaleSummary(props) {
  const { selectedFlight, fareDetailResult, baggages, meals } = useSelector(
    (state) => state.bookFlight
  );
  const {
    fareDetails,
    publishedPrice,
    currency,
    totalPayable,
    totalFare,
    totalPayableAmt,
  } = fareDetailResult ? fareDetailResult : {};
  const { isLCC } = selectedFlight ? selectedFlight : {};
  const { getPassengerType } = usePassenger();
  const [passengerFare, setPassengerFare] = useState([]);
  const [state, setState] = useState({});
  const [showDetails, setShow] = useState(false);
  const [totalPassengerCharges, setTotalPassengerCharges] = useState(0);
  const dispatch = useDispatch();

  const toggleDetails = () => {
    setShow(!showDetails);
  };

  useEffect(() => {
    if (fareDetails) {
      setPassengerFare(
        map(
          groupBy(getPassengerType(), (c) => c.paxType),
          (values, key) => ({
            passengerType: values[0].passengerType,
            noOfPassengers: values.length,
            // eslint-disable-next-line eqeqeq
            fare: fareDetails.find((f) => f.passengerType == key)
              ?.publishedFare,
          })
        )
      );

      let paxCharges = 0;

      fareDetails.forEach((fd) => {
        paxCharges += fd.publishedFare;
      });

      if (paxCharges) {
        setTotalPassengerCharges(paxCharges);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fareDetails]);

  const { baggageWeight, baggagePrice, meal, mealPrice } = state;

  const handleStateChange = (payload) => {
    setState((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  useEffect(() => {
    console.log({ saleSummaryfareDetailResult: fareDetailResult });
  }, [fareDetailResult]);

  useEffect(() => {
    let baggageWeight = 0,
      baggagePrice = 0;
    if (isLCC && baggages) {
      baggageWeight = sumBy(baggages.flat(), (c) => c.weight);
      baggagePrice = sumBy(baggages.flat(), (c) => c.price);
      // baggageWeight = sumBy(baggages[props.currentIndex], (c) => c.weight);
      // baggagePrice = sumBy(baggages[props.currentIndex], (c) => c.price);
    }
    handleStateChange({ baggageWeight, baggagePrice });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baggages]);

  useEffect(() => {
    let meal = 0,
      mealPrice = 0;
    if (isLCC && meals) {
      meal = sumBy(meals.flat(), (c) => c.quantity);
      mealPrice = sumBy(meals.flat(), (c) => c.price);
    }
    handleStateChange({ meal, mealPrice });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meals]);

  useEffect(() => {
    let totalFare = publishedPrice,
      totalPayableAmt = totalPayable;
    if (baggagePrice) {
      totalFare += baggagePrice;
      totalPayableAmt += baggagePrice;
    }
    if (mealPrice) {
      totalFare += mealPrice;
      totalPayableAmt += mealPrice;
    }
    dispatch(
      setFlight({
        sourceCode: "SaleSummary",
        fareDetailResult: {
          ...fareDetailResult,
          totalFare,
          totalPayableAmt,
          baggagePrice,
          mealPrice,
          commissionEarned: totalFare - totalPayableAmt,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baggagePrice, mealPrice, publishedPrice, totalPayable]);

  return (
    <UserCard title="Summary">
      <MuiGrid xs={12}>
        {(auth.userType === "staff" || auth.userType === "travelAgent") &&
          !(fareDetailResult?.markup > 0) && (
            <MuiLink
              onClick={toggleDetails}
              label={showDetails ? "Hide Details" : "Show Details"}
            />
          )}
        <MuiGrid xs={12}>
          <FlightDetail selectedFlight={selectedFlight} />
        </MuiGrid>
        {/* <MuiGrid xs={12}>
          <UserHeader title="Fare/Pax Type" />
        </MuiGrid>
        <MuiGrid xs={12}>
          {showDetails ? (
            <PassengerTypeFareDetails
              fareDetails={fareDetails}
              currency={currency}
              hideTransactionFee={true}
            />
          ) : (
            <PassengerTypeFare hideTransactionFee={true} fareDetails={fareDetails} currency={currency} />
          )}
        </MuiGrid> */}
        {currency && (
          <>
            <MuiGrid xs={12}>
              <UserHeader title="Total Fare" />
            </MuiGrid>

            {passengerFare.map((c) => (
              <MuiGrid xs={12} key={c.passengerType}>
                <UserLabel
                  label={`${c.passengerType}*${c.noOfPassengers}`}
                  value={formatPrice(c.fare, currency)}
                />
              </MuiGrid>
            ))}

            <MuiGrid>
              <UserLabel
                label={`Taxes and Fees`}
                value={formatPrice(
                  Math.abs(
                    publishedPrice -
                      totalPassengerCharges +
                      (!showDetails && totalPayable > publishedPrice
                        ? totalPayable - publishedPrice
                        : 0)
                  ),
                  currency
                )}
                // value={formatPrice(Math.abs(publishedPrice - totalPassengerCharges + (!showDetails ? (totalPayable - publishedPrice) : 0)), currency)}
              />

              {publishedPrice - totalPayable > 0 && !showDetails && (
                <UserLabel
                  label={"Discount"}
                  value={formatPrice(publishedPrice - totalPayable, currency)}
                />
              )}

              {/* {publishedPrice - totalPassengerCharges} */}

              <UserLabel
                label={`Excess Baggage ${baggageWeight}KG`}
                value={formatPrice(baggagePrice, currency)}
              />
              <UserLabel
                label={`Meal (${meal}Platter)`}
                value={formatPrice(mealPrice, currency)}
              />
            </MuiGrid>
            {showDetails ? (
              <>
                <hr />
                <MuiGrid className="mt-2">
                  <TotalFareDetails
                    {...fareDetailResult}
                    // totalPayable={totalPayableAmt}
                    totalFare={totalFare}
                  />
                  {/* {auth.userType === "staff" &&
                    (markup > 0 ? (
                      <>
                        <UserLabel
                          label="Service Fee"
                          value={formatPrice(markup, currency)}
                        />
                      </>
                    ) : (
                      <UserLabel
                        label="Discount"
                        value={formatPrice(discount, currency)}
                      />
                    ))} */}
                </MuiGrid>
              </>
            ) : (
              <MuiGrid>
                {/* <UserLabel
                  label="Service Fee"
                  value={formatPrice(serviceFee, currency)}
                /> */}
                {/* <UserLabel
                  label="Other Charges"
                  value={formatPrice(otherCharges, currency)}
                /> */}
                {/* <UserLabel
                  label="Total GST"
                  value={formatPrice(totalGST, currency)}
                /> */}

                <UserLabel
                  label="Total Payable"
                  value={formatPrice(totalPayableAmt, currency)}
                />
              </MuiGrid>
            )}
          </>
        )}
      </MuiGrid>
    </UserCard>
  );
}
