import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import { RootState } from "redux/store";

export interface OrderState {
  amount: number;
  orderId: string | null;
  // redirectTo: string | null;
  // If this is set to true, payment will autodeduct and processed
  // otherwise user has to press "Pay From Wallet"
  isPayingOnline: boolean;
}

const initialState: OrderState = {
  amount: 0,
  isPayingOnline: false,
  orderId: null,
  //   redirectTo: null,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPaymentAmount: (state, action: PayloadAction<OrderState>) => {
      state.amount = action.payload.amount;
      //   state.redirectTo = action.payload.redirectTo;
      state.orderId = action.payload.orderId;
      state.isPayingOnline = false;
    },

    clearOrder: (state) => {
      state = initialState;
    },

    // If this action is dispatched, payment will autodeducted and processed
    // otherwise user has to press "Pay From Wallet"
    setIsPayingOnline: (state, action: PayloadAction<boolean>) => {
      state.isPayingOnline = action.payload;
    },
  },
});

export const OrderActions = orderSlice.actions;

export const selectOrderSlice = (state: RootState) => {
  if (state.payment.expiryTime) {
    if (moment(state.payment.expiryTime).diff(moment(), "minutes") > 0) {
      return state.payment;
    }
  }
  return null;
};

export default orderSlice.reducer;
