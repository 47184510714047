import { IntlTelInputWrapper } from "components/afterLogin/dashboard/IntlTelInputWrapper";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
export const InvalidPhoneNumberModal: React.FC<{
  show: boolean;
  updatingPhoneNumber: boolean;
  submitFx: (any) => void;
}> = (props) => {
  const [disableButton, setDisableButton] = useState(true);
  const [phoneDetails, setPhoneDetails] = useState<{
    phone: string;
    phoneCode: string | undefined;
  }>({
    phone: "",
    phoneCode: "",
  });
  return (
    <Modal show={props.show} centered>
      <Modal.Header>
        <h5>Please Update Your Number</h5>
      </Modal.Header>
      <Modal.Body>
        <IntlTelInputWrapper
          fieldId="phone-number"
          label="Enter your number:"
          isRequiredField={true}
          errorMessage={
            phoneDetails.phone && disableButton
              ? "Enter a valid phone number"
              : ""
          }
          value={phoneDetails.phone}
          onChange={(phoneNumber, phoneCode, isValid) => {
            setPhoneDetails({
              phone: phoneNumber,
              phoneCode,
            });
            if (isValid) {
              setDisableButton(false);
            } else {
              setDisableButton(true);
            }
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={`${
            disableButton || props.updatingPhoneNumber
              ? "btn-secondary"
              : "btn btn-primary"
          }`}
          // style={{ color: "$primaryColor" }}
          disabled={disableButton || props.updatingPhoneNumber}
          onClick={() => {
            props.submitFx(phoneDetails);
          }}
        >
          {props.updatingPhoneNumber ? (
            <div className="">
              <Spinner className="mx-4" size="sm" />
            </div>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
