import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { SearchUsersAutocomplete } from "./SearchUsersAutocomplete";
import { SelectWrapper } from "components/afterLogin/settings-menu-components/settingsSubComponents/UI/SelectWrapper";
import { getRequest } from "components/common/ajax";
import { IUserPermissionGroup } from "types/RBAC/managePermission.types";
import { getCustomers, wlUserSearchDb } from "api/user";
import { set } from "lodash";
import { useAppSelector } from "flight-react/hooks/reduxHooks";

const useStyles = makeStyles({
  gridContainer: {
    marginTop: "1rem",
  },
  searchInp: {
    margin: "10px auto",
    width: "100%",
  },
});

export const UserSearch: React.FC<{
  setFilterCustomer?: (data: any) => void;
  setUserData: Function;
}> = (props) => {
  const classes = useStyles();
  // const user = useSelector((state:any) => state.user);
  // const [userData, setUserData] = useState({});
  // const [amount, setAmount] = useState("");
  // const [remark, setRemark] = useState("");
  // const [debitCredit, setDebitCredit] = useState("");
  // const [disabled, setDisabled] = useState(false);
  // const [totalAmountFromWallet, setTotalAmountFromWallet] = useState("0");
  // const [updateTimes, setUpdateTimes] = useState(0); // this flag is to rerender transaction table

  // const handleSearchChange = (e) => {
  //   let user = e.target.value;
  //   if (user) {
  //     setSearchQuery(user);
  //   }
  // };
  const [userGroupList, setUserGroupList] = useState<IUserPermissionGroup[]>(
    []
  );

  const siteData = useAppSelector((state: any) => state.siteData);

  const getRBACGroupList = async () => {
    const userGroupResponse = await getRequest("rbac/getRBACUserGroupList", {});
    if (userGroupResponse?.success) {
      setUserGroupList(userGroupResponse.result);
    }
  };

  const [selectedGroup, setSelectedGroup] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const searchUser = async (userGroupId?) => {
    const userSearchResult = await getCustomers({
      rbacUserGroupId: userGroupId,
    });

    if (userSearchResult?.data && props.setFilterCustomer) {
      props.setFilterCustomer(userSearchResult.data);
    }
  };

  useEffect(() => {
    getRBACGroupList();
  }, []);

  return (
    <>
      <Grid
        container
        className={classes.gridContainer}
        spacing={2}
        justifyContent="center"
      >
        <Grid item xs={12} sm={6}>
          <SearchUsersAutocomplete
            setUser={(usrData) => {
              //  if(usrData){
              props.setUserData(usrData);
              // }
            }}
          />
        </Grid>
        {/* <Grid item xs={12}></Grid> */}

        {siteData.wlMeta.canShowPermissionMenu && (
          <div className="col-lg-3 my-3 mt-5">
            <SelectWrapper
              onChange={(e) => {
                searchUser(e?.value);
                setSelectedGroup(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  paddingTop: "10px",
                  paddingBottom: "12px",
                  borderRadius: "5px",
                  marginTop: "2px",
                }),
              }}
              value={selectedGroup?.value}
              options={userGroupList.map((group) => {
                return {
                  label: group.groupName,
                  value: group.id.toString(),
                };
              })}
              placeholder="Select User Permission Group"
            />
          </div>
        )}
      </Grid>
    </>
  );
};
