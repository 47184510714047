import React from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";

const TextArea = ({
  label,
  value,
  name,
  handleChange,
  isRequired,
  errors,
  disabled,
}) => {
  const onChange = (event) => {
    const value = event.target.value;
    handleChange(name, value);
  };

  const error = errors ? errors[name] : null;

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="outlined-age-native-simple" shrink={true}>
        {label}
      </InputLabel>
      <TextareaAutosize
        rowsMin={3}
        rowsMax={5}
        value={value ? value : ""}
        id={name}
        disabled={disabled}
        onChange={onChange}
        required={error ? true : false}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default TextArea;