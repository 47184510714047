export const common = {
  LOADING: "LOADING",
  PAYMENT_LOADING: "PAYMENT_LOADING",
  SET_ERROR: "SET_ERROR",
  SHOW_ALERT_MESSAGE: "SHOW_ALERT_MESSAGE",
  SET_ALERT_MESSAGE: "SET_ALERT_MESSAGE",
  HIDE_ALERT_MESSAGE: "HIDE_ALERT_MESSAGE",
  GET_WL_USER_WALLET_BALANCE: "GET_WL_USER_WALLET_BALANCE",
  SET_WL_USER_WALLET_BALANCE: "SET_WL_USER_WALLET_BALANCE",
  GET_BANK_DETAILS: "GET_BANK_DETAILS",
  SET_BANK_DETAILS: "SET_BANK_DETAILS",
  SET_HEADER_POSITION: "SET_HEADER_POSITION",
};
