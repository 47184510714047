import APIUtil from "./APIUtil";

class SearchFlightAPI {
  searchFlight(payload) {
    return APIUtil.callPostAPI("api/search", payload);
  }

  getCalendarFare(payload) {
    return APIUtil.callPostAPI("api/getCalendarFare", payload);
  }

  getSources(payload) {
    return APIUtil.callGetAPI("api/getSources", payload);
  }

  getAirports(payload) {
    return APIUtil.callPostAPI("api/getAirports", payload);
  }

  getFlightPrice(payload) {
    return APIUtil.callPostAPI("api/getFlightPrice", payload);
  }
}

export default new SearchFlightAPI();
