import { UserTypeEnum } from "commonFnBcode/types/userPending.types";

export enum consumerSupplierRequestStatusEnum {
  "Approved" = "Approved",
  "Rejected" = "Rejected",
  "Pending" = "Pending",
  "Suspended" = "Suspended",
}

export interface IMainPagination<T = any> {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  data: T[];
}

export interface IModulesUrl {
  login: string;
  logout: string;
  homePage: string;
  signup: string;
  myProfile: string;
  dashboard: string;
  termAndConditions: string;
  privacyPolicy: string;
  cancellationReturnRefundPolicy: string;
  customHomePageUrl: string;
  modules: IModules;
}

export interface IModules {
  Flight: string;
  FixedDeparture: string;
  TourPackages: string;
  VisaChecklist: string;
  Hotels: string;
  Train: string;
  Bus: string;
  Cruise: string;
  Insurance: string;
  FlightsForm: string;
  OtherEnquiry: string;
  VisaEnquiry: string;
  TourPackageForm: string;
  Activities: string;
}

export enum ENUMphoneNumberLabel {
  Primary = "Primary",
  Secondary = "Secondary",
  Emergency = "Emergency",
}

export interface IPhoneNumbers {
  id: number;
  label: ENUMphoneNumberLabel;
  phoneNumber: string;
  phoneCountryCode: string;
  otp: number;
  otpExpiry: Date;
  isVerified: boolean;
  companyId: number;
  userId: number;
  LEId: number;
  otpAttempts: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IRBAC {
  permissions: string[];
  lastSyncAt: Date;
}

export interface IUserData {
  id: number;
  uuid: string;
  isBlocked: boolean;
  oldMongoId?: string;
  site_id: string;
  createdAt: Date;
  updatedAt: Date;
  userType: UserTypeEnum;
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  oldPhone: string;
  customer: any;
  staff: any;
  company: any;
  phone?: string;
  phoneCode?: string;
  phoneNumbers: IPhoneNumbers[];
  companyId: number;
  isDetailPending: boolean;
  isSuperAdmin: boolean;
}

export interface IPagination<T> {
  perPage: number;
  currentPage: number;
  totalPage: number;
  totalRecords: number;
  currentPageRecords: T[];
}
