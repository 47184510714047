import { Common } from "../actionTypes/common";

const initialState = {};

export default function response(state = initialState, action) {
  switch (action.type) {
    case Common.SET_API_RESPONSE:
      return {
        ...state,
        ...action.result,
      };
    case Common.SHOW_PRICE_CHANGED_ALERT:
      return {
        ...state,
        isPriceChanged: true,
        newPrice: action.payload.newPrice,
      };
    case Common.HIDE_PRICE_CHANGED_ALERT:
      return {
        ...state,
        isPriceChanged: false,
        newPrice: undefined,
      };
    default:
      return {
        ...state,
      };
  }
}
