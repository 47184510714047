import { Ticket } from "../actionTypes/ticket";

export const getTickets = (payload) => ({
  type: Ticket.GET_TICKETS,
  payload,
});

export const getBookedFlights = (payload) => ({
  type: Ticket.GET_BOOKED_FLIGHTS,
  payload,
});

export const getTicketDetails = (payload) => ({
  type: Ticket.GET_TICKET_DETAILS,
  payload,
});

export const releaseFlight = (payload) => ({
  type: Ticket.RELEASE_FLIGHT,
  payload,
});

export const changeRequest = (payload, cb) => ({
  type: Ticket.CHANGE_REQUEST,
  payload,
  cb,
});

export const updateBookingDetails = (payload) => ({
  type: Ticket.UPDATE_BOOKING_DETAILS,
  payload,
});

export const getCC = (payload, cb) => ({
  type: Ticket.GET_CC,
  payload,
  cb,
});

export const getCRStatus = (payload, cb) => ({
  type: Ticket.GET_CR_STATUS,
  payload,
  cb,
});
