import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  IPermission,
  IPermissionGroup,
  IPermissionModule,
  IUserPermissionChangedData,
  IUserPermissionGroup,
  UserPermissionChangedDataType,
} from "types/RBAC/managePermission.types";

interface IUpdateActiveStatus<T> {
  updateValue: T;
  isActive: boolean;
}

interface ISelectedUserPermissionData {
  selectedModuleId: number | null;
  selectedPermissionGroupId: string | null;
}

const initialState: {
  selectedUserPermissionGroup: IUserPermissionGroup | null;
  isModuleDataWithPermissionsLoading: boolean;
  // manage permission states
  allModuleDataWithPermissions: IPermissionModule[];
  selectedUserPermissionData: ISelectedUserPermissionData;
  userPermissionChangedData: IUserPermissionChangedData;
} = {
  selectedUserPermissionGroup: null,
  isModuleDataWithPermissionsLoading: true,
  allModuleDataWithPermissions: [],
  selectedUserPermissionData: {
    selectedModuleId: null,
    selectedPermissionGroupId: null,
  },
  userPermissionChangedData: {} as IUserPermissionChangedData,
};

// action fx
const updateUserPermissionChangedData = (
  oldUserPermissionChangeData: IUserPermissionChangedData | {},
  data: UserPermissionChangedDataType
) => {
  const updatedUserPermisionChangedData = _.cloneDeep(
    oldUserPermissionChangeData
  );
  updatedUserPermisionChangedData[data.key] = {
    ...updatedUserPermisionChangedData[data.key],
    ...data.value,
  };
  return updatedUserPermisionChangedData as IUserPermissionChangedData;
};

const managePermissionSlice = createSlice({
  name: "managePermission",
  initialState,
  reducers: {
    setSelectedUserPermissonGroups: (
      state,
      action: PayloadAction<IUserPermissionGroup>
    ) => {
      state.selectedUserPermissionGroup = action.payload;
    },

    /**
     * Initialize allModuleDataWithPermissions for the selected user permission group,
     * select first permission module
     * and select first permission group in the selected permission module
     */
    initManagePermissionsData: (
      state,
      action: PayloadAction<IPermissionModule[]>
    ) => {
      let data = [...action.payload];
      data = data.map((module) => {
        module.userPermissionGroups = module.userPermissionGroups.map((pg) => {
          return {
            ...pg,
            isActive: pg.rbacPermissions.some((per) => per.isActive),
          };
        });
        return module;
      });
      state.allModuleDataWithPermissions = data;
      state.selectedUserPermissionData.selectedModuleId = data?.[0]?.id || null;
      state.selectedUserPermissionData.selectedPermissionGroupId =
        data?.[0]?.userPermissionGroups?.[0]?.id || null;
      state.isModuleDataWithPermissionsLoading = false;
    },

    // Modules
    setSelectedPermissionModule: (state, action: PayloadAction<number>) => {
      state.selectedUserPermissionData.selectedModuleId = action.payload;
    },

    // Permission Groups
    setSelectedPermissionGroup: (state, action: PayloadAction<string>) => {
      state.selectedUserPermissionData.selectedPermissionGroupId =
        action.payload;
    },

    updateModuleActiveStatus: (
      state,
      action: PayloadAction<IUpdateActiveStatus<IPermissionModule>>
    ) => {
      let updatedModuleData = { ...action.payload.updateValue };
      let updatedChangeData = _.cloneDeep(state.userPermissionChangedData);

      updatedModuleData.userPermissionGroups =
        updatedModuleData.userPermissionGroups.map((pg) => {
          let updatePermisions = pg.rbacPermissions.map((per) => {
            updatedChangeData = updateUserPermissionChangedData(
              updatedChangeData,
              {
                key: "permissions",
                value: {
                  [per.id.toString()]: {
                    isActive: action.payload.isActive
                      ? per.defaultAllowed
                      : false,
                    moduleId: updatedModuleData.id,
                    permissionGroupId: pg.id,
                    permissionId: per.id,
                  },
                },
              }
            );
            return {
              ...per,
              isActive: action.payload.isActive ? per.defaultAllowed : false,
            };
          });
          let isPerMissionGroupActive = updatePermisions.some(
            (per) => per.isActive
          );

          updatedChangeData = updateUserPermissionChangedData(
            updatedChangeData,
            {
              key: "permissionGroups",
              value: {
                [pg.id.toString()]: {
                  isActive: isPerMissionGroupActive,
                  moduleId: updatedModuleData.id,
                  permissionGroupId: pg.id,
                },
              },
            }
          );

          return {
            ...pg,
            rbacPermissions: updatePermisions,
            isActive: isPerMissionGroupActive,
          };
        });

      state.allModuleDataWithPermissions =
        state.allModuleDataWithPermissions.map((module) => {
          if (module.id === action.payload.updateValue.id) {
            return updatedModuleData;
          }
          return module;
        });

      updatedChangeData = updateUserPermissionChangedData(updatedChangeData, {
        key: "modules",
        value: {
          [action.payload.updateValue.moduleName]: {
            isActive: action.payload.updateValue.isActive,
            moduleId: action.payload.updateValue.id,
            moduleName: action.payload.updateValue.moduleName,
          },
        },
      });
      state.userPermissionChangedData = updatedChangeData;
    },

    updatePermissionGroupActiveStatus: (
      state,
      action: PayloadAction<IUpdateActiveStatus<IPermissionGroup>>
    ) => {
      let updatedModuleDataWithPermissions = _.cloneDeep(
        state.allModuleDataWithPermissions
      );
      let updatedChangeData = _.cloneDeep(state.userPermissionChangedData);

      // updating selected module data
      updatedModuleDataWithPermissions = updatedModuleDataWithPermissions.map(
        (module) => {
          if (module.id === state.selectedUserPermissionData.selectedModuleId) {
            module.userPermissionGroups = module.userPermissionGroups.map(
              (pg) => {
                if (pg.id === action.payload.updateValue.id) {
                  pg.isActive = action.payload.isActive;
                  pg.rbacPermissions = pg.rbacPermissions.map((per) => {
                    updatedChangeData = updateUserPermissionChangedData(
                      updatedChangeData,
                      {
                        key: "permissions",
                        value: {
                          [per.id.toString()]: {
                            isActive: action.payload.isActive
                              ? per.defaultAllowed
                              : false,
                            moduleId:
                              state.selectedUserPermissionData
                                .selectedModuleId!,
                            permissionGroupId: pg.id,
                            permissionId: per.id,
                          },
                        },
                      }
                    );

                    return {
                      ...per,
                      isActive: action.payload.isActive
                        ? per.defaultAllowed
                        : false,
                    };
                  });
                }
                return pg;
              }
            );
          }
          return module;
        }
      );

      state.allModuleDataWithPermissions = updatedModuleDataWithPermissions;

      updatedChangeData = updateUserPermissionChangedData(updatedChangeData, {
        key: "permissionGroups",
        value: {
          [action.payload.updateValue.id.toString()]: {
            isActive: action.payload.updateValue.isActive,
            moduleId: state.selectedUserPermissionData.selectedModuleId!,
            permissionGroupId: action.payload.updateValue.id,
          },
        },
      });

      state.userPermissionChangedData = updatedChangeData;
    },

    updatePermissionActiveStatus: (
      state,
      action: PayloadAction<IUpdateActiveStatus<IPermission>>
    ) => {
      let updatedModuleDataWithPermissions = _.cloneDeep(
        state.allModuleDataWithPermissions
      );

      updatedModuleDataWithPermissions = updatedModuleDataWithPermissions.map(
        (module) => {
          if (module.id === state.selectedUserPermissionData.selectedModuleId) {
            module.userPermissionGroups = module.userPermissionGroups.map(
              (pg) => {
                if (
                  pg.id ===
                  state.selectedUserPermissionData.selectedPermissionGroupId
                ) {
                  pg.rbacPermissions = pg.rbacPermissions.map((per) => {
                    if (per.id === action.payload.updateValue.id) {
                      return action.payload.updateValue;
                    }
                    return per;
                  });
                }
                return pg;
              }
            );
          }
          return module;
        }
      );

      state.allModuleDataWithPermissions = updatedModuleDataWithPermissions;

      state.userPermissionChangedData = updateUserPermissionChangedData(
        state.userPermissionChangedData,
        {
          key: "permissions",
          value: {
            [action.payload.updateValue.id.toString()]: {
              isActive: action.payload.updateValue.isActive,
              moduleId: state.selectedUserPermissionData.selectedModuleId!,
              permissionGroupId:
                state.selectedUserPermissionData.selectedPermissionGroupId!,
              permissionId: action.payload.updateValue.id,
            },
          },
        }
      );
    },

    resetManagePermissionSlice: (state) => {
      state.allModuleDataWithPermissions = [];
      state.selectedUserPermissionGroup = null;
      state.selectedUserPermissionData = {
        selectedModuleId: null,
        selectedPermissionGroupId: null,
      };
      state.isModuleDataWithPermissionsLoading = true;
      state.userPermissionChangedData = {} as IUserPermissionChangedData;
    },
  },
});

// export const {
//   setSelectedUserPermissonGroups,
//   initManagePermissionsData,
//   updateModuleActiveStatus,
//   updatePermissionGroupActiveStatus,
//   updatePermissionActiveStatus,
//   setSelectedPermissionModule,
//   setSelectedPermissionGroup,
//   resetManagePermissionSlice,
// } = managePermissionSlice.actions;
export const MANAGE_PERMISSION_ACTIONS = managePermissionSlice.actions;

export default managePermissionSlice.reducer;
