export const BookFlight = {
  GET_COUNTRY_LIST: "GET_COUNTRY_LIST",
  GET_PASSENGER_VIEW_OPTIONS: "GET_PASSENGER_VIEW_OPTIONS",
  GET_PHONECODE_LIST: "GET_PHONECODE_LIST",
  GET_NATIONALITIES: "GET_NATIONALITIES",
  GET_FARE_RULES: "GET_FARE_RULES",
  GET_SSR_DETAILS: "GET_SSR_DETAILS",
  FARE_QUOTE: "FARE_QUOTE",
  // BOOK_FLIGHT: "BOOK_FLIGHT",
  // GET_TICKET: "GET_TICKET",
  FLIGHT_ORDER_SUCCESS_CALLBACK: "FLIGHT_ORDER_SUCCESS_CALLBACK",
  GET_BOOKING_DETAILS: "GET_BOOKING_DETAILS",
  SET_FLIGHT_DETAIL: "SET_FLIGHT_DETAILS",
  SET_SELECTED_FLIGHT: "SET_SELECTED_FLIGHT",
  GET_PASSENGERS_VIEW: "GET_PASSENGERS_VIEW",
  VALIDATE_BOOKING: "VALIDATE_BOOKING",
  INIT_BOOK_FLIGHT: "INIT_BOOK_FLIGHT",
  GET_FLIGHT_DETAILS: "GET_FLIGHT_DETAILS",
  BOOK_FLIGHT_SUCCESS: "BOOK_FLIGHT_SUCCESS",
  SET_FARE_DETAILS: "SET_FARE_DETAILS",
  SET_IS_BOOKING_IN_PROCESS: "SET_IS_BOOKING_IN_PROCESS",
  SET_PASSENGER_DETAILS: "SET_PASSENGER_DETAILS",
  SET_PAYMENT_ORDER_ID: "SET_PAYMENT_ORDER_ID",
};
