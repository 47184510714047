import axios from "axios";
import auth from "flight-react/libs/auth";
import { AppConstant } from "flight-react/utils/constant";
import { getAuthToken, redirection401 } from "tools/functions";

// let userKey = auth.userKey;

const Axios = axios.create({
  baseURL: "/api/nfs/flights/api",
  // process.env.NODE_ENV === "production"
  //   ? `${window.location.protocol}//${window.location.hostname}/flightApi/api/`
  //   : "http://localhost:9890/api",
});

Axios.interceptors.request.use(
  (config) => {
    config.headers[AppConstant.USER_KEY] = getAuthToken();
    config.headers["site-id"] = auth.siteId;
    return config;
  },

  (error) => Promise.reject(error)
);

// if response is 401, then logout the user
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      redirection401();
    }
    return Promise.reject(error);
  }
);

export const nfsAxios = Axios;
