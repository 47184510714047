import { useEffect } from 'react'
import { useLocation } from "react-router-dom";

export const usePreviousHistory = () => {

  const location = useLocation();

  useEffect(() => {
    // effect

    let browserhistory: string | Array<string> | null = localStorage.getItem("browserHistory");

    let currentRoute = location.pathname;
    if (browserhistory) {
      browserhistory = JSON.parse(browserhistory);
      let historyLength = 0
      if (browserhistory && typeof browserhistory !== "string") {
        historyLength = browserhistory.length;
        if (historyLength > 4) {
          browserhistory.splice(0, 1)
        }
        if (browserhistory[browserhistory.length - 1] !== currentRoute) {          
          browserhistory.push(currentRoute);
        }
      }
    } else {
      browserhistory = [];
    }

    localStorage.setItem("browserHistory", JSON.stringify(browserhistory))

    return () => {
      // cleanup
      // localStorage.removeItem("browserHistory")
    }
  }, [location.pathname])



  return;
}
