import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import { user } from "redux/actionTypes/userTypes";
import { SET_USER_DATA, UPDATE_USER_LE_DATA } from "redux/actions/userActions";
import { common } from "redux/actionTypes/commonTypes";
import { generatePasswordWlUser, postForgetPassword } from "api/user";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { getMyLegalEntity } from "api/user";

function* updatePassword(action) {
  const apiResponse = yield call(generatePasswordWlUser, action.payload);
  yield put({
    type: common.SET_ALERT_MESSAGE,
    payload: apiResponse.data.err
      ? apiResponse.data.err
      : "Password Changed! Redirecting to login page...",
  });
}

function* logout() {
  // localStorage.removeItem("auth-token");
  localStorage.removeItem("userData");
  localStorage.removeItem("persist:appCoreRoot");
  Cookies.remove("auth-token");
  yield put(SET_USER_DATA({}));
  const urlParams = new URLSearchParams(window.location?.search);
  const redirectTo = urlParams?.get("redirectTo")
    ? "?redirectTo=" + urlParams?.get("redirectTo")
    : "";
  const redirectType = urlParams?.get("redirectType")
    ? "redirectType=" + urlParams?.get("redirectType")
    : "";
  window.location.href = `/app/login${redirectTo}${
    redirectType ? "&" + redirectType : ""
  }`;
  // Cookies.remove(APP_CONSTANTS.LOGGED_IN_COOKIE);
}

/**
 *
 * commented on 14 january. Delete later if everything works fine
 * @param {} action
 * @returns
 */
// function* forgetPassword(action) {
//   const response = yield call(postForgetPassword, action.payload);
//   toast.success(response.data.err ? response.data.err : "Check Your Email..");
//   // yield put({
//   //         type: common.SET_ALERT_MESSAGE,
//   //         payload:  response.data.err ?  response.data.err : "Check Your Email.."
//   //     })
// }

function* updateUserLEData(action) {
  const response = yield call(getMyLegalEntity, action.payload.LEId);
  if (!response?.data?.success) return toast.error(response.errorMessage);
  else {
    const LE = response.data.result;
    yield put(UPDATE_USER_LE_DATA(LE));
  }
}

export function* watchUserAction() {
  yield takeEvery(user.UPDATE_PASSWORD, updatePassword);
  yield takeEvery(user.LOGOUT, logout);
  // commented on 14 jan 2023 - delete later if everything works fine
  // yield takeLatest(user.FORGET_PASSWORD, forgetPassword);
  yield takeLatest(user.SAGA_UPDATE_USER_LE_DATA, updateUserLEData);
}
