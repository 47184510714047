import React from "react";
// import spinnerLogo from "assets/images/common/spinner.gif";
import "./loader.css";
export function Spinner(props) {
  return (
    <>
      {(props.loading === "true" || props.loading === true) && (
        <div
          // className="bg-white"
          style={{
            width: "100vw",
            height: "100vh",
            opacity: "0.7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="loadingio-spinner-pulse-wjymhpuiss">
            <div className="ldio-e63ka05htst">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          {/* <img src={spinnerLogo} alt="spinner" /> */}
        </div>
      )}
    </>
  );
}

// export default Spinner;
