/* eslint-disable eqeqeq */
import { push } from "connected-react-router";
import { call, put } from "redux-saga/effects";
import { Common } from "../actionTypes/common";
import { RouteConstant } from "../utils/routeConstant";

export default function* callAPI(
  api,
  { payload, hasNextCall },
  cb,
  hideLoading,
  failedCallback
) {
  try {
    if (!hideLoading) yield put({ type: Common.SHOW_PROGRESS });
    const response = yield call(api, payload);
    if (!hasNextCall) yield put({ type: Common.CLOSE_PROGRESS });
    if (response.status >= 200 && response.status < 300) {
      if (typeof response === "string") {
        return;
      }
      const { result, success, message } = yield response.json();
      if (success) {
        yield put({
          type: Common.SET_API_RESPONSE,
          result: { success, message },
        });
        if (cb) {
          yield call(cb, result, payload);
        }
      }
      return { result, payload };
    } else if (response.status == 401) {
      yield put(push(RouteConstant.SEARCH_FLIGHT));
    } else if (response.status == 402) {
      yield put(push(RouteConstant.BOOKING_REVIEW));
    } else if (response.status == 502) {
      yield put({
        type: Common.SET_API_RESPONSE,
        result: {
          message: "Request timeout.Please check your netowrk connection.",
          success: false,
        },
      });
    } else {
      yield put({ type: Common.CLOSE_PROGRESS });
      const responseJson = yield response.json();
      const { message, errorCode } = responseJson;

      if (!message.includes("No SSR details")) {
        // Hiding No SSR details found popup
        yield put({
          type: Common.SET_API_RESPONSE,
          result: { message, errorCode, success: false },
        });
        if (failedCallback) {
          yield call(failedCallback, responseJson);
        }
      }
    }
    return response;
  } catch (e) {
    yield put({ type: Common.CLOSE_PROGRESS });
    yield put({
      type: Common.SET_API_RESPONSE,
      result: { message: e.message },
    });
  }
}
