import { getUserBySessionId } from "api/user";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_USER_DATA } from "redux/actions/userActions";
import { getAuthToken } from "tools/functions";

export const useHydrateUserData = () => {
  const dispatch = useDispatch();

  /**
   * this function will hydrate userData if user data is not set in presist
   * and user is logged in.
   */
  const setUserDataToReduxConditionally = async () => {
    const logoutPath = "/app/logout";
    const isLoggingOut = window.location.pathname === logoutPath;
    const token = getAuthToken();
    const persistData = localStorage.getItem("persist:appCoreRoot")
      ? JSON.parse(localStorage.getItem("persist:appCoreRoot") || "")
      : undefined;
    const userData = persistData?.user
      ? JSON.parse(persistData?.user)
      : undefined;

    if (token && !userData?.id && !isLoggingOut) {
      try {
        const userResponse = await getUserBySessionId(token);
        if (userResponse.data.success) {
          return dispatch(SET_USER_DATA(userResponse.data.result));
        } else {
          throw new Error("User Fetch Failed");
        }
      } catch (error) {
        window.location.href = logoutPath;
      }
    }
  };

  useEffect(() => {
    setUserDataToReduxConditionally();
  }, []);

  return;
};
