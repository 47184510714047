/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { UserSelect } from "../../../components/SelectBox";
import { TextArea } from "../../../components/TextInput";
import { UserCheckbox } from "../../../components/Checkbox";
import useForm from "../../../hooks/useForm";
import { getPaxType } from "../../../utils/paxType";
import { FareRuleScreen } from "../../FareDetails";
import { UserButton } from "../../../components/Button";
import { UserLabel } from "../../../components/Label";
import { useDispatch } from "react-redux";
import { changeRequest, getCC } from "../../../actions/ticket";
import { FullScreenLoader } from "components/afterLogin/dashboard/flightApis/AirlineDiscountMarkup/components/FullScreenLoader";

const requestTypes = [
  { text: "Refund", value: 1 },
  { text: "Partial Refund", value: 2 },
  { text: "Change Itinerary / Reissue", value: 3 },
  // { text: "Flight Cancelled", value: 0 },
];

const requestTypeObj = {
  Refund: requestTypes[0].value,
  Partial_Refund: requestTypes[1].value,
  Change: requestTypes[2].value,
  // Flight_Cancelled: requestTypes[3].value,
};

export default function ChangeRequest({
  segments: initSegments,
  passenger: initPassengers,
  fareRules,
  bookingId,
  isLcc,
  isLCCSpecialReturn,
  handleBack,
}) {
  const [segments, setSegments] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const { state, errors, handleChange, handleStateChange, handleSubmit } =
    useForm(
      {},
      {
        requestType: { required: true },
        passenger: { required: true },
        sectors: { required: true },
        remarks: { required: true },
      },
      handleSend
    );

  const {
    requestType,
    passenger,
    sectors,
    remarks,
    isAccepted,
    showCharges,
    ticketCCInfo,
    disabled,
  } = state;
  const dispatch = useDispatch();

  useEffect(() => {
    if (initSegments)
      setSegments(
        initSegments.map((c) => ({
          ...c,
          value: `${c.originAirportCode}-${c.destinationAirportCode}`,
          text: `${c.originAirportCode}-${c.destinationAirportCode}`,
        }))
      );
  }, [initSegments]);

  useEffect(() => {
    if (initPassengers)
      setPassengers(
        initPassengers.map((c) => ({
          ...c,
          value: c.ticket?.ticketId,
          text: `${c.firstName} ${c.lastName} (${getPaxType(c.paxType)})`,
        }))
      );
  }, [initPassengers]);

  // useEffect(() => {
  //   if (requestType) {

  //   }
  // }, [requestType]);

  useEffect(() => {
    if (requestType) {
      const disabled =
        // eslint-disable-next-line eqeqeq
        requestTypeObj.Refund == requestType ||
        // eslint-disable-next-line eqeqeq
        requestTypeObj.Flight_Cancelled == requestType;

      handleStateChange({
        showCharges: false,
        sectors:
          disabled || requestTypeObj.Change == requestType
            ? segments.map((c) => c.value)
            : null,
        // sectors: segments.map((c) => c.value),
        passenger: disabled ? passengers.map((c) => c.value) : null,
        disabled,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType]);

  function handleSend(changeDetail) {
    const { requestType } = changeDetail;

    if (
      // isLcc &&
      // initSegments &&
      // initSegments.some((s) => s.isLCCSpecialReturn)
      false
    ) {
      setLoadingState(true);
      dispatch(
        getCC(
          {
            BookingId: bookingId,
            RequestType: requestType,
          },
          ({ ticketCCInfo }) => {
            setLoadingState(false);
            handleStateChange({ showCharges: true, ticketCCInfo });
          }
        )
      );
    } else {
      sendChangeRequest(null, changeDetail);
    }
  }

  const sendChangeRequest = (event, changeDetail = state) => {
    const { requestType, remarks, passenger, sectors } = changeDetail;

    dispatch(
      changeRequest({
        BookingId: bookingId,
        RequestType: requestType,
        CancellationType:
          requestType == requestTypeObj.Flight_Cancelled ? 2 : 3,
        ...(requestType == requestTypeObj.Partial_Refund
          ? {
              Sectors: sectors.map((c) => {
                const sector = c.split("-");
                return {
                  Origin: sector[0],
                  Destination: sector[1],
                };
              }),
            }
          : null),
        ...(requestType == requestTypeObj.Partial_Refund ||
        requestType == requestTypeObj.Change
          ? {
              TicketId: passenger,
            }
          : null),
        Remarks: remarks,
      })
    );
    if (handleBack) handleBack();
  };

  // if (showCharges) {
  //   const { refundAmount, cancellationCharge, remarks, currency } = ticketCCInfo
  //     ? ticketCCInfo
  //     : {};
  // }

  return (
    <>
      <FullScreenLoader show={loadingState} />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <UserSelect
              name="requestType"
              value={requestType}
              label="Request Change"
              handleChange={handleChange}
              options={requestTypes}
              addFirstItem
              errors={errors}
            />
          </div>
        </div>
        <div className="row my-3 justify-content-center">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <UserCheckbox
                  label="Please select Passenger"
                  options={passengers}
                  name="passenger"
                  values={passenger}
                  handleChange={handleChange}
                  errors={errors}
                  disabled={disabled}
                />
              </div>
              {requestTypeObj.Change != requestType && (
                <div className="col-md-6">
                  <UserCheckbox
                    label="Please select Refund Sectors"
                    options={segments}
                    name="sectors"
                    values={sectors}
                    handleChange={handleChange}
                    errors={errors}
                    disabled={disabled}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row my-3 justify-content-center">
          <div className="col-md-8">
            <TextArea
              name="remarks"
              value={remarks}
              label="Remarks"
              handleChange={handleChange}
              errors={errors}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8">
            <FareRuleScreen fareRules={fareRules} />
          </div>
        </div>

        <div className="row mb-3 justify-content-center">
          <div className="col-md-8">
            <UserCheckbox
              fullWidth
              name="isAccepted"
              value={isAccepted}
              handleChange={handleChange}
              label="I have reviewed and agreed on the cancellation policy and fare rules offered for this booking."
            />
          </div>
        </div>
        {showCharges && ticketCCInfo ? (
          <div className="row justify-content-center pt-1">
            <div className="col-md-8 ">
              <h5>Request Charges</h5>
            </div>
            <div className="col-md-8 mb-5 mt-3">
              {/* <UserLabel
                label="Refund Amount"
                value={ticketCCInfo.refundAmount}
              /> */}
              <UserLabel
                label="Cancellation Charge"
                value={ticketCCInfo.cancellationCharge}
              />
              <UserLabel label="Remarks" value={remarks} />
              <UserLabel label="Currency" value={ticketCCInfo.currency} />
            </div>
            <div className="col-md-8">
              <UserButton
                label="Accept and Send Cancellation Request"
                onClick={sendChangeRequest}
              />
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-md-8">
              <UserButton
                label="Send"
                onClick={handleSubmit}
                disabled={!isAccepted}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
