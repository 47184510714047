import APIUtil from "./APIUtil";

class TicketAPI {
  getBookedFlights(payload) {
    return APIUtil.callPostAPI("api/getBookedFlights", payload);
  }

  getTickets(payload) {
    return APIUtil.callPostAPI("api/getTickets", payload);
  }

  getTicketDetails(payload) {
    // return APIUtil.callPostAPI("api/getTicketDetails", payload);
    return APIUtil.callPostAPI("api/ticketDetail/getTicketDetail", payload);
  }

  releaseFlight(payload) {
    return APIUtil.callPostAPI("api/releaseFlight", payload);
  }

  changeRequest(payload) {
    return APIUtil.callPostAPI("api/changeRequest", payload);
  }

  updateBookingDetails(payload) {
    return APIUtil.callPostAPI("api/updateBookingDetails", payload);
  }

  // Get Cancellation Charge
  getCC(payload) {
    return APIUtil.callPostAPI("api/getCC", payload);
  }

  getCRStatus(payload) {
    return APIUtil.callPostAPI("api/getCRStatus", payload);
  }
}

export default new TicketAPI();
