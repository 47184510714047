import { postRequest } from "api/tools/httpRequests";
import { AxiosResponse } from "axios";
import { appAxios } from "fd-react/Components/Axios";
import Cookies from "js-cookie";
import { IJSONResponse } from "../dashboard/flightApis/AirlineDiscountMarkup/types";

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const pushNotificationSubscribeButtonHandler = async () => {
  const isSubscriptionExist = await _CheckSubscriptionAlreadyExist();
  if (isSubscriptionExist) {
    // console.log("isSubscriptionExist", isSubscriptionExist);
    // console.info("already exists in db");
    return true;
  }

  const permissionResult = await Notification.requestPermission();
  if (permissionResult === "denied") {
    console.error("The user explicitly denied the permission request.");
    return;
  }
  if (permissionResult === "granted") {
    console.info("The user accepted the permission request.");
  }
  // const registration = await navigator.serviceWorker.getRegistration();
  const registration = await navigator.serviceWorker.register(
    `${process.env.PUBLIC_URL}/service-worker.js`
  );

  const subscribed = await registration?.pushManager.getSubscription();

  if (subscribed) {
    console.info("User is already subscribed.");
    return;
  }

  // const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

  const vapidPublicKey =
    "BNpJXxfVSNXpSeDH40zJzK-E3105pw0msuzu-smaXypEp9Qv7PyAkDFC_iWIAwxzxmS7pSjcIBOiho1m1o8oG6U";

  if (!vapidPublicKey) {
    throw new Error("vapid public key not found");
  }

  const subscription = await registration?.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
  });
  // if (isSubscriptionExist) {
  //   console.log("isSubscriptionExist", isSubscriptionExist);
  //   return true;
  // }

  const savedResponse = await postRequest(
    "notification/createPushNotificationSubscription",
    {
      subscription: subscription,
    }
  );

  return true;
};
/** 
* @description 
*This function registers the service worker and 
 checks if the push notification subscription already exists for that user. 
 If the subscription exists, it returns true (do nothing). Otherwise, it saves the push notification in the database
**/
export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    try {
      const serviceWorkerRegistration = await navigator.serviceWorker.register(
        `${process.env.PUBLIC_URL}/service-worker.js`
      );
      console.info("Service worker was registered.");
      // console.info({ serviceWorkerRegistration });
    } catch (error) {
      console.error("An error occurred while registering the service worker.");
      console.error(error);
    }
  } else {
    console.error("Browser does not support service workers or push messages.");
  }

  await pushNotificationSubscribeButtonHandler();
};

const _CheckSubscriptionAlreadyExist = async () => {
  const subscriptionEndPoint = Cookies.get("notificationEndPoint");

  if (subscriptionEndPoint) {
    const existingResponse: AxiosResponse<
      IJSONResponse<{
        isSubscriptionExistForCurrentUser: boolean;
      }>
    > = await postRequest("notification/checkExistingSubscription", {
      subscriptionEndPoint: subscriptionEndPoint,
    });

    return existingResponse.data.result.isSubscriptionExistForCurrentUser;
  }
};
