import { PARTNER_SIPPI_BASE_URLS } from "tools/constants";
import auth from "../libs/auth";
import APIUtil from "./APIUtil";

const baseUrl = PARTNER_SIPPI_BASE_URLS.PARTNER_SIPPI + "/";

class CommonAPI {
  sendEmail(payload) {
    return APIUtil.callPostAPI(
      "notifications/sendEmailForWl",
      { ...payload, siteId: auth.siteId },
      baseUrl
    );
  }

  //   async verifyTransaction (payload){
  //     return await SippiAjax.postCall(
  //       "wl/user/verifyTransaction",
  //       payload,
  //       mainAppBaseUrl
  //     )
  //   }
}

export default new CommonAPI();
