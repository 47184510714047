import { takeLatest, call, put } from "redux-saga/effects";
import SearchFlightAPI from "../api/SearchFlightAPI";
import callAPI from "./helper";
import { SearchFlight } from "../actionTypes/searchFlight";
import { push } from "connected-react-router";
import { RouteConstant } from "../utils/routeConstant";
import auth from "../libs/auth";
import { AppConstant } from "../utils/constant";
import moment from "moment";

function* searchFlight(action) {
  yield put({
    type: SearchFlight.SEARCH_FLIGHT_SET_SHOW_LOADER,
    result: true,
  });
  yield call(callAPI, SearchFlightAPI.searchFlight, action, setSearchResult);

  yield put({
    type: SearchFlight.SEARCH_FLIGHT_SET_SHOW_LOADER,
    result: false,
  });
}

function* getCalendarFare(action) {
  yield call(
    callAPI,
    SearchFlightAPI.getCalendarFare,
    action,
    setCalendarFareResult
  );
}

function* getAirports(action) {
  // yield delay(100);
  yield call(callAPI, SearchFlightAPI.getAirports, action, setResult, true);
}
function* getSources(action) {
  yield call(callAPI, SearchFlightAPI.getSources, action, setResult);
}

function* getFlightPrice(action) {
  yield call(callAPI, SearchFlightAPI.getFlightPrice, action, action.cb);
}

function* setSearchResult(result) {
  yield call(setResult, result);
  auth.setItem(AppConstant.SEARCH_ID, result.searchId);
  const pathname = window.location.pathname;
  if (
    pathname === RouteConstant.SEARCH_FLIGHT ||
    pathname.includes(RouteConstant.SEARCH_FLIGHT_URL) ||
    pathname === "/" ||
    pathname === RouteConstant.SEARCH_RESULT ||
    pathname === RouteConstant.CALENDAR_FARE_RESULT
  ) {
    if (result.searchBy) {
      let firstSegment = result.searchBy.segments[0];
      let depDate = firstSegment.preferredDepartureTime.slice(0, 10);
      yield put(
        push(
          `${RouteConstant.SEARCH_RESULT}/${result.searchId}?origin=${firstSegment.origin}&destination=${firstSegment.destination}&departure=${depDate}&adt=${result.searchBy.adultCount}&chd=${result.searchBy.childCount}&inf=${result.searchBy.infantCount}`
        )
      );
    }
  }
}

function* setCalendarFareResult(result) {
  yield call(setResult, result);
  yield put(push(RouteConstant.CALENDAR_FARE_RESULT));
}

function* setResult(result) {
  yield put({ type: SearchFlight.SEARCH_FLIGHT_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(SearchFlight.SEARCH_FLIGHT, searchFlight);
  yield takeLatest(SearchFlight.GET_SOURCES, getSources);
  yield takeLatest(SearchFlight.GET_AIRPORTS, getAirports);
  yield takeLatest(SearchFlight.GET_CALENDAR_FARE, getCalendarFare);
  yield takeLatest(SearchFlight.GET_FLIGHT_PRICE, getFlightPrice);
}
