import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { UserButton } from "../../components/Button";
import { UserCheckbox } from "../../components/Checkbox";
import { UserDatePicker } from "../../components/DateControl";
import { UserHeader } from "../../components/Header";
import { TextInput } from "../../components/TextInput";
import MuiGrid from "../../components/Grid/item";
import { UserSelect } from "flight-react/components/SelectBox";

const FilterComponent = ({ action }) => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();

  const {
    isDomestic,
    isInternational,
    ticketNo,
    airlineCode,
    paxName,
    bookingFrom,
    bookingTo,
    travelFrom,
    travelTo,
    sortBy,
  } = state;

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleApply = () => {
    dispatch(
      action({
        ...state,
      })
    );
  };

  const handleClear = () => {
    setState({});
  };

  return (
    <MuiGrid container spacing={2} style={{ backgroundColor: "#F5F5F5" }}>
      <MuiGrid container spacing={2}>
        <UserHeader title="Filters" />
        <MuiGrid sm={12}>
          <UserSelect
            options={[
              {
                value: "BD",
                text: "Booking Date (Descending)",
              },
              {
                value: "BA",
                text: "Booking Date (Ascending)",
              },
              {
                value: "TD",
                text: "Travel Date (Descending)",
              },
              {
                text: "Travel Date (Ascending)",
                value: "TA",
              },
            ]}
            label="Sort By"
            value={sortBy}
            name="sortBy"
            handleChange={handleChange}
          />
        </MuiGrid>
        <MuiGrid xs={12} sm={6}>
          <UserCheckbox
            name="isDomestic"
            value={isDomestic}
            handleChange={handleChange}
            label="Domestic"
          />
        </MuiGrid>
        <MuiGrid xs={12} sm={6}>
          <UserCheckbox
            name="isInternational"
            value={isInternational}
            handleChange={handleChange}
            label="International"
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid xs={12}>
        <TextInput
          value={ticketNo}
          name="ticketNo"
          label="Restrict By PNR/Ticket No:"
          labelProps={{ shrink: true }}
          handleChange={handleChange}
        />
      </MuiGrid>
      <MuiGrid xs={12}>
        <TextInput
          value={airlineCode}
          name="airlineCode"
          label="Restrict By AirlineCode:"
          labelProps={{ shrink: true }}
          handleChange={handleChange}
        />
      </MuiGrid>
      <MuiGrid xs={12}>
        <TextInput
          value={paxName}
          name="paxName"
          label="Restrict By Passenger:"
          labelProps={{ shrink: true }}
          handleChange={handleChange}
        />
      </MuiGrid>
      <MuiGrid container spacing={1} justifyContent="center">
        <MuiGrid xs={12}>
          <UserHeader title="Booking Date" />
        </MuiGrid>
        <MuiGrid xs={12} sm={6}>
          <UserDatePicker
            label="From"
            name="bookingFrom"
            value={bookingFrom}
            handleChange={handleChange}
          />
        </MuiGrid>
        <MuiGrid xs={12} sm={6}>
          <UserDatePicker
            label="To"
            name="bookingTo"
            value={bookingTo}
            handleChange={handleChange}
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container spacing={1} justifyContent="center">
        <MuiGrid xs={12}>
          <UserHeader title="Travel Date" />
        </MuiGrid>
        <MuiGrid xs={12} sm={6}>
          <UserDatePicker
            label="From"
            name="travelFrom"
            value={travelFrom}
            handleChange={handleChange}
          />
        </MuiGrid>
        <MuiGrid xs={12} sm={6}>
          <UserDatePicker
            label="To"
            name="travelTo"
            value={travelTo}
            handleChange={handleChange}
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container spacing={2}>
        <MuiGrid xs={12} sm={6}>
          <UserButton label="Apply" onClick={handleApply} />
        </MuiGrid>
        <MuiGrid xs={12} sm={6}>
          <UserButton label="Clear Filters" onClick={handleClear} />
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};

export default FilterComponent;
