import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ChangeRequest } from ".";
import { UserButton } from "../../../components/Button";
import { Paper } from "../../../components/Paper";
import { UserModal } from "../../../components/Modal";
import { Button, makeStyles } from "@material-ui/core";
import {
  formatDisplayDate,
  formatDisplayDateTime,
} from "../../../libs/functions";
import { getPaxType } from "../../../utils/paxType";
import { RouteConstant } from "../../../utils/routeConstant";
import MuiGrid from "../../../components/Grid/item";
import { uniqBy } from "lodash";
import Typography from "../../../components/Typography/index";
import Chip from "@material-ui/core/Chip";
import { getCRStatus } from "../../../actions/ticket";

const useStyles = makeStyles((theme) => ({
  ticketButtonsContainer: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

export default function Ticket(props) {
  const {
    flightDetail,
    flightDetail: {
      pnr,
      createdAt,
      fare,
      passenger,
      segments,
      bookingId,
      status,
      transactionDetails,
      user,
      supplierChangeReqStatus,
    },
    setFlight,
    ticketIndex,
  } = props;
  const [state, setState] = useState({
    passengers: [],
  });

  const dispatch = useDispatch();

  const classes = useStyles();

  const { passengers, sectors, airline } = state;

  const [showChangeModal, setShowChangeModal] = useState(false);

  useEffect(() => {
    if (passenger) {
      setState({
        passengers: passenger.map((c) => ({
          ticketNo: c.ticket?.ticketNumber,
          paxName: `${c.firstName} ${c.lastName} (${getPaxType(c.paxType)})`,
          email: c.email,
          phone: c.contactNo,
        })),
        sectors: segments
          .map((c) => `${c.originAirportCode}-${c.destinationAirportCode}`)
          .join("-"),
        airline: uniqBy(segments, (c) => c.airlineName)
          .map((c) => `${c.airlineName} (${c.airlineCode}-${c.flightNumber})`)
          .join(","),
        flightNumber: uniqBy(segments, (c) => c.flightNumber)
          .map((c) => `${c.flightNumber}`)
          .join(","),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passenger]);

  const toggleModal = () => {
    setShowChangeModal(!showChangeModal);
  };

  const handleAmend = () => {
    setFlight(flightDetail);
  };

  const refreshRequestStatus = () => {
    const ticketCrInfo = props.flightDetail.ticketCRInfo;
    if (ticketCrInfo[0]) {
      dispatch(
        getCRStatus(
          { ChangeRequestId: ticketCrInfo[0]?.changeRequestId, ticketIndex }
          // () => {
          //   dispatch(getTickets());
          // }
        )
      );
    }
  };
  const bookingSlug = btoa(`${bookingId}___${pnr}`);

  return (
    <>
      <Paper
        elevation={4}
        style={{
          backgroundColor: "#eee",
          padding: 20,
          borderBottom: "5px solid #e3e3e3",
          margin: 15,
          borderRadius: 15,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 5,
            top: 5,
          }}
        ></div>

        <MuiGrid container spacing={1}>
          <MuiGrid xs={12} sm={6}>
            <Typography
              body={`Booking Date: ${formatDisplayDate(createdAt)}`}
            />
          </MuiGrid>
          <MuiGrid xs={12} sm={6}>
            <Typography
              body={`Travel Date: ${formatDisplayDateTime(
                segments[0]?.departureTime
              )}`}
            />
          </MuiGrid>

          <MuiGrid xs={12} sm={6}>
            <Typography
              body={`Booking Price: ${
                fare.currency
              } ${transactionDetails.amount.toFixed(2)}`}
            />
          </MuiGrid>

          <MuiGrid xs={12} sm={6}>
            <Typography body={`PNR: ${pnr}`} />
          </MuiGrid>

          <MuiGrid xs={12} sm={6}>
            <Typography body={`${airline}`} />
          </MuiGrid>

          {/* <MuiGrid xs={12} sm={6}>
            <Typography body={`Airline.: ${airline}`} />
          </MuiGrid> */}

          <MuiGrid xs={12} sm={6}>
            <Typography body={`Sectors: ${sectors}`} />
          </MuiGrid>

          <MuiGrid xs={12} sm={6}>
            <Typography
              body={`Status: ${
                supplierChangeReqStatus?.statusText
                  ? supplierChangeReqStatus.statusText
                  : status
              }`}
            />
            {status === "ChangeRequested" && (
              <>
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={refreshRequestStatus}
                >
                  Refresh Status
                </Button>
              </>
            )}
          </MuiGrid>

          {user && (
            <>
              <MuiGrid xs={12} sm={6}>
                {/* <Button onClick={() => props.handleSelectUser(user)}> */}
                <Typography
                  onClick={() => props.handleSelectUser(user)}
                  body={`Created by : ${user.email}`}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                />
                {/* </Button> */}
              </MuiGrid>

              <MuiGrid xs={12} sm={6}>
                <Chip
                  color={user.userType === "retail" ? "primary" : "secondary"}
                  label={user.userType?.toUpperCase()}
                />
              </MuiGrid>
            </>
          )}
        </MuiGrid>
        <hr />
        <div>
          <strong>Tickets: </strong>
          <div className="row">
            <div className="col-md-8">
              <div className="mt-2">
                {passengers && passengers[0]?.email && (
                  <>
                    <i className="far fa-envelope"></i> {passengers[0].email}
                    <br />
                    <i className="fas fa-phone fa-flip-horizontal"></i>{" "}
                    {passengers[0].phone}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <Link
                className="btn btn-sm btn-primary"
                // target="_blank"
                // to={`${RouteConstant.E_TICKET}/${bookingId}`}
                to={`${RouteConstant.E_TICKET}/${bookingSlug}`}
              >
                View Ticket (Full)
              </Link>
            </div>
          </div>
          <ol
            style={{
              marginTop: 10,
            }}
          >
            {passengers.map((c, indx) => (
              <li key={indx}>
                <MuiGrid container>
                  <MuiGrid item xs={12} sm={8}>
                    <Typography body={`Ticket No. : ${c.ticketNo}`} />
                    <Typography body={c.paxName} />
                  </MuiGrid>
                  <MuiGrid item xs={12} sm={4}>
                    <Link
                      // target="_blank"
                      className="btn btn-sm"
                      to={`${RouteConstant.E_TICKET}/${bookingSlug}/?pax=${indx}`}
                    >
                      <strong>
                        <i className="fas fa-print"></i> Ticket
                      </strong>
                    </Link>
                  </MuiGrid>
                </MuiGrid>
              </li>
            ))}
          </ol>
        </div>

        {/* <MuiGrid xs={12}>
          <UserTable rows={passengerRows()} headers={headers} />
        </MuiGrid> */}

        <MuiGrid
          container
          spacing={1}
          className={classes.ticketButtonsContainer}
        >
          {/* <MuiGrid item>
            <UserButton
              fullWidth={false}
              variant="outlined"
              size="small"
              label="Booking History"
              onClick={handleAmend}
            />
          </MuiGrid> */}
          {/* {status === "Ticketing" && ( */}
          <>
            <MuiGrid item>
              <UserButton
                fullWidth={false}
                variant="outlined"
                size="small"
                label="Flight Information"
                onClick={handleAmend}
              />
            </MuiGrid>
            {/* <MuiGrid item>
              <UserButton
                fullWidth={false}
                variant="outlined"
                size="small"
                label="Amend"
                onClick={handleAmend}
              />
            </MuiGrid> */}
            <MuiGrid item>
              <UserButton
                fullWidth={false}
                variant="outlined"
                size="small"
                label={"Change Request"}
                onClick={toggleModal}
              />
            </MuiGrid>
          </>
          {/* )} */}
        </MuiGrid>
      </Paper>

      {showChangeModal && (
        <UserModal
          open={showChangeModal}
          handleClose={toggleModal}
          body={<ChangeRequest {...flightDetail} handleBack={toggleModal} />}
        />
      )}
    </>
  );
}
