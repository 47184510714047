import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  inline: {
    display: "inline-block",
    padding: "0",
    margin: "0",
    fontSize: "10px !important",
  },
}));

const UserCheckbox: React.FC<{
  label: any;
  options?: any;
  name?: any;
  value?: any;
  handleChange: any;
  disabled?: any;
  values?: any;
  fullWidth: any;
  inline?: any;
}> = ({
  label,
  options: initOptions,
  name,
  value,
  handleChange,
  disabled,
  values,
  fullWidth,
  inline = false,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  // const [open, setOpen] = useState('false')

  useEffect(() => {
    if (initOptions)
      setOptions(
        initOptions.map((c) => ({
          ...c,
          // checked: values && includes(values, c.value),
          checked: values && values.includes(c.value),
        }))
      );
  }, [initOptions, values]);

  const handleClick = (event) => {
    if (event) event.stopPropagation();
  };

  const onChange = (event, checked) => {
    if (event) event.stopPropagation();
    if (!initOptions) {
      handleChange(name, checked);
    } else {
      // eslint-disable-next-line eqeqeq
      const index = options.findIndex(
        (c: any) => c.value == event.target.value
      );
      if (index > -1) {
        let list: any = options.slice();
        list[index].checked = checked;
        handleChange(
          name,
          list.filter((c: any) => c.checked).map((c) => c.value)
        );
      }
    }
  };

  const handleAll = (event, checked) => {
    let list = options.map((c: any) => ({ ...c, checked }));
    handleChange(
      name,
      list.filter((c) => c.checked).map((c) => c.value)
    );
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      component="fieldset"
      className={classes.inline}
    >
      {!initOptions ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={value ? value : false}
              onChange={onChange}
              onClick={handleClick}
              name={name}
              disabled={disabled}
            />
          }
          style={{ fontSize: "10px" }}
          labelPlacement={inline ? "end" : "bottom"}
          label={label}
        />
      ) : (
        <>
          {label && <FormLabel component="legend">{label}</FormLabel>}
          {options.length > 1 && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.every((c: any) => c.checked)}
                    onChange={handleAll}
                    disabled={disabled}
                  />
                }
                label="Select All"
              />
            </div>
          )}
          {options.map((c: any, index) => (
            <div key={index} className={classes.inline}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={c.checked ? c.checked : false}
                    onChange={onChange}
                    name={name}
                    value={c.value}
                    disabled={c.disabled || disabled}
                  />
                }
                label={c.text}
              />
            </div>
          ))}
        </>
      )}
    </FormControl>
  );
};

export default UserCheckbox;
