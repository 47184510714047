import React from "react";
import { TextField } from "@material-ui/core";

export default function TextInput({
  label,
  value,
  name,
  handleChange,
  labelProps,
  type = "text",
  isRequired = false,
  errors,
  inputProps = {},
  disabled = false,
}) {
  const onChange = (event) => {
    const value = event.target.value;
    handleChange(name, type === "number" ? parseInt(value) : value);
  };

  const error = errors ? errors[name] : null;

  return (
    <TextField
      {...inputProps}
      value={value ? value : ""}
      className="uk-width-1-2"
      id={name}
      label={label}
      disabled={disabled}
      fullWidth
      variant="outlined"
      autoComplete="new-password"
      type={type}
      InputLabelProps={{
        ...labelProps,
      }}
      size="small"
      onChange={onChange}
      required={isRequired}
      error={error ? true : false}
      helperText={error}
    />
  );
}
