import { camelCase } from "lodash";
import moment from "moment";
import { AppConstant } from "../utils/constant.js";

const timeFormat = "HH:mm:ss";

export const formatToApiDate = (date, time = "00:00:00") =>
  moment(date).format(`${AppConstant.API_DATE_FORMAT} T${time}`);

export const convertToMoment = (date, time = "00:00:00") =>
  moment(date, [
    `${AppConstant.DATE_FORMAT} ${timeFormat}`,
    AppConstant.API_DATETIME_FORMAT,
  ]);

export const convertTimeToMoment = (time = "00:00:00") =>
  moment(time, timeFormat);

export const convertDateToMomentTime = (date) =>
  convertTimeToMoment(
    moment(date, `${AppConstant.API_DATETIME_FORMAT}`).format(timeFormat)
  );

export const convertAPIDateToMoment = (date) =>
  moment(date, AppConstant.API_DATETIME_FORMAT);

export const formatTime = (date) =>
  convertAPIDateToMoment(date).format(`${AppConstant.TIME_DISPLAY_FORMAT}`);

export const formatDisplayDate = (date) => {
  if (date)
    return convertAPIDateToMoment(date).format(
      `${AppConstant.DATE_DISPLAY_FORMAT}`
    );

  return "";
};

export const formatDisplayDateTime = (date) =>
  convertAPIDateToMoment(date).format(
    `${AppConstant.DATE_TIME_DISPLAY_FORMAT}`
  );

export const getMonthAndYearList = () =>
  [...new Array(10)].map((c, index) => ({
    text: moment().add(index, "months").format("MMMM,YYYY"),
    value: moment()
      .add(index, "months")
      .format(AppConstant.SEARCH_API_DATETIME_FORMAT),
  }));

export const getWeekDayList = () => {
  return [...new Array(7)].map((c, index) =>
    moment()
      .startOf("week")
      .add(index, "days")
      .format(AppConstant.DAY_DISPLAY_FORMAT)
  );
};

export const formatPrice = (price, currency, roundOff = false) => {
  if (price) {
    if (roundOff) {
      // price = Math.round(price);
      // price = price;
    } else {
      price = price ? price.toFixed(2) : 0;
    }
  } else {
    price = 0;
  }
  return `${currency} ${price}`;
};

export const getFormattedTimeDifference = (start, end) => {
  const minutesDifference = moment(start).diff(moment(end), "minutes");
  return getTimeFromMins(minutesDifference);
};

export const getTimeFromMins = (totalMins) => {
  // const duration = moment.utc(
  //   moment.duration(mins, "minutes").asMilliseconds()
  // );
  // const duration = moment(moment.duration(mins, "minutes").asMilliseconds());

  // const hours = duration.get("hours");
  // const days = duration.get("date");
  // const minutes = duration.get("minutes");

  let hours = Math.floor(totalMins / 60);
  let mins = totalMins % 60;
  hours = hours < 10 ? "0" + hours : hours; // (or alternatively) h = String(h).padStart(2, '0')
  mins = mins < 10 ? "0" + mins : mins; // (or alternatively) m = String(m).padStart(2, '0')
  return `${hours}h ${mins}m`;
};

export const mapJSONData = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => mapJSONData(v));
  } else if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: mapJSONData(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export const compareString = (str1, str2) => {
  return (
    str1?.replace(" ", "").toLowerCase() === str2.replace(" ", "").toLowerCase()
  );
};
