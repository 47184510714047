export const user = {
  SET_USER_DATA: "SET_USER_DATA",
  SAGA_UPDATE_USER_LE_DATA: "SAGA_UPDATE_USER_LE_DATA",
  UPDATE_USER_LE_DATA: "UPDATE_USER_LE_DATA",
  UPDATE_TAGENT_LOGO: "UPDATE_TAGENT_LOGO",
  UPDATE_PHONE_NUMBER: "UPDATE_PHONE_NUMBER",
  UPDATE_USER_REFERRAL_CODE: "UPDATE_USER_REFERRAL_CODE",
  // UPDATE_USER_PERMISSIONS: "UPDATE_USER_PERMISSIONS",
  LOGOUT: "LOGOUT",
  LOGIN: "LOGIN",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  FORGET_PASSWORD: "FORGET_PASSWORD",
};
