import React from "react";
import MuiTypography from "../../components/Typography";
import MuiGrid from "../../components/Grid/item";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: "1rem auto",
  },
  fareRulesCard: {
    padding: "1rem auto",
    "& p": {
      fontSize: "12px",
      overflowX: "auto",
    },
    "& table": {
      margin: "10px auto",
      overflowX: "scroll",
      fontSize: "11px",
    },
  },
  overflowScroll: {
    overflowY: "auto",
    height: "400px",
    // marginTop: 15,
    marginBottom: 20,
    // border: "1px solid #eee",
    borderRadius: 5,
  },
}));

export default function FareRuleScreen({ fareRules }) {
  const classes = useStyles();
  return (
    <div className={classes.overflowScroll}>
      {fareRules &&
        fareRules.map((c, index) => (
          <React.Fragment key={index}>
            <MuiGrid xs={12}>
              <MuiTypography
                variant="h6"
                body={`○\xa0${c.origin} → ${c.destination}`}
              />
            </MuiGrid>
            <MuiGrid xs={12} className={classes.fareRulesCard}>
              <div
                className="fareRulesHtml"
                dangerouslySetInnerHTML={{ __html: c.fareRuleDetail }}
              ></div>
            </MuiGrid>
          </React.Fragment>
        ))}
    </div>
  );
}
