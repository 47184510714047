import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { wlUserSearchDb } from "api/user";
// import { wlUserTransactionSearchByPNR } from "api/user";
import React, { useEffect, useState } from "react";

export const SearchUsersAutocomplete: React.FC<{
  setUser: Function;
  rowClass?: string;
}> = (props) => {
  const [users, setUsers] = useState<
    Array<{
      id: number;
      email: string;
      phoneNumbers: any[];
      firstName: string;
      lastName: string;
      userType: string;
      company: any;
    }>
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleChange = (e, val) => {
    props.setUser(val);
  };

  const searchUserFromDbViaSearchQuery = async () => {
    if (searchTerm) {
      const userSearchResult = await wlUserSearchDb({
        searchQuery: searchTerm,
      });
      if (userSearchResult?.data?.err)
        return alert(userSearchResult?.data?.err);
      if (userSearchResult.data) {
        setUsers(userSearchResult.data);
        // getTotalAmountForWlUser(userSearchResult.data.id);
      }
    }
  };

  useEffect(() => {
    let waitForSearch;
    if (searchTerm) {
      waitForSearch = setTimeout(() => {
        searchUserFromDbViaSearchQuery();
      }, 400);
    }

    return () => clearTimeout(waitForSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // useEffect(() => {
  //   if (routeParams.siteId) {
  //     searchWl(routeParams.siteId);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [routeParams.siteId]);

  const handleInputChange = async (e, val) => {
    // if (searchRes.error) return alert(searchRes.err);
    setSearchTerm(val);
  };

  return (
    <div className="row justify-content-center my-3 mt-5">
      <div className={`${props.rowClass ? props.rowClass : "col-md-6"}`}>
        <Autocomplete
          options={users}
          getOptionLabel={(option) => {
            // console.log(option);
            return `${option.email?.toString()} ${option.id?.toString()} ${option.phoneNumbers
              .find((pn) => pn.label === "Primary")
              ?.phoneNumber?.toString()} ${option.firstName.toString()} ${option.lastName.toString()}`;
          }}
          fullWidth
          renderOption={(option, st) => {
            return (
              <div className="w-100 border-bottom">
                {`#${option.id} ${(
                  option.firstName.toString() +
                  " " +
                  option.lastName.toString()
                ).toUpperCase()}`}
                <br />
                <i>({option.userType})</i>
                <br />
                {option.email?.toString()}
                <br />
                {
                  option.phoneNumbers.find((pn) => pn.label === "Primary")
                    ?.phoneNumber
                }
              </div>
            );
          }}
          onInputChange={handleInputChange}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              // style={{ zIndex: -1 }}
              label={"Search Users"}
              variant="outlined"
            />
          )}
        />
      </div>
      <div className={`${props.rowClass ? props.rowClass : "col-md-6"}`}>
        <Autocomplete
          options={users}
          getOptionLabel={(option) => {
            // console.log(option);
            // if()
            return `${option.company?.companyName}`;
          }}
          fullWidth
          renderOption={(option, st) => {
            return (
              <div className="w-100 border-bottom">
                {`#${option.id} ${(
                  option.firstName.toString() +
                  " " +
                  option.lastName.toString()
                ).toUpperCase()}`}
                <br />
                {option.company?.companyName ? (
                  <b>
                    <i className="fas fa-building me-2"></i>
                    {option.company?.companyName.toString()}
                  </b>
                ) : (
                  <span>
                    <i className="fas fa-user me-2"></i>
                    <b>B2C User</b>
                  </span>
                )}
                <br />
                {option.email && (
                  <>
                    <span>{option.email?.toString()}</span>
                    <br />
                  </>
                )}
                {option.phoneNumbers.length && (
                  <>
                    <span>
                      {
                        option.phoneNumbers?.find(
                          (pn) => pn.label === "Primary"
                        )?.phoneNumber
                      }
                    </span>
                    <br />
                  </>
                )}
              </div>
            );
          }}
          onInputChange={handleInputChange}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              // style={{ zIndex: -1 }}
              label={"Search Users By Company Name"}
              variant="outlined"
            />
          )}
        />
      </div>
    </div>
  );
};
