import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJobsheetDateRangeState } from "dmcMaster/jobSheet/DmcJobsheet";
import moment from "moment";
import { RootState } from "redux/store";

export interface IDMCJobTransfer {
  dateRange: IJobsheetDateRangeState;
  selectedDriverId?: number;
  sortBy?: string;
}

const initialState: IDMCJobTransfer = {
  dateRange: {
    from: new Date(),
    to: moment().add(1, "days").toDate(),
  },
};

export const DmcJobTransferSlice = createSlice({
  name: "DmcJobTransfer",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDateRange: (state, action: PayloadAction<IJobsheetDateRangeState>) => {
      state.dateRange = action.payload;
    },
    setSortby: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setSelectedDriverId: (state, action: PayloadAction<number>) => {
      state.selectedDriverId = action.payload;
    },
  },
});

export const DmcJobTransferActions = DmcJobTransferSlice.actions;

export const selectJobTransferSlice = (state: RootState) => {
  return state.dmcJobTransfer;
};

export const DmcJobTransferReducer = DmcJobTransferSlice.reducer;
