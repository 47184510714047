import axios, { AxiosRequestConfig, Method } from "axios";
import { AppConstant } from "fd-react/utils/constants";
import { toast } from "react-toastify";
import { PARTNER_SIPPI_BASE_URLS } from "tools/constants";
import { getAuthToken, redirection401 } from "tools/functions";

// export const postRequestWl = async (url, data) => {

//   console.log(JSON.stringify(headers))
//   const r = await axios({
//     url: `/api/wl/${url}`,
//     method: 'POST',
//     data,
//     headers: headers
//   })
//   return r
// }

// export const postRequest = async ({ url, data }) => {
//   const r = await axios({
//     url,
//     method: 'POST',
//     data
//   })
//   return r
// }

interface IAxios {
  url: string;
  method: Method;
  data: object;
  baseUrl?: string;
  headers?: object;
}

const _getHeaders = (props: IAxios) => {
  const siteDataString = localStorage.getItem("siteData");
  const siteId = siteDataString ? JSON.parse(siteDataString)._id : null;

  // if auth token is not present then return empty headers
  const authToken = getAuthToken();
  const staffToken = getAuthToken();
  return {
    "site-id": siteId,
    "staff-id": staffToken, // FIXME: Remove staff-id if not required
    "auth-token": authToken,
    ...props?.headers,
    // 'auth-token': props.data && props.data.staffId ? props.data.staffId : '',
  };
};

const _axiosRequest = async (props: IAxios) => {
  try {
    /**
     * if GET request then we pass params object
     * else we pass data object
     */
    const dataOrParams = props.method === "GET" ? "params" : "data";
    const baseUrl = props.baseUrl || `/api/`;
    const axiosOptions: AxiosRequestConfig = {
      url: baseUrl + props.url,
      method: props.method,
      [dataOrParams]: props.data ? props.data : {},
      headers: _getHeaders(props),
    };
    const axiosResponse = await axios(axiosOptions);
    return axiosResponse;
  } catch (err: any) {
    // if 401 then redirect to logout
    if (err.response.status === 401) {
      redirection401();
      return;
    }

    if (err.response) {
      let data = err.response.data;
      if (data.success === false && data.errorMessage) {
        toast.error(data.errorMessage);
        return data;
      }
    } else {
      console.log({
        msg: "HTTP Request failed",
        err,
      });
    }
  }
};
export const postRequest = async (
  url,
  data,
  baseUrl: undefined | string = undefined,
  headers?: object
) => {
  try {
    const result = await _axiosRequest({
      url,
      data,
      method: "POST",
      baseUrl,
      headers,
    });
    if (result) {
      return result;
    } else {
      return {
        success: false,
        errorMessage:
          "Something went wrong. HTTP (POST) request failed. Please contact support.",
      };
    }
  } catch (err) {
    console.log("POST REQUEST FAILED \n\n", err);
  }
};

export const getRequest = async (
  url,
  data,
  baseUrl: undefined | string = undefined
) => {
  try {
    const result = await _axiosRequest({
      url,
      data,
      method: "GET",
      baseUrl,
    });
    if (result) {
      return result;
    } else {
      return {
        success: false,
        errorMessage:
          "Something went wrong. HTTP (GET) request failed. Please contact support.",
      };
    }
  } catch (err) {
    console.log("GET REQUEST FAILED \n\n", err);
  }
};

export const deleteRequest = async (url, data) => {
  try {
    const result = await _axiosRequest({
      url,
      data,
      method: "DELETE",
    });
    if (result) {
      return result.data;
    } else {
      return {
        success: false,
        errorMessage:
          "Something went wrong. HTTP (DELETE) request failed. Please contact support.",
      };
    }
  } catch (err) {
    console.log("DELETE REQUEST FAILED \n\n", err);
  }
};

export const partenerSippiAjax = async (
  method: Method,
  url: string,
  data: any
) => {
  try {
    const partnerSippiBaseUrl = PARTNER_SIPPI_BASE_URLS.PARTNER_SIPPI + "/";
    const result = await _axiosRequest({
      url,
      data,
      method,
      baseUrl: partnerSippiBaseUrl,
    });
    if (result) {
      return result.data;
    } else {
      return {
        success: false,
        errorMessage:
          "Something went wrong. HTTP (DELETE) request failed. Please contact support.",
      };
    }
  } catch (err) {
    console.log("DELETE REQUEST FAILED \n\n", err);
  }
};

export const proxyPartnerSippi = async (
  method: Method,
  url: string,
  data: any
) => {
  return await postRequest("psForward", {
    url: url,
    data: data,
    method: method,
  });
};
