import {common} from '../actionTypes/commonTypes'

export const SET_LOADING = (payload) => {
    return {
        type: common.LOADING,
        payload
    }
}

export const SET_ERROR = (error) => {
    return {
        type: common.SET_ERROR,
        payload: {
            errorMessage: error
        }
    }
}

export const SET_ALERT_MESSAGE = (message) => {
    return {
        type: common.SET_ALERT_MESSAGE,
        payload: message
    }
} 

export const HIDE_ALERT_MESSAGE = () => {
    return {
        type: common.HIDE_ALERT_MESSAGE
    }
}

export const GET_WL_USER_WALLET_BALANCE = (data) => {
    return {
        type: common.GET_WL_USER_WALLET_BALANCE,
        payload: data
    }
}

export const SET_WL_USER_WALLET_BALANCE = (data) => {
    return {
        type: common.SET_WL_USER_WALLET_BALANCE,
        payload: data
    }
}


export const GET_BANK_DETAILS = (payload) => ({
    type: common.GET_BANK_DETAILS,
    payload
})

export const SET_BANK_DETAILS = (apiRes) => ({
    type: common.SET_BANK_DETAILS,
    payload : apiRes
})

export const SET_HEADER_POSITION = (position) => ({
    type: common.SET_HEADER_POSITION,
    payload: {
        position
    }
})