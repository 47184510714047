import React from "react"
import Typography, { TypographyProps } from "@material-ui/core/Typography"


const UserTypography: React.FC<TypographyProps & {
    body: any;
}> = (props) => {
    const { body } = props
    return (
        <Typography  {...props}>
            {body}
        </Typography>
    )
}

export default UserTypography;