import { ErrorText } from "components/errors/ErrorText";
import React from "react";
import IntlTelInput, { IntlTelInputProps } from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { countryDataList } from "tools/countryData";

interface Props extends IntlTelInputProps {
  label: string;
  onChange: (phoneNumber: string, phoneCode: string, isValid: boolean) => void;
  dialCode?: string;
  errorMessage?: string;
  isRequiredField?: boolean;
}

export const IntlTelInputWrapper: React.FC<Props> = (props) => {
  const defaultCountry = props.dialCode
    ? countryDataList
        .find((country) => country.dial_code === props.dialCode)
        ?.code.toLowerCase()
    : "in";
  // console.log(defaultCountry);

  return (
    <React.Fragment>
      <label htmlFor={props.fieldId} className="mb-3">
        {props.label}{" "}
        {props.isRequiredField && <span className="text-danger">*</span>}
      </label>
      <IntlTelInput
        fieldId={props.fieldId}
        placeholder="Enter Mobile Number"
        preferredCountries={["in"]}
        containerClassName={`intl-tel-input w-100`}
        defaultValue={props.defaultValue}
        defaultCountry={defaultCountry}
        onSelectFlag={(currentNumber, countryData, fullNumber, isValid) => {
          const dialCode = countryData.dialCode;
          props.onChange(currentNumber, `+${dialCode}`, isValid);
        }}
        onPhoneNumberChange={(isValid, value, selectedCountry) => {
          const dialCode = `+${selectedCountry.dialCode}`;
          let phoneNumber = value;
          if (value.includes(dialCode)) {
            phoneNumber = value.replace(dialCode, "");
          }
          props.onChange(phoneNumber.trim(), dialCode, isValid);
        }}
        formatOnInit={false}
        inputClassName="form-control m-0 w-100"
        style={{ width: "100%" }}
      />
      <ErrorText error={props.errorMessage} />
    </React.Fragment>
  );
};
