import APIUtil from "./APIUtil";

class BookingAPI {
  getPassengerViewOptions(payload) {
    return APIUtil.callPostAPI("api/getPassengerViewOptions", payload);
  }

  getCountryList(payload) {
    return APIUtil.callGetAPI("api/getCountryList", payload);
  }

  getPhoneCodeList(payload) {
    return APIUtil.callGetAPI("api/getPhoneCodeList", payload);
  }

  getNationalities(payload) {
    return APIUtil.callGetAPI("api/getNationalities", payload);
  }

  getFareRules(payload) {
    return APIUtil.callPostAPI("api/getFareRules", payload);
  }

  setFlightDetails(payload) {
    return APIUtil.callPostAPI("api/setFlightDetails", payload);
  }

  getFlightDetails(payload) {
    return APIUtil.callPostAPI("api/getFlightDetails", payload);
  }

  validateBooking(payload) {
    return APIUtil.callPostAPI("api/validateBooking", payload);
  }

  getSSRDetails(payload) {
    return APIUtil.callPostAPI("api/getSSR", payload);
  }

  fareQuote(payload) {
    return APIUtil.callPostAPI("api/fareQuote", payload);
  }

  // bookFlight(payload) {
  //   return APIUtil.callPostAPI("api/bookFlight", payload);
  // }

  // getTicket(payload) {
  //   return APIUtil.callPostAPI("api/getTicket", payload);
  // }

  flightOrderSuccessCallback(payload) {
    return APIUtil.callPostAPI("api/payments/orderSuccessCallback", payload);
  }

  getBookingDetails(payload) {
    return APIUtil.callPostAPI("api/getBookingDetails", payload);
  }
}

export default new BookingAPI();
