/* eslint-disable eqeqeq */
import { sumBy } from "lodash";
import React, { ChangeEvent, useEffect } from "react";
import { TextInput } from "../../../components/TextInput";
import {
  formatDisplayDate,
  formatDisplayDateTime,
} from "../../../libs/functions";
import "../eticket.scss";
import auth from "../../../libs/auth";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import { useLocation } from "react-router-dom";
// const queryString = require("query-string");
import bwipjs from "bwip-js";
import moment from "moment";

const noOfColumns = 5;

const tdBorderedStyle = {
  border: "1px solid #dee2e6",
  padding: "0.75rem",
  verticalAlign: "top",
};

const passengerTableTd = {
  padding: "10px",
  borderLeft: "1px solid #dee2e6",
  verticalAlign: "middle",
};

const UserLabel = ({ label, value }) => {
  return (
    // <table>
    <tr>
      <td>
        <b>{label}</b>
      </td>
      <td>&nbsp;&nbsp;{value}</td>
    </tr>
  );
};

const ETicket: React.FC<{
  baggages: any;
  meals: any;
  singlePax?: string;
  ticketDetail: any;
  hidePayment: boolean;
  editFee: boolean;
  editDiscount: boolean;
  discount: string;
  txnFee?: string;
  handleChange: any;
  errors: any;
  mergeTxnFeeTaxes: boolean;
  ticketLogoUrl?: string;
  toggleMergeTxnFeeTaxes: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}> = React.forwardRef((props, ref: any) => {
  const printBarCode = (paxDetail, paxIndex) => {
    try {
      // props.ticketDetail?.passenger?.forEach((pax, paxIndex) => {
      if (paxDetail.barcodeDetails) {
        paxDetail.barcodeDetails?.barcode?.forEach((barcode, barcodeIndex) => {
          // The return value is the canvas element
          bwipjs.toCanvas(`mycanvas${paxIndex}${barcodeIndex}`, {
            bcid: "pdf417", // Barcode type
            text: barcode.content, // Text to encode
            scale: 2, // 3x scaling factor
            height: 6.5, // Bar height, in millimeters
            includetext: true, // Show human-readable text
            textxalign: "center", // Always good to set this
          });
        });
      } else {
        segments.forEach((seg, segIndex) => {
          let lastSlashFirst = `${paxDetail.lastName.toUpperCase()}/${paxDetail.firstName.toUpperCase()}`;

          let countNameChar = lastSlashFirst.length;

          if (countNameChar > 20) {
            lastSlashFirst = lastSlashFirst.substring(0, 20);
            countNameChar = 20;
          }

          let countNameCharToFill = 20 - countNameChar;

          lastSlashFirst = lastSlashFirst + " ".repeat(countNameCharToFill);

          let barCode = `M1${lastSlashFirst} ${
            seg.airlinePnr || props.ticketDetail.pnr
          } ${seg.originAirportCode}${seg.destinationAirportCode}${
            seg.operatingCarrier || seg.airlineCode
          }`;

          let flightNo = seg.flightNumber;

          if (flightNo.length < 4) {
            flightNo = flightNo.padStart(4, "0");
          }

          barCode += " " + flightNo + " ";

          // day of year eg 1st Jan = 1, 31st Dec = 365 or 366

          let departureDate = moment(seg.departureTime);

          let dayOfYear = departureDate.dayOfYear().toString();

          if (dayOfYear.length === 2) {
            dayOfYear = "0" + dayOfYear;
          } else if (dayOfYear.length === 1) {
            dayOfYear = "00" + dayOfYear;
          }

          barCode += dayOfYear;

          if (!seg.fareClass || seg.fareClass.length > 1) {
            barCode += "Y";
          } else {
            barCode += seg.fareClass;
          }

          barCode += "0".repeat(9);

          // random between 0 and 1
          let randomNo = Math.random();

          if (randomNo > 0.5) {
            barCode += "000";
          } else {
            barCode += "100";
          }

          bwipjs.toCanvas(`myCustomCanvas${paxIndex}${segIndex}`, {
            bcid: "pdf417", // Barcode type
            text: barCode, // Text to encode
            scale: 2, // 3x scaling factor
            height: 6.5, // Bar height, in millimeters
            includetext: true, // Show human-readable text
            textxalign: "center", // Always good to set this
          });
        });
      }
      // });
    } catch (e) {
      // `e` may be a string or Error object
    }
  };

  const segment = ({
    airlineName,
    airlineCode,
    flightNumber,
    fareClass,
    originAirportName,
    originCityName,
    originAirportCode,
    originTerminal,
    arrivalTime,
    destinationAirportCode,
    destinationCityName,
    destinationAirportName,
    destinationTerminal,
    departureTime,
    flightStatus,
    airlinePnr,
    baggage,
    cabinBaggage,
    stopPoint,
    stopPointArrivalTime,
    stopPointDepartureTime,
  }) => {
    const { baggages, meals } = props;

    const additionalMeal = sumBy(
      meals.filter((c: any) => c?.flightNumber == flightNumber),
      (c: any) => c.quantity
    );
    const additionalBaggage = sumBy(
      baggages.filter((bag) => bag?.flightNumber == flightNumber),
      (c: any) => c?.weight
    );

    return {
      flight: (
        <>
          <table
            className=" "
            style={{
              width: "100%",
            }}
          >
            <tr>
              <td style={tdBorderedStyle}>
                <b>{airlineName}</b> ({airlineCode} {flightNumber})<br />(
                {fareClass} Class)
              </td>
              <td style={tdBorderedStyle}>
                Status: {flightStatus}
                <br />
                {airlinePnr !== "" && <>Airline PNR: {airlinePnr}</>}
              </td>

              <td style={tdBorderedStyle}>
                Baggage: {baggage}
                <br /> Cabin Baggage: {cabinBaggage}
                <br />
                {additionalBaggage > 0 && (
                  <>
                    Additional Baggage:&nbsp;
                    {additionalBaggage} KG
                    <br />
                  </>
                )}
                {additionalMeal > 0 && (
                  <>
                    Additional Meal:&nbsp;
                    {additionalMeal} Platter
                  </>
                )}
              </td>
            </tr>
          </table>
        </>
      ),
      departure: (
        <>
          {originAirportCode} ({`${originAirportName}, ${originCityName}`})
          <br />
          Terminal {originTerminal}
          <br />
          {formatDisplayDateTime(departureTime)}
        </>
      ),
      arrival: (
        <>
          {destinationAirportCode} (
          {`${destinationAirportName}, ${destinationCityName}`})
          <br />
          Terminal {destinationTerminal}
          <br />
          {formatDisplayDateTime(arrivalTime)}
        </>
      ),
      status: <></>,
      technicalStopJSX: stopPoint ? (
        <tr>
          <td colSpan={2}>
            <div>
              <span className="text-danger">
                Technical Stop at {stopPoint}
                <br />
                Arrival: {moment(stopPointArrivalTime).format("DD MMM")}{" "}
                {moment(stopPointArrivalTime).format("HH:mm")}
                <br />
                Departure: {moment(stopPointDepartureTime).format(
                  "DD MMM"
                )}{" "}
                {moment(stopPointDepartureTime).format("HH:mm")}
                <br />
              </span>
            </div>
          </td>
        </tr>
      ) : (
        <></>
      ),
    };
  };

  const renderSegments = (segments) => {
    if (segments) return segments.map(segment);

    return [];
  };

  const {
    editFee,
    editDiscount,
    discount,
    txnFee,
    handleChange,
    errors,
    ticketDetail,
    mergeTxnFeeTaxes,
  } = props;

  const {
    pnr,
    segments,
    // transactionDetails,
    user,
    isLcc,
    passenger: ticketPassengers,
    // fare,
    fare,
  }: {
    pnr: any;
    segments: any;
    // transactionDetails,
    user: any;
    isLcc: any;
    passenger: any;
    // fare
    fare?: {
      // baseFare,
      tax: number;
      otherCharges: number;
      totalFare: number;
      roundedTotalFare: number;
      baggageMealCharges: number;
    };
  } = ticketDetail || {};

  const { companyName, logoUrl, adminContact, adminEmail } = auth.siteDetails;
  const b2bUserPhoneNumber =
    user?.phoneNumbers?.find((phone) => phone.label === "Primary")
      ?.phoneNumber ||
    user?.phone ||
    "";

  return ticketDetail ? (
    <div ref={ref} className="eticket-wrapper">
      <table
        className=" eticket-table"
        // style={{

        // }}
      >
        <tr>
          <td colSpan={2} width={"20%"}>
            {user && user.company ? (
              props.ticketLogoUrl ? (
                <div>
                  <img
                    src={props.ticketLogoUrl}
                    className="img-fluid"
                    alt={user.company.companyName}
                  />
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "#333",
                    color: "#fff",
                    borderRadius: 50,
                    fontSize: 16,
                    padding: "5px 15px",
                    display: "inline-block",
                  }}
                >
                  {user.company.companyName}
                </div>
              )
            ) : (
              <img
                src={logoUrl}
                alt={companyName}
                style={{ width: "50%", height: "auto" }}
              />
            )}
          </td>
          <td
            width={"33%"}
            align="center"
            style={{
              fontSize: 20,
            }}
          >
            <h4 className="heading">E-Ticket</h4>
          </td>
          <td colSpan={2} width={"33%"}>
            {user && user.company ? (
              <>
                <h5 style={{ fontSize: 16, marginBottom: 10 }}>
                  {user.company.companyName}
                </h5>
                {b2bUserPhoneNumber && (
                  <UserLabel label="Phone" value={b2bUserPhoneNumber} />
                )}
                <UserLabel label="Email" value={user.email} />
                <UserLabel
                  label="Address"
                  value={`${user.company.address1}, ${user.company.address2}`}
                />
              </>
            ) : (
              <>
                <h5 style={{ fontSize: 16, margin: 0 }}>{companyName}</h5>
                <UserLabel label="Phone" value={adminContact} />
                <UserLabel label="Email" value={adminEmail} />
              </>
            )}
          </td>
        </tr>
        <tr style={{ lineHeight: 40 }}>
          <td colSpan={noOfColumns}></td>
        </tr>
        <tr>
          <td colSpan={noOfColumns}>
            <UserLabel label="PNR" value={pnr} />
            <UserLabel
              label="Issue Date"
              value={formatDisplayDate(props.ticketDetail.invoiceCreatedOn)}
            />
          </td>
        </tr>

        <tr>
          <td colSpan={5}>
            <table
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: 25,
                ...tdBorderedStyle,
              }}
            >
              <tr style={tdBorderedStyle}>
                <td style={passengerTableTd}>
                  <b>Passenger Name</b>
                </td>
                <td style={passengerTableTd}>
                  <b>Ticket Number</b>
                </td>
                <td style={passengerTableTd}>
                  <b>Baggage</b>
                </td>
              </tr>
              {ticketPassengers &&
                ticketPassengers
                  .filter((passe, paxIndx) => {
                    if (props.singlePax) {
                      return paxIndx === parseInt(props.singlePax);
                    } else {
                      return true;
                    }
                  })
                  .map((pass, paxindx) => {
                    printBarCode(pass, paxindx);
                    return (
                      <>
                        <tr style={tdBorderedStyle}>
                          <td
                            style={passengerTableTd}
                          >{`${pass.firstName} ${pass.lastName}`}</td>
                          <td style={passengerTableTd}>
                            {pass.ticket.validatingAirline}-
                            {pass.ticket.ticketNumber}
                          </td>
                          <td style={passengerTableTd}>
                            {/* <pre>{JSON.stringify(pass, null, 2)}</pre> */}
                            {pass.segmentAdditionalInfo.map(
                              (pasSegment, pasSegindx) => {
                                return (
                                  <>
                                    ({segments[pasSegindx].originAirportCode} -{" "}
                                    {
                                      segments[pasSegindx]
                                        .destinationAirportCode
                                    }
                                    ) Baggage: {pasSegment.baggage}
                                    <br />
                                  </>
                                );
                              }
                            )}

                            {/* {isLcc && segments && segments.length
                            ? segments?.map((seg) => (
                                <>
                                  {seg.originCityCode} -{" "}
                                  {seg.destinationCityCode} : {seg.baggage}{" "}
                                  (Baggage), {seg.cabinBaggage} (Cabin)
                                  <br />
                                </>
                              ))
                            : pass.segmentAdditionalInfo.map(
                                (pasSegment, pasSegindx) => {
                                  return (
                                    <>
                                      ({segments[pasSegindx].originCityCode} -{" "}
                                      {segments[pasSegindx].destinationCityCode}
                                      ) Baggage: {pasSegment.baggage}
                                      <br />
                                    </>
                                  );
                                }
                              )} */}
                          </td>
                        </tr>

                        <tr style={tdBorderedStyle}>
                          {/* <td colSpan={3}>
                          <pre>
                            {JSON.stringify(
                              pass.barcodeDetails.barcode,
                              null,
                              2
                            )}
                          </pre>
                        </td> */}

                          {pass.barcodeDetails ? (
                            pass.barcodeDetails?.barcode?.map(
                              (bcode, barcodeIndx) => {
                                const barcodeText = bcode.content;

                                // Split the barcode text into an array of words by space (1 or more spaces)
                                const words = barcodeText.split(/\s+/);

                                // const psplit = words[2]; // Get the third element (index 2) from the resulting array

                                return (
                                  <td>
                                    <div
                                      style={{
                                        marginTop: 10,
                                      }}
                                    >
                                      <canvas
                                        id={`mycanvas${paxindx}${barcodeIndx}`}
                                      ></canvas>
                                      {/* <div>{JSON.stringify(words[0])}</div>
                                      <div>{JSON.stringify(words[1])}</div>
                                      <div>{JSON.stringify(words[2])}</div> */}
                                    </div>
                                  </td>
                                );
                              }
                            )
                          ) : (
                            <>
                              {segments.map((seg, segIndex) => {
                                return (
                                  <td>
                                    <div
                                      style={{
                                        marginTop: 10,
                                      }}
                                    >
                                      <canvas
                                        id={`myCustomCanvas${paxindx}${segIndex}`}
                                      ></canvas>
                                    </div>
                                  </td>
                                );
                              })}
                            </>
                          )}
                        </tr>
                      </>
                    );
                  })}
              <tr>
                <td colSpan={3}>
                  <p
                    style={{
                      color: "red",
                      // textAlign: "left",
                      marginLeft: 20,
                    }}
                  >
                    If Barcode doesn't work, please download original ticket
                    from Airline's website.
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan={noOfColumns}>
            <hr />
          </td>
        </tr>
        <tr>
          <td colSpan={noOfColumns}>
            <table
              style={{
                width: "100%",
                marginTop: 25,
                marginBottom: 25,
                textAlign: "center",
              }}
            >
              <tr>
                <td colSpan={2}>
                  <h4
                    style={{
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    Flight Details
                  </h4>
                </td>
              </tr>
              {renderSegments(segments).map((c) => (
                <>
                  <tr>
                    <td style={tdBorderedStyle}>
                      <b>Departure:</b>
                      <br /> {c.departure}
                    </td>
                    <td style={tdBorderedStyle}>
                      <b>Arrival:</b>
                      <br /> {c.arrival}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>{c.flight}</td>
                  </tr>
                  {c.technicalStopJSX}
                </>
              ))}
            </table>
          </td>
        </tr>
        {!props.hidePayment && !props.singlePax && (
          <>
            <tr>
              <td colSpan={noOfColumns}>
                <h2 style={{ fontSize: 20 }}>Payment Details</h2>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                This is an electronic ticket. Please carry a positive
                identification for check in.
              </td>
              {fare?.totalFare && (
                <td colSpan={2}>
                  <UserLabel
                    label="Fare"
                    value={
                      fare.totalFare +
                      parseFloat(discount) -
                      fare.baggageMealCharges -
                      fare.otherCharges -
                      fare.tax -
                      parseFloat(txnFee || "0")
                    }
                  />
                  <UserLabel
                    label="Taxes & Fee"
                    // value={parseFloat(tax?.toString()) + mergeTxnFeeTaxes ? parseFloat(txnFee?.toString()) : 0}
                    value={
                      fare.tax +
                      (mergeTxnFeeTaxes ? parseFloat(txnFee || "0") : 0)
                    }
                  />
                  <UserLabel label="Surcharge" value={fare.otherCharges} />
                  <UserLabel
                    label="Baggage/Meal"
                    value={fare.baggageMealCharges}
                  />
                  {editFee ? (
                    <div className="row rowMargin mt-3">
                      <div className="col-12">
                        <TextInput
                          value={txnFee}
                          name="txnFee"
                          label="Txn Fee"
                          labelProps={{ shrink: true }}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={mergeTxnFeeTaxes}
                              onChange={props.toggleMergeTxnFeeTaxes}
                              color="primary"
                            />
                          }
                          label="Merge Txn Fee to Taxes"
                        />
                      </div>
                    </div>
                  ) : (
                    !mergeTxnFeeTaxes && (
                      <UserLabel label="Txn Fee" value={txnFee} />
                    )
                  )}
                  {editDiscount ? (
                    <div className="row rowMargin mt-3">
                      <div className="col-12">
                        <TextInput
                          value={discount}
                          name="discount"
                          label="Add Discount"
                          labelProps={{ shrink: true }}
                          handleChange={handleChange}
                          errors={errors}
                        />
                      </div>
                    </div>
                  ) : (
                    <UserLabel label="Discount" value={discount} />
                  )}
                  <UserLabel
                    label="Total Fare"
                    value={fare.totalFare.toFixed(2)}
                  />
                  <UserLabel
                    label="(Rounded Off)"
                    value={fare.roundedTotalFare.toFixed(2)}
                  />
                </td>
              )}
            </tr>
          </>
        )}

        <tr>
          <td colSpan={noOfColumns}>
            <table style={{ marginTop: 25, marginBottom: 25 }}>
              <p>
                Carriage and other services provided by the carrier are subject
                to conditions of carriage which hereby incorporated by
                reference. These conditions may be obtained from the issuing
                carrier. If the passenger's journey involves an ultimate
                destination or stop in a country other than country of departure
                the Warsaw convention may be applicable and the convention
                governs and in most cases limits the liability of carriers for
                death or personal injury and in respect of loss of or damage to
                baggage.
              </p>
              <p className="text-danger">
                Don't Forget to purchase travel insurance for your Visit. Please
                Contact your travel agent to purchase travel insurance.
              </p>
            </table>
          </td>
        </tr>
      </table>
    </div>
  ) : (
    <div className="text-center">{/* <h1>No Data Found</h1> */}</div>
  );
});

export default ETicket;
