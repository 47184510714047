import { user } from "../actionTypes/userTypes";
const initialState = {
  legalEntity: {},
};
export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case user.SET_USER_DATA:
      return action.payload;

    case user.LOGOUT:
      return {};

    case user.UPDATE_USER_LE_DATA:
      return {
        ...state,
        legalEntity: action.payload,
      };

    case user.UPDATE_TAGENT_LOGO:
      return {
        ...state,
        taLogoUrl: action.payload,
      };

    case user.UPDATE_PHONE_NUMBER:
      // update phone number
      return {
        ...state,
        phoneNumbers: action.payload,
      };

    case user.UPDATE_USER_REFERRAL_CODE:
      // update user referral code
      return {
        ...state,
        referralCode: action.payload,
      };

    default:
      return state;
  }
}
