import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import { AppConstant } from "../../utils/constant";

const UserDatePicker: React.FC<{
  label: any;
  value: any;
  name: any;
  handleChange: any;
  id?: any;
  minDate: any;
  maxDate?: any;
  errors: any;
  disabled?: any;
  isRequired?: any;
}> = ({
  label,
  value,
  name,
  handleChange,
  minDate,
  maxDate,
  errors,
  id,
  disabled = false,
  isRequired,
}) => {
  const onChange = (date) => {
    handleChange(name, date);
  };

  const error = errors ? errors[name] : null;

  // const detePickerRef = useRef();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        // ref={detePickerRef}
        id={id}
        label={label}
        format={AppConstant.DATE_FORMAT}
        minDate={minDate}
        value={value ? value : null}
        error={error ? true : false}
        helperText={error}
        fullWidth
        autoOk
        required={isRequired}
        inputVariant="outlined"
        size="small"
        // inputProps={{

        // }}
        onChange={onChange}
        disabled={disabled}
        maxDate={maxDate}
        // KeyboardButtonProps={ {
        //     "aria-label": "change date",
        //   }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default UserDatePicker;
