import React, { useEffect, useState } from "react";
import { groupBy, map } from "lodash";
import { UserCard } from "../../../components/Container";
import { UserLabel } from "../../../components/Label";
import {
  formatDisplayDate,
  formatDisplayDateTime,
} from "../../../libs/functions";
import { getPaxType } from "../../../utils/paxType";
import { UserButton } from "../../../components/Button";
import { releaseFlight } from "../../../actions/ticket";
import { useDispatch } from "react-redux";

export default function BookedFlight({ setFlight, flightDetail }) {
  const {
    status,
    pnr,
    createdAt,
    fare,
    passenger,
    segments,
    bookingId,
    source,
  } = flightDetail;
  const [passengers, setPassengers] = useState(null);
  const [segmentDetail, setSegmentDetail] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (passenger) {
      setPassengers(
        map(
          groupBy(passenger, (c) => c.paxType),
          (values, key) => `${values.length} ${getPaxType(key)}`
        ).join("|")
      );
    }
  }, [passenger]);

  useEffect(() => {
    if (segments) {
      setSegmentDetail(
        segments
          .map((c) => `${c.originAirportCode}-${c.destinationAirportCode}`)
          .join("-")
      );
    }
  }, [segments]);

  const handleOpen = () => {
    setFlight(flightDetail);
  };

  const handleRelease = () => {
    dispatch(
      releaseFlight({
        BookingId: bookingId,
        Source: source,
      })
    );
  };

  const { title, firstName, lastName } = passenger[0];

  return (
    <UserCard>
      <div className="row rowMargin">
        <div className="col-4"></div>
        <div className="col-4">
          <UserLabel label={status} />
        </div>
        <div className="col-4">
          <UserLabel label="PNR" value={pnr} />
          <UserLabel label="Booking Price" value={fare.publishedFare} />
        </div>
      </div>
      <div className="row rowMargin">
        <div className="col-4">{passengers}</div>
        <div className="col-4">{segmentDetail}</div>
        <div className="col-4">{`${title} ${firstName} ${lastName}`}</div>
      </div>
      <div className="row rowMargin">
        <div className="col-4">
          <UserLabel label="Booked" value={formatDisplayDate(createdAt)} />
        </div>
        <div className="col-4 offset-4">
          <UserLabel
            label="Flight"
            value={formatDisplayDateTime(segments[0]?.departureTime)}
          />
        </div>
      </div>
      <div className="row rowMargin">
        <div className="col-12">
          <UserButton
            label="Release/Cancel Seats"
            onClick={handleRelease}
            className="fr mr-l-10"
          />
          <UserButton label="Open" onClick={handleOpen} className="fr" />
        </div>
      </div>
    </UserCard>
  );
}
