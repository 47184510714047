import { includes, maxBy, minBy, uniqBy } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { UserCheckbox } from "../../components/Checkbox";
import {
  convertDateToMomentTime,
  convertTimeToMoment,
} from "../../libs/functions";
import { FlightTime } from "../../utils/flightTime";
import { UserHeader } from "../../components/Header";
import { UserButton } from "../../components/Button";
import MuiGrid from "../../components/Grid/item";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import "./_flightFilter.scss";
import { UserSlider } from "../../components/Slider";
import { Paper } from "../../components/Paper/index.js";

const useStyles = makeStyles((theme) => ({
  style: {
    padding: "1.5rem",
  },
  heading: {
    padding: "1.5rem",
    justifyContent: "space-around",
    margin: "0 auto",
  },
}));

const flightTimeOptions = FlightTime.filter((c) => c.startTime).map((c) => ({
  ...c,
  // text: `${c.startTime}-${c.endTime}`,
  text: `${c.text}`,
}));

const stopOptions = [
  { text: "Direct", value: 1 },
  { text: "1", value: 2 },
  { text: "2 & more", value: 3 },
];

const fareTypeOptions = [
  { text: "Refundable", value: "true" },
  { text: "Non-Refundable", value: "false" },
];

export default function FlightFilter({ flights: initFlights, setFlights }) {
  const classes = useStyles();
  const [state, setState] = useState({});
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (initFlights) {
      const allFlights = initFlights.flat(1);
      let airlineOptions = [],
        layoverOptions = [],
        destinationOptions = [],
        originAirportOptions = [];
      allFlights.forEach((c) => {
        const noOfSegments = c.segments.length;
        c.segments.forEach((s, index) => {
          airlineOptions.push({
            text: `${s.airlineName} ${s.airlineCode}`,
            value: s.airlineCode,
          });

          if (index < noOfSegments - 1)
            layoverOptions.push({
              text: `${s.destinationCityName} (${s.destinationCityCode})`,
              value: s.destinationCityCode,
            });
          // eslint-disable-next-line eqeqeq
          if (index == noOfSegments - 1)
            destinationOptions.push({
              text: `${s.destinationCityName} (${s.destinationCityCode})`,
              value: s.destinationCityCode,
            });
          originAirportOptions.push({
            text: s.originAirportName,
            value: s.originAirportName,
          });
        });
      });
      airlineOptions = uniqBy(airlineOptions, (c) => c.value);
      layoverOptions = uniqBy(layoverOptions, (c) => c.value);
      destinationOptions = uniqBy(destinationOptions, (c) => c.value);
      originAirportOptions = uniqBy(originAirportOptions, (c) => c.value);
      const minPrice = minBy(allFlights, (c) => c.publishedPrice);
      const maxPrice = maxBy(allFlights, (c) => c.publishedPrice);
      setOptions({
        airlineOptions,
        layoverOptions,
        destinationOptions,
        originAirportOptions,
        minPrice: minPrice?.publishedPrice,
        maxPrice: maxPrice?.publishedPrice,
      });

      handleChange("price", maxPrice?.publishedPrice);
    }
  }, [initFlights]);
  const {
    airlineOptions,
    layoverOptions,
    destinationOptions,
    originAirportOptions,
    minPrice,
    maxPrice,
  } = options;

  const {
    flightTime,
    noOfStops,
    airlines,
    destinations,
    layover,
    fareType,
    originAirports,
    price,
  } = state;

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (initFlights) {
      let flights = initFlights;
      if (flightTime && flightTime.length > 0) {
        const selectedTime = flightTimeOptions
          .filter((c) => includes(flightTime, c.value))
          .map((c) => {
            const startTime = convertTimeToMoment(c.startTime);
            let endTime = convertTimeToMoment(c.endTime);
            endTime = endTime.isSameOrBefore(startTime)
              ? endTime.clone().add(1, "day")
              : endTime;

            return { startTime, endTime };
          });

        flights = flights.map((c) =>
          c.filter((f) => {
            const momentDate = convertDateToMomentTime(
              f.segments[0].departureTime
            );
            return selectedTime.some((s) =>
              momentDate.isBetween(s.startTime, s.endTime)
            );
          })
        );
      }
      if (noOfStops && noOfStops.length > 0) {
        flights = flights.map((c) =>
          c.filter((f) => includes(noOfStops, f.segments.length))
        );
      }
      if (airlines && airlines.length > 0) {
        flights = flights.map((c) =>
          c.filter((f) =>
            f.segments.some((s) => includes(airlines, s.airlineCode))
          )
        );
      }
      if (layover && layover.length > 0) {
        flights = flights.map((c) =>
          c.filter((f) =>
            f.segments.some((s) => includes(layover, s.destinationCityCode))
          )
        );
      }
      if (destinations && destinations.length > 0) {
        flights = flights.map((c) =>
          c.filter((f) =>
            f.segments.some((s) =>
              includes(destinations, s.destinationCityCode)
            )
          )
        );
      }
      if (originAirports && originAirports.length > 0) {
        flights = flights.map((c) =>
          c.filter((f) =>
            f.segments.some((s) =>
              includes(originAirports, s.originAirportName)
            )
          )
        );
      }
      if (fareType && fareType.length > 0) {
        flights = flights.map((c) =>
          c.filter((f) => includes(fareType, f.isRefundable.toString()))
        );
      }
      if (price) {
        flights = flights.map((c) =>
          c.filter(
            (f) => f.publishedPrice >= minPrice && f.publishedPrice <= price
          )
        );
      }

      setFlights(flights);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, initFlights, options]);

  useEffect(() => {
    let layoverOptions = [];
    if (initFlights) {
      initFlights.forEach((fl_res) => {
        fl_res.forEach((si_fl) => {
          if (
            si_fl &&
            (!airlines ||
              airlines.length === 0 ||
              si_fl.segments.some((s) => includes(airlines, s.airlineCode))) &&
            si_fl.segments
          ) {
            const noOfSegments = si_fl.segments.length;
            si_fl.segments.forEach((s, index) => {
              if (index < noOfSegments - 1)
                layoverOptions.push({
                  text: `${s.destinationCityName} (${s.destinationCityCode})`,
                  value: s.destinationCityCode,
                });
            });
          }
        });
      });
      layoverOptions = uniqBy(layoverOptions, (lay_opt) => lay_opt.value);

      setOptions((old) => ({
        ...old,
        layoverOptions: layoverOptions,
      }));
    }
  }, [state.airlines]);

  useEffect(() => {}, []);

  const handleClear = () => {
    setState({});
  };

  return (
    <>
      <Paper>
        <MuiGrid xs={12} className={classes.heading}>
          <UserHeader title="🜄 Filters" />
          <UserButton label=" Clear Filters" onClick={handleClear} />
        </MuiGrid>
        <Divider variant="middle" />
        <MuiGrid xs={12} className={classes.style}>
          <UserCheckbox
            label="Outbound Flight Times"
            options={flightTimeOptions}
            name="flightTime"
            values={flightTime}
            handleChange={handleChange}
          />
        </MuiGrid>
        <Divider variant="middle" />
        <MuiGrid xs={12} className={classes.style}>
          <UserSlider
            label="Price"
            name="price"
            value={price}
            handleChange={handleChange}
            minValue={minPrice}
            maxValue={maxPrice}
            marks={[
              { label: `₹${minPrice}`, value: minPrice },
              { label: `₹${price}`, value: price },
            ]}
          />
        </MuiGrid>
        <Divider variant="middle" />
        <MuiGrid xs={12} className={classes.style}>
          <UserCheckbox
            label="Stops"
            options={stopOptions}
            name="noOfStops"
            values={noOfStops}
            handleChange={handleChange}
          />
        </MuiGrid>
        <Divider variant="middle" />
        <MuiGrid xs={12} className={classes.style}>
          <UserCheckbox
            label="Airlines"
            options={airlineOptions}
            name="airlines"
            values={airlines}
            handleChange={handleChange}
          />
        </MuiGrid>
        {/* 1 means direct */}
        {(!noOfStops || !noOfStops?.includes(1)) && (
          <>
            <Divider variant="middle" />
            <MuiGrid xs={12} className={classes.style}>
              <UserCheckbox
                label="Layover"
                options={layoverOptions}
                name="layover"
                values={layover}
                handleChange={handleChange}
              />
            </MuiGrid>
          </>
        )}
        <Divider variant="middle" />
        <MuiGrid xs={12} className={classes.style}>
          <UserCheckbox
            label="Destination"
            options={destinationOptions}
            name="destinations"
            values={destinations}
            handleChange={handleChange}
          />
        </MuiGrid>
        <Divider variant="middle" />
        <MuiGrid xs={12} className={classes.style}>
          <UserCheckbox
            label="Fare Type"
            options={fareTypeOptions}
            name="fareType"
            values={fareType}
            handleChange={handleChange}
          />
        </MuiGrid>
        <Divider variant="middle" />
        <MuiGrid xs={12} className={classes.style}>
          <UserCheckbox
            label="Origin Airport"
            options={originAirportOptions}
            name="originAirports"
            values={originAirports}
            handleChange={handleChange}
          />
        </MuiGrid>
      </Paper>
    </>
  );
}
