import Cookies from "js-cookie";
import { APP_CONSTANTS } from "./constants";

export const getNameFromUser = (user) => {
  return user.firstName + " " + user.lastName;
  // ? user.customer
  //   ? user.customer.firstName + " " + user.customer.lastName
  //   : user.company
  //   ? user.company.companyName
  //   : user.staff
  //   ? user.staff.staffName
  //   : ""
  // : "";
};

export const getTextFromHtml = (html: string) => {
  const divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
};

export const extendLoginSession = () => {
  const nonRemeberedExpiry = (1 / 1440) * 30; // 30 minute session

  const prevCookie = Cookies.get(APP_CONSTANTS.LOGGED_IN_COOKIE);

  if (!prevCookie || (prevCookie && prevCookie !== "remembered")) {
    // If cookie is not remembered, then extend cookie by 30min
    Cookies.set(APP_CONSTANTS.LOGGED_IN_COOKIE, "non_remembered", {
      expires: nonRemeberedExpiry,
    });
  }
};

export const getAuthToken = () => {
  const token = Cookies.get("auth-token");
  return token;
};
export const redirection401 = () => {
  // if (window.location.pathname.includes("logout")) {
  //   return;
  // }
  const urlParams = new URLSearchParams(window.location?.search);
  const redirectTo = urlParams?.get("redirectTo")
    ? "?redirectTo=" + urlParams?.get("redirectTo")
    : "";

  const redirectType = urlParams?.get("redirectType")
    ? "redirectType=" + urlParams?.get("redirectType")
    : "";
  window.location.href = `/app/logout${redirectTo}${
    redirectType ? "&" + redirectType : ""
  }`;
};
