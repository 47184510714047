import { combineReducers } from "redux";
import siteDataReducer from "redux/reducers/siteDataReducer";
import { connectRouter } from "connected-react-router";

import userReducer from "./userReducer";
import commonReducer from "./commonReducer";
import UsersGroupReducer from "./usersGroupReducer";
import { flightReducers } from "flight-react/reducers";
import { SupplierReducer } from "redux/slices/supplier.slice";
import { DmcJobTransferReducer } from "redux/slices/dmc/JobTransfer.slice";
import FixedDepartureQueueReducer from "redux/slices/fixedDeparture/fixedDepartureQueue.slice";
import manageUsersPermisionsReducer from "redux/slices/manageUsersPermisionsSlice";
import permissionsReducer from "redux/slices/permissions.slice";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    common: commonReducer,
    siteData: siteDataReducer,
    userGroups: UsersGroupReducer,
    supplier: SupplierReducer,
    dmcJobTransfer: DmcJobTransferReducer,
    fixedDepartureQueue: FixedDepartureQueueReducer,
    managePermission: manageUsersPermisionsReducer,
    userPermissions: permissionsReducer,
    ...flightReducers,
  });

export default rootReducer;
