export const Ticket = {
  GET_TICKETS: "GET_TICKETS",
  GET_BOOKED_FLIGHTS: "GET_BOOKED_FLIGHTS",
  RELEASE_FLIGHT: "RELEASE_FLIGHT",
  CHANGE_REQUEST: "CHANGE_REQUEST",
  GET_TICKET_DETAILS: "GET_TICKET_DETAILS",
  UPDATE_BOOKING_DETAILS: "UPDATE_BOOKING_DETAILS",
  GET_CC: "GET_CC",
  GET_CR_STATUS: "GET_CR_STATUS",
  TICKET_SUCCESS: "TICKET_SUCCESS",
  UPDATE_CR_STATUS: "UPDATE_CR_STATUS", // Update Change request status OBject to Ticket
  TICKET_ERROR: "TICKET_ERROR",
};
