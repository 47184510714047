import { siteDataTypes } from "../actionTypes/siteDataActionTypes";

const initialState = {
  userGroups: [],
  defaultGroupId: null,
};

export default function UsersGroupReducer(state = initialState, action) {
  switch (action.type) {
    case siteDataTypes.SET_USER_GROUPS:
      return {
        ...state,
        userGroups: [...action.payload],
      };

    default:
      return state;
  }
}
