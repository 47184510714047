import moment from "moment";
import { useEffect, useState } from "react";
import Workbook from "react-excel-workbook";

const excelVars = {
  Origin: "originAirportCode",
  Destination: "destinationAirportCode",
  "First name": "firstName",
  "Last name": "lastName",
  "Passport No": "passportNo",
  "Booked by": "bookedByUserName",
  "Booked by user email": "bookedByUserEmail",
  "Booking Date": "bookingDate",
  "Travel Date": "travelDate",
  "Purchase cost": "purchase",
  "Sales cost": "sales",
  "Airline PNR": "airlinePnr",
  PNR: "pnr",
  Status: "status",
} as const;

type keysType = (typeof excelVars)[keyof typeof excelVars];

export const LiveFlightTicketQueueExcel: React.FC<{
  tickets: any[];
}> = (props) => {
  const [mappedData, setMappedData] = useState<any[]>([]);

  useEffect(() => {
    let excelData: {
      [key in keysType]: any;
    }[] = [];
    props.tickets.forEach((ticket) => {
      return ticket.segments.forEach((segement, segIndex) => {
        return ticket.passenger.forEach((passenger, passengerIndex) => {
          let purchase = 0;
          let sales = 0;
          let bookedByUserEmail = "";
          let bookedByUserName = "";
          if (segIndex === 0 && passengerIndex === 0) {
            purchase = Math.round(ticket.accounting?.middleManWl?.cost || 0);
            sales = ticket.accounting?.endCustomer?.cost || 0;
            bookedByUserName =
              ticket.user.firstName + " " + ticket.user.lastName;
            bookedByUserEmail = ticket.user.email;
          }
          const status =
            ticket.statusId === 3
              ? "Ticketed"
              : ticket.statusId === 4
              ? "Cancelled"
              : "Change request";
          excelData.push({
            originAirportCode: segement.originAirportCode,
            destinationAirportCode: segement.destinationAirportCode,
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            passportNo: passenger.passportNo,
            bookedByUserName,
            bookedByUserEmail,
            bookingDate: moment(ticket.createdAt).format("LLL"),
            travelDate: moment(segement.departureTime).format("LLL"),
            purchase,
            sales,
            pnr: ticket.pnr || "",
            airlinePnr: segement.airlinePnr,
            status,
          });
        });
      });
    });
    setMappedData(excelData);
  }, [props.tickets]);

  return (
    <Workbook
      filename="flight-ticket-queue.xlsx"
      element={
        <button className="btn btn-lg btn-success btn-sm ms-2">
          <i className="fas fa-file-download me-2"></i>
          Download Excel
        </button>
      }
    >
      <Workbook.Sheet data={mappedData} name="Live Flight Ticket Queue">
        {Object.entries(excelVars).map(([key, value], index) => {
          return <Workbook.Column key={key} label={key} value={value} />;
        })}
      </Workbook.Sheet>
    </Workbook>
  );
};
