import { ListSkeleton } from "components/afterLogin/settings-menu-components/settingsSubComponents/UI/ListSkeleton";
import { getRequest, postRequest } from "components/common/ajax";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IUserPermissionGroup } from "types/RBAC/managePermission.types";

export const SelectRBACUserGroupModal: React.FC<{
  show: boolean;
  onClose: () => void;
  userId: number;
  // selectedUser: any;
}> = (props) => {
  const [loadingState, setLoadingState] = useState(false);
  const [userPermissionGroups, setUserPermissonGroups] = useState<
    IUserPermissionGroup[]
  >([]);

  const [selectGroupLoading, setSelectGroupLoading] = useState<boolean[]>([
    false,
  ]);

  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState<
    number | null
  >(null);

  const getUserGroupsList = async () => {
    setLoadingState(true);
    const userGroupResponse = await getRequest("rbac/getRBACUserGroupList", {});
    if (userGroupResponse?.success) {
      setUserPermissonGroups(userGroupResponse.result);
    }
    setLoadingState(false);
  };

  const getSelectedUserGroups = async () => {
    setLoadingState(true);
    const userGroupResponse = await postRequest("rbac/getUserGroupByUserId", {
      userId: props.userId,
    });
    if (userGroupResponse?.success) {
      setSelectedPermissionGroup(userGroupResponse.result);
    }
    setLoadingState(false);
  };

  useEffect(() => {
    getUserGroupsList();
    getSelectedUserGroups();
  }, []);

  const handleClick = async (groupId: number | null, index?: number) => {
    if (typeof index === "number") {
      setSelectGroupLoading((prev) => {
        const newState = [...prev];
        newState[index] = true;
        return newState;
      });
    }
    const response = await postRequest("rbac/addUserToRBACUserGroup", {
      userId: props.userId,
      userGroupId: groupId,
    });

    if (response?.success) {
      setSelectedPermissionGroup(groupId);
      // props.onClose();
      if (typeof index === "number") {
        setSelectGroupLoading((prev) => {
          const newState = [...prev];
          newState[index] = false;
          return newState;
        });
      }
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Permission Group </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-end">
            <button
              className="btn p-0 text-danger btn-sm"
              onClick={() => {
                handleClick(null);
              }}
            >
              <i className="fas fa-times"></i> Remove
            </button>
          </div>
          {loadingState ? (
            <ListSkeleton count={1} />
          ) : (
            <div id="user-permission-group-list-container">
              {userPermissionGroups.map((group, index) => (
                <div className="position-relative">
                  {selectGroupLoading && selectGroupLoading[index] ? (
                    <div className="text-center position-absolute top-50 start-50 translate-middle">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={
                      ` m-2 p-2 border rounded ` +
                      (selectedPermissionGroup &&
                      selectedPermissionGroup === group.id
                        ? " shadow border-success border-2"
                        : "")
                    }
                    style={{
                      opacity:
                        selectGroupLoading && selectGroupLoading[index]
                          ? 0.5
                          : 1,
                    }}
                    role="button"
                    onClick={() => {
                      handleClick(group.id, index);
                    }}
                  >
                    <h6 className="text-capitalize">
                      <span>{index + 1}</span> {group.groupName}
                    </h6>
                    {group.modules.map((module, index) => (
                      <span className="badge bg-primary me-2">
                        {module.moduleName}
                      </span>
                    ))}
                  </div>
                </div>

                //   <UserPermisssionGroupCard
                //     key={index}
                //     index={index}
                //     group={group}
                //     openEditGroupModal={(group) => {
                //       dispatch(setSelectedUserPermissonGroups(group));
                //       setShowCreateOrEditGroupModalState(true);
                //     }}
                //     openManagePermissionModal={(group) => {
                //       dispatch(setSelectedUserPermissonGroups(group));
                //       setShowManagePermissionModalState(true);
                //     }}
                //   />
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
