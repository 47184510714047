import { useState, useEffect, useRef } from "react";
import "./assets/css/common/common.css";
import "semantic-ui-css/semantic.min.css";
import "flight-react/App.scss";
import "./assets/css/newDesign/style.scss";
import Routes from "./components/common/Routes";
import axios from "axios";
import ComingSoon from "components/staticPages/comingSoon";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { SET_SITE_DATA } from "redux/actions/siteDataActions";
import CommonComponents from "components/common/index";
import { useTheme } from "./components/common/theme/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { usePreviousHistory } from "hooks/usePreviousHistory.ts";
import "react-toastify/dist/ReactToastify.css";
import TagManager from "react-gtm-module";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { clarity } from "react-microsoft-clarity";
import { SET_ALERT_MESSAGE } from "redux/actions/commonActions";
import "rc-menu/assets/index.css";
import "./fd-react/assets/css/fixedDeparture.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useAppSelector } from "flight-react/hooks/reduxHooks";
import { InvalidPhoneNumberModal } from "components/login/InvalidPhoneNumberModal";
import { useHydrateUserData } from "hooks/useHydrateUserData";
import { redirection401 } from "tools/functions";
import { useInvalidPhoneNumber } from "hooks/useInvalidPhoneNumber";
import { useEjsHeaderFooter } from "hooks/useEjsHeaderFooter";
import { usePushNotificationSubscription } from "hooks/usePushNotificationSuscbscription";

const getFaviconEl = () => {
  return document.getElementById("faviconImage");
};

// import TagManager from 'react-gtm-module'
function App(props) {
  // const google = useGoogle();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [siteFound, setSiteFound] = useState(true);
  const [popupAlertState, setPopupAlertState] = useState();
  const [pageTitle, setPageTitle] = useState("");
  const theme = useTheme();
  const siteData = useAppSelector((state) => state.siteData);
  const user = useAppSelector((state) => state.user);
  usePreviousHistory();
  useHydrateUserData();
  useEjsHeaderFooter(loading);
  const gTagRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const { showPhoneInvalidModal, updatingPhoneNumber, updatePhoneNumberFx } =
    useInvalidPhoneNumber();

  usePushNotificationSubscription();

  /**
   *  -- 30 March 2023 --
   * below given code was redirecting user to api routes
   * now we have removed this login and the routing will be controlled via nginx.
   */
  // useEffect(() => {
  //   if (FnBconstants.ssrHomePageRoutes.includes(window.location.pathname)) {
  //     dispatch(SET_LOADING(true));

  //     if (window.location.hostname === "localhost") {
  //       window.location.href =
  //         "http://localhost:5000" +
  //         window.location.pathname +
  //         window.location.search;
  //     } else {
  //       window.location.reload();
  //     }
  //   }
  // }, [location, history]);

  // Delete is invalid phone check works properly 5/5/2023
  // const validPhoneNumberCheck = () => {
  //   const isInvalid = !user.phoneCode || user.phoneCode === "INVALID";
  //   if (user.id && isInvalid) {
  //     setShowPhoneInvalidModal(true);
  //   } else {
  //     setShowPhoneInvalidModal(false);
  //   }
  // };

  useEffect(() => {
    let domain = window.location.hostname;

    clarity.init("muuo18azr3");

    // console.log(domain);
    //console.log(window.location,'domain')
    //console.log("ACtual theme",theme)

    // if jwt token exists in local storage redirects to /logout
    // const accessToken = localStorage.getItem(AppConstant.USER_KEY);
    // if (accessToken?.length > 50) {
    //   window.location.href = "/logout";
    // }

    if (domain === "localhost" || domain === "192.168.0.120") {
      domain = "1.landarrangement.com"; // maya travels
      // domain = "2.landarrangement.com"; // maya travels
      // domain = "3.landarrangement.com"; // fdking
      // domain = "4.landarrangement.com"; // multani travels
    }

    if (domain.includes("ngrok-free.app")) {
      domain = "2.landarrangement.com"; // packagecart
    }

    document.addEventListener("wheel", function (event) {
      if (document?.activeElement?.type === "number") {
        document.activeElement.blur();
      }
    });

    /**
     * To remove alert message from redux local storage,
     *  so that it will not show on every page load
     */
    dispatch(SET_ALERT_MESSAGE(null));

    const setCssVariables = (primary, secondary) => {
      const root = document.documentElement;

      if (primary) {
        root?.style.setProperty("--bs-primary", primary);
        root?.style.setProperty("--primary", primary);
      }

      if (secondary) {
        root?.style.setProperty("--bs-secondary", secondary);
        root?.style.setProperty("--secondary", secondary);
      }
    };

    axios({
      url: "/api/whitelabel/detetct",
      method: "POST",
      data: { domain },
    })
      .then((r) => {
        if (r.data.success) {
          // console.log(r.data[0])

          const siteData = r.data.result;

          setCssVariables(
            siteData?.brandingSettings?.form?.theme?.palette?.primary?.main,
            siteData?.brandingSettings?.form?.theme?.palette?.secondary?.main
          );

          if (siteData.gtm_tag) {
            gTagRef.current = {
              gtmId: siteData.gtm_tag,
            };
          }

          const favicon = getFaviconEl(); // Accessing favicon element
          favicon.href = siteData.faviconImage;

          let shouldPopupAlert = Cookies.get("popup_alert_frequency");
          if (
            !shouldPopupAlert &&
            siteData?.brandingSettings?.form?.popupFrequency &&
            siteData?.brandingSettings?.form?.popupFrequency !== 0
          ) {
            // IF cookie exists donot show popup
            let popupAlertHtml = siteData?.brandingSettings?.form?.popupAlert;
            setPopupAlertState(popupAlertHtml);
            Cookies.set("popup_alert_frequency", true, {
              expires: siteData.brandingSettings.form.popupFrequency,
            });
          }

          dispatch(SET_SITE_DATA(siteData));
          localStorage.setItem("siteData", JSON.stringify(siteData));
          setLoading(false);
        } else {
          //console.log('more than one wtlb data found',domain)
          setSiteFound(false);
        }
        PageTitle();
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          redirection401();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gTagRef.current) {
      TagManager.initialize(gTagRef.current);
    }
    // if (Cookies.get(APP_CONSTANTS.LOGGED_IN_COOKIE)) {
    //   // Extending Session time by 15 minutes
    //   extendLoginSession();
    // } else {
    //   dispatch(LOGOUT());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gTagRef.current?.gtmId, location.pathname]);

  useEffect(() => {
    if (siteData?.domain && user?.site_id) {
      const clarityStarted = clarity.hasStarted();
      if (clarityStarted) {
        clarity.identify(user?.id.toString(), {
          siteId: user?.site_id,
          domain: siteData?.domain,
        });

        // clarity.setTag("userId", user?.id.toString());
        // clarity.setTag("siteId", user?.site_id);
        // clarity.setTag("domain", siteData?.domain);
      }
    }
  }, [user, siteData?.domain]);

  const PageTitle = () => {
    let siteData = localStorage.getItem("siteData");
    if (siteData) {
      siteData = JSON.parse(siteData);
      setPageTitle(siteData.siteName);
    } else {
      setPageTitle("Coming Soon!");
    }
  };

  return (
    <>
      <CommonComponents />
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      {loading === false && (
        <ThemeProvider theme={theme}>
          {/* old semantic popup not needed anymore */}
          {/* <BackdropDialog
            open={popupAlertState}
            onClose={() => setPopupAlertState(undefined)}
          >
            <div className="container-fluid">
              {ReactHtmlParser(popupAlertState)}
            </div>
          </BackdropDialog> */}
          <InvalidPhoneNumberModal
            show={showPhoneInvalidModal}
            submitFx={(phoneDetails) => {
              updatePhoneNumberFx(phoneDetails);
            }}
            updatingPhoneNumber={updatingPhoneNumber}
          />
          <GoogleOAuthProvider clientId={siteData?.wlMeta?.googleAuthClientId}>
            <Routes />
          </GoogleOAuthProvider>
        </ThemeProvider>
      )}
      {siteFound === false && <ComingSoon />}
    </>
  );
}

export default App;
