import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PublicRoute from "../PublicRoutes";
import { getAuthToken } from "tools/functions";

/**
 * This type of route can also be reffered to as "Guest Route"
 */

// Guest Route
export const OnlyWithoutLoginRoute = (props) => {
  const history = useHistory();
  useEffect(() => {
    //redirect to home page if the user is already logged in
    const authToken = getAuthToken();
    if (authToken) history.push("/");
  }, []);
  return <PublicRoute {...props} />;
};

export default OnlyWithoutLoginRoute;
