import {siteDataTypes} from '../actionTypes/siteDataActionTypes'

export const SET_SITE_DATA = (siteData) => {
    return {
        type: siteDataTypes.SET_SITE_DATA,
        payload: siteData
    }
}

export const SET_SITE_USER_GROUPS = (userGroups) => {
    return {
        type: siteDataTypes.SET_USER_GROUPS,
        payload: userGroups
    }
}