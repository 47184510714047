import { BookFlight } from "../actionTypes/bookFlight";

export const getPassengerViewOptions = (payload) => ({
  type: BookFlight.GET_PASSENGER_VIEW_OPTIONS,
  payload,
});

export const getCountryList = (payload) => ({
  type: BookFlight.GET_COUNTRY_LIST,
  payload,
});

export const getPhoneCodeList = (payload) => ({
  type: BookFlight.GET_PHONECODE_LIST,
  payload,
});

export const getNationalities = (payload) => ({
  type: BookFlight.GET_NATIONALITIES,
  payload,
});

export const getPassengerView = (payload) => ({
  type: BookFlight.GET_PASSENGERS_VIEW,
  payload,
});

export const getFareRules = (payload) => ({
  type: BookFlight.GET_FARE_RULES,
  payload,
});

export const getSSRDetails = (payload) => ({
  type: BookFlight.GET_SSR_DETAILS,
  payload,
});

export const fareQuote = (payload, cb) => ({
  type: BookFlight.FARE_QUOTE,
  payload,
  cb,
});

// export const bookFlight = (payload, cb) => ({
//   type: BookFlight.BOOK_FLIGHT,
//   payload,
//   cb,
// });

// export const getTicket = (payload) => ({
//   type: BookFlight.GET_TICKET,
//   payload,
// });

export const flightOrderSuccessCallback = (payload) => ({
  type: BookFlight.FLIGHT_ORDER_SUCCESS_CALLBACK,
  payload,
});

export const getBookingDetails = (payload) => ({
  type: BookFlight.GET_BOOKING_DETAILS,
  payload,
});

export const setSelectedFlight = (payload) => ({
  type: BookFlight.SET_SELECTED_FLIGHT,
  payload,
});

export const setFlightDetails = (payload) => ({
  type: BookFlight.SET_FLIGHT_DETAIL,
  payload,
});

export const initFlight = (result) => ({
  type: BookFlight.INIT_BOOK_FLIGHT,
  result,
});

export const setFlight = (result) => ({
  type: BookFlight.BOOK_FLIGHT_SUCCESS,
  result,
});

export const validateBooking = (payload, cb) => ({
  type: BookFlight.VALIDATE_BOOKING,
  payload,
  cb,
});

export const getFlightDetails = (payload) => ({
  type: BookFlight.GET_FLIGHT_DETAILS,
  payload,
});

export const setIsBookingInProcess = (payload) => ({
  type: BookFlight.SET_IS_BOOKING_IN_PROCESS,
  payload,
});

export const setPassengerDetails = (payload) => ({
  type: BookFlight.SET_FARE_DETAILS,
  payload,
});
