import {
  changeConsumerSupplierRequestStatus,
  getAllConsumerSupplierRequestsBySupplierId,
} from "api/supplier";
import { DropdownPagination } from "components/common/Pagination/DropdownPagination";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { consumerSupplierRequestStatusEnum } from "types/common.types";

const ApiCustomerModal = (props: {
  show: boolean;
  /* will contain the function to close the modal */
  handleClose: any;
}) => {
  // let paginationItems: JSX.Element[] = [];
  // const [requests, setRequests] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  // const [paginationItems, setPagination] = React.useState(false);
  // const [currentPage, setPage] = useState(1);
  const [paginationResult, setPaginationResult] = useState<{
    currentPage: number;
    totalPages: number;
    totalRecords: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    records: Array<any>;
  }>();
  useEffect(() => {
    getAllCustomerRequests();
  }, []);

  const getAllCustomerRequests = async (page: number = 1) => {
    setIsLoading(true);
    const response = await getAllConsumerSupplierRequestsBySupplierId(page);
    if (!response?.data?.success)
      return toast.error(response?.data?.errorMessage);
    setPaginationResult(response?.data?.result);

    // paginationItems = [];
    // for (let number = 1; number <= 5; number++) {
    //   paginationItems.push(
    //     <Pagination.Item
    //       key={number}
    //       active={number === response?.data?.result?.currentPage}
    //     >
    //       {number}
    //     </Pagination.Item>
    //   );
    // }

    setIsLoading(false);
  };

  const changeRequestStatus = async (request, newStatus) => {
    const response = await changeConsumerSupplierRequestStatus({
      newRequestStatus: newStatus,
      requestId: request.id,
      consumerMongoSiteId: request.whitelabel_mongoDb_siteId,
    });
    if (!response.data.success) {
      return toast.error(response.data.errorMessage);
    } else {
      toast.success("Request status changed successfully");
      getAllCustomerRequests();
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} centered keyboard>
      <Modal.Header>
        <Modal.Title>API Customer Requests</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {isLoading ? (
            <p className="text-center"> Loading....</p>
          ) : (
            <>
              <div style={{ fontSize: 12 }}>
                {paginationResult && paginationResult?.totalRecords > 0 ? (
                  <>
                    <ul className="list-group">
                      {paginationResult?.records.map((req, index) => (
                        <li className="list-group-item" key={index}>
                          <div className="row">
                            <div className="col-sm-4 text-center">
                              <span
                                className="badge bg-primary"
                                style={{ fontSize: 13 }}
                              >
                                {req.supplier_category}
                              </span>
                              <a
                                href={`http://${req.domain}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={req?.logoUrl}
                                  alt={req.domain}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "http://placehold.jp/24/250x100.png?text=No+Logo";
                                  }}
                                  className="img-fluid"
                                />
                                {req.companyName}
                                <br />({req.siteName})
                              </a>
                            </div>
                            <div className="col-sm-4 text-center">
                              <b>Request Status</b>
                              <br />
                              <span className="badge bg-warning text-dark">
                                {req.requestStatus}
                              </span>
                              <div className="p-2"></div>
                              <span
                                style={{
                                  fontSize: 9,
                                  lineHeight: "1.2",
                                  display: "block",
                                }}
                              >
                                Generated @ <br />
                                {moment(req.requestGeneratedAt).format("llll")}
                                <br /> {req.adminContact}
                                <br /> {req.adminEmail}
                              </span>
                            </div>
                            <div className="col-sm-4 text-center d-flex justify-content-evenly flex-column text-break">
                              {req.requestStatus ===
                                consumerSupplierRequestStatusEnum.Approved ||
                              req.requestStatus ===
                                consumerSupplierRequestStatusEnum.Rejected ? (
                                <>
                                  {req.requestStatus} by{" "}
                                  {`${req.users_firstName} ${req.users_lastName}`}{" "}
                                  ({req.users_email})
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-outline-success "
                                    onClick={() =>
                                      changeRequestStatus(
                                        req,
                                        consumerSupplierRequestStatusEnum.Approved
                                      )
                                    }
                                  >
                                    <i className="fas fa-user-check"></i>&nbsp;
                                    Accept Request
                                  </button>
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() =>
                                      changeRequestStatus(
                                        req,
                                        consumerSupplierRequestStatusEnum.Rejected
                                      )
                                    }
                                  >
                                    <i className="fas fa-times-circle"></i>
                                    &nbsp; Decline Request
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <DropdownPagination
                      currentPage={paginationResult.currentPage}
                      onPageChange={getAllCustomerRequests}
                      hasNextPage={paginationResult.hasNextPage}
                      hasPreviousPage={paginationResult.hasPreviousPage}
                      totalPages={paginationResult.totalPages}
                    />
                    {/* <Pagination>
                      {paginationResult.totalPages > 1 &&
                        _.times(paginationResult.totalPages).map((pNumber) => {
                          return (
                            <Pagination.Item
                              onClick={() => {
                                getAllCustomerRequests(pNumber + 1);
                              }}
                              key={pNumber}
                              active={
                                pNumber + 1 === paginationResult.currentPage
                              }
                            >
                              {pNumber + 1}
                            </Pagination.Item>
                          );
                        })}
                    </Pagination> */}
                  </>
                ) : (
                  <p className="text-center">
                    <i className="fas fa-exclamation-triangle"></i>
                    <br />
                    No Requests found!
                  </p>
                )}
              </div>
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApiCustomerModal;
