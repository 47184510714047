export const RouteConstant = {
  LOGIN_SCREEN: "/flight/login",
  SEARCH_FLIGHT: "/flight/search",
  SEARCH_FLIGHT_URL: "/flight/searchFlight",
  SEARCH_RESULT: "/flight/searchResult",
  CALENDAR_FARE_RESULT: "/flight/calendarFareResult",
  ADD_PASSENGER_DETAILS: "/flight/passengerDetails",
  BOOKING_REVIEW: "/flight/bookingReview",
  BOOKING_DETAILS: "/flight/bookingDetails",
  TICKET_QUEUE: "/flight/ticketQueue",
  FIXED_DEPARTURE_QUEUE: "/flight/fixedDepartureQueue",
  DEPRECATED_FIXED_DEPARTURE_QUEUE: "/flight/oldFixedDepartureQueue",
  DEPRECATED_PRINT_FIXED_DEPARTURE: "/flight/oldFixedDeparture/Print",
  PRINT_FIXED_DEPARTURE: "/flight/fixedDeparture/print",
  CHANGE_REQUESTS: "/flight/changeRequests",
  E_TICKET: "/flight/eTicket",
  BOOKED_QUEUE: "/flight/bookedQueue",
  PAYMENT_SCREEN: "/flight/payments",
  TRANSACTION_SCREEN: "/flight/transaction",
  FLIGHT_SUPPLIER_REPORT: "/flight/supplierReport",
};
