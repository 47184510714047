export const JourneyTypes = [
  { text: "OneWay", value: 1 },
  { text: "Return", value: 2 },
  { text: "Multi Stop", value: 3 },
  // { text: "Advance Search", value: 4 },
  // { text: "Calendar Fare", value: 5 },
];

export const ReturnTypeOptions = [
  { text: "Normal Returns", value: "2" },
  { text: "LCC Special Returns", value: "5" },
  { text: "GDS Special Returns", value: "3" },
];

export const ReturnTypeValues = {
  NORMAL: ReturnTypeOptions[0].value,
  LCC: ReturnTypeOptions[1].value,
  GDS: ReturnTypeOptions[2].value,
};
