import React from "react";

// import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
// import "@szhsin/react-menu/dist/index.css";

import Menu, { SubMenu, MenuItem } from "rc-menu";
import "./TileMenuBoxes.scss";
// import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ITileMenu } from "./StaffMenu";

// const useStyles = makeStyles((theme) => ({
//   link: {
//     color: `${theme.palette.primary.main} !important`,
//     padding: "0 !important",
//     margin: "0 !important",
//     "&:hover": {
//       color: `${theme.palette.secondary.main} !important`,
//       textDecoration: "none",
//     },
//   },
//   nested: {
//     paddingLeft: theme.spacing(2),
//   },
//   btn: {
//     border: "none",
//   },
//   navBtns: {
//     fontSize: 10,
//     border: `1px solid ${theme.palette.primary.main}`,
//     lineHeight: 1.2,
//     textTransform: "capitalize",
//     background: theme.palette.primary.main,
//     margin: "0 1px",
//     padding: "8px",
//     color: "#fff",
//     borderRadius: 7,
//     display: "flex",
//     flexDirection: "column",
//     height: 64,
//     minWidth: 64,
//     maxWidth: "min-content",
//     textAlign: "center",
//   },
// }));

export const TileMenuBoxes: React.FC<{
  menus: Array<ITileMenu>;
}> = (props) => {
  // const classes = useStyles();

  const history = useHistory();

  const printMenuBtn = (menu: ITileMenu) => {
    return (
      <div
        className={`topMenuBtn`}
        onClick={() => {
          if (menu.submenus?.length === 0) {
            if (menu.isRedirecting) {
              return (window.location.href = menu.link);
            }
            history.push(menu.link);
          }
        }}
      >
        <i className={` ${menu.icon} topMenuIcon`}></i>
        <div className="text-center">{menu.title}</div>
      </div>
    );
  };

  return (
    <>
      <div className="tiles-container text-center d-flex justify-content-center mb-2 bg-primary mx-auto col-12">
        <Menu mode="horizontal" className="menu" triggerSubMenuAction="hover">
          {/* {console.log(props.menus)} */}
          {props.menus.map((menu, indx) => (
            <SubMenu
              className="submenuWithIcon"
              title={printMenuBtn(menu)}
              key={`${indx.toString()}-${Math.floor(Math.random() * 100)}`}
            >
              {menu.submenus?.map((subm, subIndx) => {
                return subm.submenus ? (
                  <SubMenu
                    title={subm.title}
                    key={`${indx.toString()}-${subIndx.toString()}`}
                  >
                    {subm.submenus &&
                      subm.submenus.map((subm2, subIndx2) => {
                        return subm2.submenus ? (
                          <SubMenu
                            title={subm2.title}
                            key={`${subIndx.toString()}-${subIndx2.toString()}`}
                          >
                            {subm2.submenus &&
                              subm2.submenus.map((subm3, subIndx3) => {
                                return (
                                  <MenuItem
                                    key={`${indx.toString()}-${subIndx.toString()}-${subIndx2.toString()}-${subIndx3.toString()}`}
                                    onClick={() => {
                                      if (subm3.isRedirecting) {
                                        return (window.location.href =
                                          subm3.link);
                                      }
                                      if (
                                        !history.location.pathname.includes(
                                          subm3.link
                                        )
                                      ) {
                                        history.push(subm3.link);
                                      }
                                    }}
                                  >
                                    {subm3.title}
                                  </MenuItem>
                                );
                              })}
                          </SubMenu>
                        ) : (
                          <MenuItem
                            key={`${indx.toString()}-${subIndx.toString()}-${subIndx2.toString()}`}
                            onClick={() => {
                              if (subm2.isRedirecting) {
                                return (window.location.href = subm2.link);
                              }
                              if (
                                !history.location.pathname.includes(subm2.link)
                              ) {
                                history.push(subm2.link);
                              }
                            }}
                          >
                            {subm2.title}
                          </MenuItem>
                        );
                      })}
                  </SubMenu>
                ) : (
                  <MenuItem
                    onClick={() => {
                      if (subm.isRedirecting) {
                        return (window.location.href = subm.link);
                      }
                      if (!history.location.pathname.includes(subm.link)) {
                        history.push(subm.link);
                      }
                    }}
                    key={subIndx.toString()}
                  >
                    {subm.title}
                  </MenuItem>
                );
              })}
            </SubMenu>
          ))}
        </Menu>
      </div>
    </>
  );
};
