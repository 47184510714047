export const SearchFlight = {
  SEARCH_FLIGHT: "SEARCH_FLIGHT",
  GET_AIRPORTS: "GET_AIRPORTS",
  GET_SOURCES: "GET_SOURCES",
  GET_CALENDAR_FARE: "GET_CALENDAR_FARE",
  GET_FLIGHT_PRICE: "GET_FLIGHT_PRICE",
  SEARCH_FLIGHT_SUCCESS: "SEARCH_FLIGHT_SUCCESS",

  SEARCH_FLIGHT_SET_SHOW_LOADER: "SEARCH_FLIGHT_SET_SHOW_LOADER",
};
