import { useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { MarkupAndDiscountApi } from "api/flightApis/MarkupAndDiscount.api";
import { SET_SITE_USER_GROUPS } from "redux/actions/siteDataActions";
import { DiscountMarkupTextField } from "./flightApis/AirlineDiscountMarkup/components/DiscountMarkupTextField";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ILEGroup } from "./flightApis/AirlineDiscountMarkup/types";
import { SET_LOADING } from "redux/actions/commonActions";
import { SAGA_UPDATE_USER_LE_DATA } from "redux/actions/userActions";
import { useAppSelector } from "flight-react/hooks/reduxHooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      // maxWidth: 360,
      margin: "0 auto 30px",
      padding: 20,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const initialUserGroupInput = {
  value: "",
  error: [],
  validation: [],
};

export const UserGroupsComponent = () => {
  const classes = useStyles();

  const userGroupsState = useSelector((state: any) => state.userGroups);
  const currentUserLE = useSelector((state: any) => state.user.legalEntity);
  const user = useAppSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const [editingGroup, setEditingGroup] = useState<{
    id: number;
    groupName: string;
  }>();

  const [userGroupInput, setUserGroupInput] = useState<{
    value: string;
    error: Array<string>;
    validation: Array<string>;
  }>(initialUserGroupInput);

  // const [defaultUserGroup, setDefaultUserGroup] = useState<number>();

  const getSiteUserGroups = async () => {
    const response = await MarkupAndDiscountApi.getWlUserGroupsApiCall();
    dispatch(SET_SITE_USER_GROUPS(response.result));
    dispatch(SET_LOADING(false));
  };

  const addSiteUserGroup = async () => {
    const response = await MarkupAndDiscountApi.saveWlUserGroupsApiCall(
      userGroupInput.value
    );
    if (response.success) {
      setUserGroupInput(initialUserGroupInput);
      toast.success("Group successfully saved");
      getSiteUserGroups();
    } else {
      if (response.errorMessage) {
        toast.error(response.errorMessage);
      } else {
        toast.error("Some error occurred.");
      }
    }
  };

  const setDefaultUserGroup = async (groupId: number) => {
    dispatch(SET_LOADING(true));
    const response = await MarkupAndDiscountApi.setDefaultWlUserGroupApiCall(
      groupId
    );
    if (response?.success) {
      toast.success("Group successfully set as default");
      getSiteUserGroups();
      dispatch(SAGA_UPDATE_USER_LE_DATA({ userUUID: user.uuid }));
    } else {
      if (response?.errorMessage) {
        toast.error(response.data.errorMessage);
      } else {
        toast.error("Some error occurred.");
      }
    }
    dispatch(SET_LOADING(false));
  };

  const saveEdited = async () => {
    if (editingGroup) {
      dispatch(SET_LOADING(true));
      const response = await MarkupAndDiscountApi.saveWlUserGroupsApiCall(
        editingGroup.groupName,
        editingGroup.id
      );
      if (response.success) {
        toast.success("Group successfully changed");
        setEditingGroup(undefined);
      } else {
        if (response.errorMessage) {
          toast.error(response.errorMessage);
        } else {
          toast.error("Some error occurred.");
        }
      }
      getSiteUserGroups();
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h5" className="text-center">
          Customer Groups
        </Typography>
      </div>

      <div className="my-4">
        <DiscountMarkupTextField
          name="userGroup"
          label="New Group Name"
          value={userGroupInput}
          onChange={(e) =>
            setUserGroupInput({
              error: [],
              value: e.target.value,
              validation: [],
            })
          }
          textFieldProps={{
            className: "w-100",
            size: "small",
          }}
        />
        <Button
          variant="contained"
          fullWidth
          color="primary"
          className="mt-2"
          onClick={addSiteUserGroup}
        >
          Save
        </Button>
      </div>

      <List component="nav">
        {userGroupsState?.userGroups.map((cGroup: ILEGroup) => {
          return (
            <ListItem>
              {editingGroup && editingGroup.id === cGroup.id ? (
                <>
                  <TextField
                    value={editingGroup.groupName}
                    onChange={(e) => {
                      setEditingGroup({
                        id: editingGroup.id,
                        groupName: e.target.value,
                      });
                    }}
                  />
                  <Button
                    onClick={saveEdited}
                    color="secondary"
                    variant="outlined"
                    size="small"
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <ListItemText primary={cGroup.groupName} />
                  {cGroup.id !== currentUserLE.default_LE_GroupId ? (
                    <Button
                      onClick={() => setDefaultUserGroup(cGroup.id)}
                      color="primary"
                      variant="outlined"
                      size="small"
                    >
                      Make Default
                    </Button>
                  ) : (
                    <Button color="primary" size="small">
                      Default
                    </Button>
                  )}
                  <Button
                    className="ml-2"
                    onClick={() =>
                      setEditingGroup({
                        id: cGroup.id,
                        groupName: cGroup.groupName,
                      })
                    }
                    color="secondary"
                    variant="outlined"
                    size="small"
                  >
                    Edit
                  </Button>
                </>
              )}
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
