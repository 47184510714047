import { takeLatest, call, put } from "redux-saga/effects";
import BookingAPI from "../api/BookingAPI";
import callAPI from "./helper";
import { BookFlight } from "../actionTypes/bookFlight";
import { SearchFlight } from "../actionTypes/searchFlight";
import { push } from "connected-react-router";
import { RouteConstant } from "../utils/routeConstant";
import auth from "../libs/auth";
import { Common } from "../actionTypes/common";
import { OrderActions } from "flight-react/slices/order.slice";

function* getPassengerViewOptions(action) {
  const passengerViewRes = yield call(
    callAPI,
    BookingAPI.getPassengerViewOptions,
    action
  );

  const result = passengerViewRes?.result;

  if (result) {
    yield call(setResult, {
      countryList: result.countryList,
      phoneCodeList: result.phoneCodeList,
      nationalities: result.countryList,
      fareRules: result.fareRules,
    });

    if (result.fareQuote) {
      yield call(
        setFareQuoteResult,
        result.fareQuote,
        passengerViewRes.payload
      );
    }
  }
}

function* getCountryList(action) {
  yield call(callAPI, BookingAPI.getCountryList, action, setResult);
}

function* getPhoneCodeList(action) {
  yield call(callAPI, BookingAPI.getPhoneCodeList, action, setResult);
}

function* getNationalities(action) {
  yield call(callAPI, BookingAPI.getNationalities, action, setResult);
}

function* getFareRules(action) {
  yield call(callAPI, BookingAPI.getFareRules, action, setResult);
}

function* fareQuote(action) {
  yield call(
    callAPI,
    BookingAPI.fareQuote,
    action,
    setFareQuoteResult,
    undefined,
    failedFareQuote
  );
}

function* failedFareQuote(result) {
  // console.log("fareQuoteFailed", );
  yield window.history.back();
}

function* setFareQuoteResult(result, payload) {
  if (result?.isPriceChanged) {
    yield put({
      type: Common.SHOW_PRICE_CHANGED_ALERT,
      payload: { newPrice: result.fareDetailResult.totalPayable },
    });
  }

  yield put({ type: BookFlight.SET_FARE_DETAILS, result });
  // yield call(setResult, result);
  yield put({
    type: BookFlight.GET_SSR_DETAILS,
    payload: { ...payload, ResultIndex: result.fareDetailResult.resultIndex },
  });
}

function* validateBooking(action) {
  yield call(callAPI, BookingAPI.validateBooking, action, action.cb);
}

function* getSSRDetails(action) {
  yield call(callAPI, BookingAPI.getSSRDetails, action, setResult);
}

function* bookFlight(action) {
  yield call(callAPI, BookingAPI.bookFlight, action, bookFlightResult);
}

function* bookFlightResult(result, payload) {
  yield call(setResult, result);
  const { transactionDetails } = payload;
  yield put(
    push({
      pathname: `${RouteConstant.BOOKING_DETAILS}/${auth.searchId}`,
      state: {
        transactionDetails,
        transactionId: transactionDetails?.paymentToken,
      },
    })
  );
}

function* flightOrderSuccessCallback(action) {
  yield call(
    callAPI,
    BookingAPI.flightOrderSuccessCallback,
    action,
    generateTicketResult
  );
}

// function* getTicket(action) {
//   yield call(callAPI, BookingAPI.getTicket, action, generateTicketResult);
// }

function* generateTicketResult(result, payload) {
  yield call(setResult, result);

  if (result) {
    // If flight is one way then redirect to ticket queue page
    // OR
    // If flight is return and booked sector is return then redirect to ticket queue page

    if (
      payload &&
      (!payload.hasReturn || (payload.hasReturn && payload.isReturn))
    ) {
      const { transactionDetails } = payload;
      yield put(
        push({
          pathname: RouteConstant.TICKET_QUEUE,
          // state: {
          //   transactionDetails,
          //   transactionId: transactionDetails?.paymentToken,
          // },
        })
      );
    } else if (payload && payload.hasReturn) {
      // If flight is return and booked sector is one way then redirect to booking details page
      const {} = payload;
      yield put(
        push({
          pathname: `${RouteConstant.BOOKING_DETAILS}/${auth.searchId}`,
          state: {
            showReturn: true,
          },
        })
      );
    }
  }
}

function* getFlightDetails(action) {
  yield call(callAPI, BookingAPI.getFlightDetails, action, setFlightResult);
}

function* setFlightResult({ searchBy, ...rest }) {
  yield call(setResult, rest);
  yield put({ type: SearchFlight.SEARCH_FLIGHT_SUCCESS, result: { searchBy } });
}

function* getBookingDetails(action) {
  yield call(callAPI, BookingAPI.getBookingDetails, action, setResult);
}

function* setSelectedFlight(action) {
  yield call(setFlightDetails, action);
  yield put(push(`${RouteConstant.ADD_PASSENGER_DETAILS}/${auth.searchId}`));
}

function* setFlightDetails({ payload }) {
  const orderDetails = yield call(callAPI, BookingAPI.setFlightDetails, {
    payload: { ...payload, searchId: auth.searchId },
  });

  // yield put({
  //   type: BookFlight.SET_PAYMENT_ORDER_ID,
  //   payload: orderDetails.result.orderId,
  // });

  yield put(
    OrderActions.setPaymentAmount({
      amount: orderDetails.result.amount,
      orderId: orderDetails.result.orderId,
      isPayingOnline: false,
    })
  );

  yield call(setResult, payload);
  yield put({ type: BookFlight.SET_IS_BOOKING_IN_PROCESS, payload: true });
}

function* getPassengerView({ payload }) {
  const {
    selectedFlight,
    hasReturn,
    isReturn,
    resultIndex: combinedIndex,
  } = payload;
  const { traceId, resultIndex } = selectedFlight ? selectedFlight : {};
  const reqPayload = {
    TraceId: traceId,
    ResultIndex: combinedIndex ? combinedIndex : resultIndex,
    hasReturn,
    isReturn,
    searchId: auth.searchId,
  };

  // This will get countries list, nationalities, fareRules, fareQuote, ssr
  yield put({
    type: BookFlight.GET_PASSENGER_VIEW_OPTIONS,
    payload: reqPayload,
    hasNextCall: true,
  });

  // yield put({ type: BookFlight.GET_COUNTRY_LIST, hasNextCall: true });
  // yield put({ type: BookFlight.GET_PHONECODE_LIST, hasNextCall: true });
  // yield put({ type: BookFlight.GET_NATIONALITIES, hasNextCall: true });
  // yield put({
  //   type: BookFlight.GET_FARE_RULES,
  //   payload: reqPayload,
  //   hasNextCall: true,
  // });
  // } else {
  //   yield put({
  //     type: BookFlight.FARE_QUOTE,
  //     payload: reqPayload,
  //     hasNextCall: true,
  //   });
  // }
}

function* setResult(result) {
  yield put({ type: BookFlight.BOOK_FLIGHT_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(
    BookFlight.GET_PASSENGER_VIEW_OPTIONS,
    getPassengerViewOptions
  );
  yield takeLatest(BookFlight.GET_COUNTRY_LIST, getCountryList);
  yield takeLatest(BookFlight.GET_PHONECODE_LIST, getPhoneCodeList);
  yield takeLatest(BookFlight.GET_NATIONALITIES, getNationalities);
  yield takeLatest(BookFlight.GET_FARE_RULES, getFareRules);
  yield takeLatest(BookFlight.GET_SSR_DETAILS, getSSRDetails);
  yield takeLatest(BookFlight.FARE_QUOTE, fareQuote);
  // yield takeLatest(BookFlight.BOOK_FLIGHT, bookFlight);
  // yield takeLatest(BookFlight.GET_TICKET, getTicket);
  yield takeLatest(
    BookFlight.FLIGHT_ORDER_SUCCESS_CALLBACK,
    flightOrderSuccessCallback
  );
  yield takeLatest(BookFlight.GET_BOOKING_DETAILS, getBookingDetails);
  yield takeLatest(BookFlight.SET_SELECTED_FLIGHT, setSelectedFlight);
  yield takeLatest(BookFlight.SET_FLIGHT_DETAIL, setFlightDetails);
  yield takeLatest(BookFlight.GET_PASSENGERS_VIEW, getPassengerView);
  yield takeLatest(BookFlight.VALIDATE_BOOKING, validateBooking);
  yield takeLatest(BookFlight.GET_FLIGHT_DETAILS, getFlightDetails);
}
