import React from "react";
import Button from "@material-ui/core/Button";

const UserButton = (props) => {
  const {
    onClick,
    disabled,
    label,
    className,
    size,
    fullWidth,
    btnStyle,
    variant,
    icon,
  } = props;

  let btnSize = "medium";
  if ((size === "small") | "medium" | "large") {
    btnSize = size;
  }

  let btnFullWidth = true;
  if (fullWidth !== undefined) {
    btnFullWidth = fullWidth;
  }

  return (
    <Button
      size={btnSize}
      disabled={disabled}
      className={className}
      onClick={onClick}
      variant={variant ? variant : "contained"}
      color={props.color || "primary"}
      fullWidth={btnFullWidth ? Boolean(btnFullWidth) : false}
      style={btnStyle}
      label={props.label}
      id={props.id}
      // {...props}
    >
      {icon ? icon : null}
      {label}
    </Button>
  );
};
// const UserButton = (props) => {

//   const { onClick, disabled, label, className, size, fullWidth, btnStyle, variant } = props;

//   let btnSize = "medium";
//   if (size === "small" | "medium" | "large") {
//     btnSize = size;
//   }

//   let btnFullWidth = true;
//   if (fullWidth !== undefined) {
//     btnFullWidth = fullWidth;
//   }

//   return (
//     <Button
//       size={btnSize}
//       disabled={disabled}
//       className={className}
//       onClick={onClick}
//       variant={variant ? variant : "contained"}
//       color={props.color || "primary"}
//       fullWidth={btnFullWidth ? Boolean(btnFullWidth) : false}
//       style={btnStyle}
//       {...props}
//     >
//       {label}
//     </Button>
//   );
// };

export default UserButton;
