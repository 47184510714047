import React from "react";
import MuiTypography from "../Typography";
import MuiGrid from "../Grid/item";

const UserLabel = ({ label, value }) => {
  return (
    <MuiGrid container spacing={2}>
      <MuiGrid xs={6}>
        <MuiTypography variant="body2" body={label} />
      </MuiGrid>
      <MuiGrid xs={6}>
        <MuiTypography noWrap variant="body2" body={value} />
      </MuiGrid>
    </MuiGrid>
  );
};

export default UserLabel;
