import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function index({
  open,
  handleClose,
  message = "",
}: {
  open: boolean;
  handleClose: any;
  message?: string;
}) {
  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        color={
          message === "Password Changed! Redirecting to login page..."
            ? "success"
            : "error"
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default index;
