import { user } from "../actionTypes/userTypes";

export const SET_USER_DATA = (payload) => {
  return {
    type: user.SET_USER_DATA,
    payload,
  };
};

interface IuserLEPayload {
  userUUID: string;
}
export const SAGA_UPDATE_USER_LE_DATA = (payload: IuserLEPayload) => {
  return {
    type: user.SAGA_UPDATE_USER_LE_DATA,
    payload,
  };
};

export const UPDATE_USER_LE_DATA = (payload) => {
  return {
    type: user.UPDATE_USER_LE_DATA,
    payload,
  };
};

export const UPDATE_TAGENT_LOGO = (payload) => {
  return {
    type: user.UPDATE_TAGENT_LOGO,
    payload,
  };
};

export const LOGOUT = () => {
  return {
    type: user.LOGOUT,
  };
};

export const LOGIN = (data) => {
  return {
    type: user.LOGIN,
    payload: data,
  };
};

export const UPDATE_PASSWORD = (data) => {
  return {
    type: user.UPDATE_PASSWORD,
    payload: data,
  };
};

export const UPDATE_PHONE_NUMBER = (data) => {
  return {
    type: user.UPDATE_PHONE_NUMBER,
    payload: data,
  };
};

export const UPDATE_USER_REFERRAL_CODE = (data) => {
  return {
    type: user.UPDATE_USER_REFERRAL_CODE,
    payload: data,
  };
};

export const FORGET_PASSWORD = (data) => {
  return {
    type: user.FORGET_PASSWORD,
    payload: data,
  };
};
