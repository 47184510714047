import {
  postRequest,
  getRequest,
  partenerSippiAjax,
} from "api/tools/httpRequests";
import { consumerSupplierRequestStatusEnum } from "types/common.types";

export const getAllSuppliers = async (domainName?: string) => {
  const response = await postRequest(
    "supplier/getAllVisibleSuppliersWithConsumerRequests",
    { domainName }
  );
  return response;
};

export const getWebsiteOwnSuppliers = async () => {
  const response = await getRequest("supplier/getWebsiteOwnSuppliers", {});
  return response;
};

export const getSupplierDetails = async (data) => {
  return await postRequest("wl/user/supplierApproval_supplierDetails", data);
};

export const postEligibilityCriteria = async (data) => {
  return await postRequest(
    "wl/user/supplierApproval_acceptTermsAndConditions",
    data
  );
};

export const getEligibilityCriteria = async (data) => {
  return await postRequest(
    "wl/user/supplierApproval_getTermsAndConditions",
    data
  );
};

export const getSupplierCategoriesApiCall = async () => {
  return await getRequest("whitelabel/getSupplierCategories", {});
};

export const getFixedDepartureSuppliers = async () => {
  return await partenerSippiAjax("GET", "supplierManagement/suppliers", {
    supplierCategory: "ada",
  });
};

interface IsendRequestToSupplierFromConsumerWlProps {
  consumer_LEId: number;
  supplierId: number;
  generatedBy_wlUserId: number;
}

export const sendRequestToSupplierFromConsumerWl = async (
  props: IsendRequestToSupplierFromConsumerWlProps
) => {
  return await postRequest("supplier/consumerToSupplierRequests", props);
};

/**
 * this api is used to get the consumer supplier requests based on the supplier id
 * this is only used in the supplier dashboard
 */
export const getAllConsumerSupplierRequestsBySupplierId = async (
  page: number = 1
) => {
  return await getRequest(
    "supplier/supplierOnlyAccess/consumerToSupplierRequestsBasedOnSupplierId",
    { page }
  );
};

/**
 * this api is used to change legal entity relation configs
 */
export const changeRelationConfig = async ({
  userLEID,
  isTdsDeductable,
}: {
  userLEID: number;
  isTdsDeductable: boolean;
}) => {
  return await postRequest("supplier/setLERelationConfig", {
    isTdsDeductable,
    userLEID,
  });
};

/**
 * this api is used to get the consumers of logged in supplier
 */
export const getMyApiConsumers = async () => {
  return await getRequest("supplier/supplierOnlyAccess/supplierConsumers", {});
};

export const changeConsumerSupplierRequestStatus = async (props: {
  requestId: number;
  newRequestStatus: consumerSupplierRequestStatusEnum;
  consumerMongoSiteId: string;
}) => {
  return await postRequest(
    "supplier/supplierOnlyAccess/changeConsumerSupplierRequestStatus",
    props
  );
};

export const getLinkedSuppliers = () => {
  return getRequest("supplier/getLinkedSuppliersByConsumer", {});
};

export const changeConsumerSupplierMappingStatusFromConsumerSide =
  async (props: { supplierId: number; newStatus: boolean }) => {
    return await postRequest(
      "supplier/changeConsumerSupplierMappingStatusFromConsumerSide",
      props
    );
  };

// export const postKycDocuments = async(data)=>{
//     return await postRequest("user/supplierApproval_documentUpload",data)
// }
