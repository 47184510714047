import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "redux/sagas/root.saga";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { routerMiddleware } from "connected-react-router";
const { createBrowserHistory } = require("history");

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory({
  basename: "/app",
});

const persistConfig = {
  key: "appCoreRoot",
  storage,
  whitelist: [
    "user",
    // "common",
    "siteData",
    "userGroups",
    "searchFlight",
    "bookFlight",
    "ticket",
    "progress",
    "response",
    "payment",
    "halfSignup",
    "dmcJobTransfer",
    "userPermissions",
  ],

  blacklist: ["router", "supplier", "managePermission"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

// const middleware = [sagaMiddleware, routerMiddleware(history)]

// export const store = createStore(
//         persistedReducer,
//         compose(
//             applyMiddleware(...middleware),
//             (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__)
//             ? window.__REDUX_DEVTOOLS_EXTENSION__()
//             : f => f
//         ),
//     )

export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV === "development" ? true : false,
  devTools: process.env.NODE_ENV === "development" ? true : false,
  middleware: [sagaMiddleware, routerMiddleware(history)],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
