import React from "react";
import { Route, Redirect } from "react-router-dom";
import Topmenu from "components/common/header/TopNavbar";

import { useLocation } from "react-router-dom";
import { Footer } from "./footer/Footer";
import { getAuthToken } from "tools/functions";

const SecureRoute = ({ component: Component, noMenu = false, ...rest }) => {
  const authToken = getAuthToken();

  const location = useLocation();

  return (
    <>
      {!noMenu && <Topmenu />}
      <Route
        {...rest}
        render={(props) => {
          if (authToken) {
            return <Component {...props} />;
          } else {
            let redirectTo = "";
            if (location.pathname) {
              redirectTo = `/?redirectTo=${location.pathname}`;
            }
            return <Redirect to={`/login${redirectTo}`} />;
          }
        }}
      />
      {!noMenu && <Footer />}
    </>
  );
};
export default SecureRoute;
