export const AppConstant = {
  DATE_FORMAT: "dd/MM/yyyy",
  USER_KEY: "auth-token",
  SEARCH_ID: "searchId",
  SEARCH_CRITERIA: "searchCriteria",
  API_DATE_FORMAT: "YYYY-MM-DD",
  API_DATETIME_FORMAT: "YYYY-MM-DDTHH:mm:ss",
  SEARCH_API_DATETIME_FORMAT: "YYYY-MM-DDT00:00:00",
  TIME_DISPLAY_FORMAT: "HH:mm",
  DATE_DISPLAY_FORMAT: "ddd,DD MMM YYYY",
  DATE_TIME_DISPLAY_FORMAT: "ddd,DD MMM YYYY HH:mm",
  DAY_DISPLAY_FORMAT: "ddd",
  TICKET_SUBJECT: "E-Ticket",
  TICKET_TEXT: "E-Ticket",
};
