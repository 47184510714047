import React from "react";
import { OTHER_MODULE_BASE_URLS } from "tools/constants";

/**
 *
 * Created by Jasmohan on 8 April 2023
 * This component will hard reload to the home page
 * which will then use nginx to redirect to the laravel home page
 */
export const V2HomePage = () => {
  window.location.href = OTHER_MODULE_BASE_URLS.laravelCore;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        overflow: "hidden",
        zIndex: 9999,
      }}
    ></div>
  );
};
