// const { postRequest } = require("components/common/ajax");
const { postRequest, getRequest } = require("components/common/ajax");

export const getBankDetailsApi = async (data) => {
  return await postRequest("whitelabel/getBankDetails", data);
};

export const getActiveGatewayApi = async (data) => {
  return await postRequest("payment/getActiveGateway", data);
};

export const getRazorpayDetailsApi = async (data) => {
  return await postRequest("whitelabel/getRazorpayDetailsToUser");
};

export const getSippiCustomerEmailIdBySippiCustomerId = async (
  sippiCustomerId
) => {
  // console.log({ sippiCustomerId });
  return await getRequest(
    "whitelabel/getSippiCustomerEmailIdBySippiCustomerId",
    { sippiCustomerId }
  );
};
