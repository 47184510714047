import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { string } from "joi";

const initialState: {
  permissions: string[];
  lastSyncAt: Date | undefined;
  isUserSuperAdmin: boolean;
} = {
  permissions: [],
  lastSyncAt: undefined,
  isUserSuperAdmin: false,
};

const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    updateUserPermissionsInRedux: (
      state,
      action: PayloadAction<{
        permissions: string[];
        lastSyncAt: Date;
        isUserSuperAdmin: boolean;
      }>
    ) => {
      state.permissions = action.payload.permissions;
      state.lastSyncAt = action.payload.lastSyncAt;
      state.isUserSuperAdmin = action.payload.isUserSuperAdmin;
    },
    resetUserPermissionsInRedux: (state) => {
      state.permissions = [];
      state.lastSyncAt = undefined;
      state.isUserSuperAdmin = false;
    },
  },
});

export const USER_PERMISSIONS_ACTIONS = permissionSlice.actions;

export default permissionSlice.reducer;
