import { useAppSelector } from "flight-react/hooks/reduxHooks";

export default function Topmenu() {
  const siteData = useAppSelector((state) => state.siteData);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: siteData?.ssrHtml?.header,
        }}
      ></div>
    </>
  );
}
