import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export interface ISuppliersState {
  suppliersList: any[];
  websiteOwnSuppliers: any[];
  linkedSuppliers: any[];
}

const initialState: ISuppliersState = {
  suppliersList: [],
  websiteOwnSuppliers: [],
  linkedSuppliers: [],
};

export const SupplierSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    updateSuppliers: (state, action: PayloadAction<any>) => {
      state.suppliersList = action.payload;
    },
    updateWebsiteOwnSuppliers: (state, action: PayloadAction<any>) => {
      state.websiteOwnSuppliers = action.payload;
    },
    updateLinkedSuppliers: (state, action: PayloadAction<any>) => {
      state.linkedSuppliers = action.payload;
    },
  },
});

export const selectSupplierList = (state: RootState) =>
  state.supplier.suppliersList;

export const selectWebsiteOwnSuppliers = (state: RootState) =>
  state.supplier.websiteOwnSuppliers;

export const SupplierActions = SupplierSlice.actions;

export const SupplierReducer = SupplierSlice.reducer;
