import { nfsAxios } from "api/nfsAxios";
import { IAirline } from "components/afterLogin/dashboard/flightApis/AirlineDiscountMarkup/types";
import { getRequest, postRequest } from "components/common/ajax";

const BASE_URL = `markupAndDiscounts`;

export type IAirlineApiRes = {
  data: {
    success: boolean;
    result: IAirline[];
  };
};
export class MarkupAndDiscountApi {
  private _baseUrl = BASE_URL;

  static getAllAirlinesApiCall = async (): Promise<IAirlineApiRes> => {
    // return await proxyPartnerSippi("GET", `flights/allAirlines`, {});
    const allAirlines = await nfsAxios.post(`/master/getAirlinesStaticData`);

    const airlines: IAirline[] = allAirlines.data.result.map((a) => {
      return {
        text: a.airlineName,
        value: a.airlineCode,
        logoUrl: a.logoUrl,
      };
    });

    return {
      data: {
        success: true,
        result: airlines,
      },
    };
  };

  static getWlUserGroupsApiCall = async () => {
    return await getRequest(`markupDiscount/LEGroupsForWl`, {});
  };

  static getConsumerLEIdGroupMappingWithOwnerLEGroups = async (
    consumerLEId
  ) => {
    return await getRequest(
      "markupDiscount/getConsumerLEIdGroupMappingWithOwnerLEGroups",
      { consumerLEId }
    );
  };

  static setDefaultWlUserGroupApiCall = async (groupId: number) => {
    return await postRequest(`markupDiscount/setDefaultLEGroup`, {
      groupId: groupId,
    });
  };

  static mapLEIdWithWlLEGroupIdApiCall = async (
    groupId: number,
    LEId: number
  ) => {
    return await postRequest(`markupDiscount/mapLEIdWithLEGroupId`, {
      groupId,
      LEId,
    });
  };

  static saveWlUserGroupsApiCall = async (
    userGroup: string,
    groupId?: number
  ) => {
    // return await proxyPartnerSippi("POST", `${BASE_URL}/wlUserGroups`, {
    //   id: groupId,
    //   groupName: userGroup,
    // });
    return await postRequest(`markupDiscount/LEGroupsForWl`, {
      id: groupId,
      groupName: userGroup,
    });
  };

  static getDefaultAirlineMarkupApiCall = async () => {
    return await getRequest(`markupDiscount/defaultAirlineMarkup`, {});
  };

  static saveDefaultAirlineMarkup = async (data: any) => {
    return await postRequest(`markupDiscount/defaultAirlineMarkup`, data);
  };

  static deleteDefaultAirlineMarkipApiCall = async (markupId) => {
    return await postRequest(`markupDiscount/deleteDefaultAirlineMarkup`, {
      markupId,
    });
  };

  static getAirlineSpecificMarkups = async () => {
    return await getRequest(`markupDiscount/airlineSpecificMarkupForWl`, {});
  };

  static deleteAirlineSpecificMarkup = async (markupId: number) => {
    // return await proxyPartnerSippi(
    //   "DELETE",
    //   `${BASE_URL}/airlineSpecificMarkupForWl`,
    //   { markupId }
    // );
    return await postRequest(
      `markupDiscount/deleteAirlineSpecificMarkupForWl`,
      {
        markupId,
      }
    );
  };

  static saveAirlineSpecificMarkup = async (data: any) => {
    return await postRequest(`markupDiscount/airlineSpecificMarkupForWl`, data);
  };

  static saveFixedDepartureMarkup = async (data: any) => {
    // return await proxyPartnerSippi(
    //   "POST",
    //   `${BASE_URL}/fixedDepartureMarkup`,
    //   data
    // );
    return await postRequest(`markupDiscount/fixedDepartureMarkup`, data);
  };
  static getFixedDepartureMarkups = async (data: any) => {
    // return await proxyPartnerSippi(
    //   "GET",
    //   `${BASE_URL}/fixedDepartureMarkup`,
    //   data
    //   );
    return await getRequest(`markupDiscount/fixedDepartureMarkup`, data);
  };

  static deleteFixedDepartureMarkup = async (markupId: number) => {
    return await postRequest(`markupDiscount/deleteFixedDepartureMarkupForWl`, {
      markupId,
    });
  };
}
