import React, {useState, useEffect} from 'react'
import FloatingButton from "../../../components/Fab"
import MuiGrid from "../../../components/Grid/item"

export default function FilterButton ({filter,body,filterSm,bodySm}){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showFliter, setShowFilter] = useState(false)

    useEffect(() => {
        function handleResize() {
          setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize) 
      })
    
      // const toggleEmailModal = () => {
      //   setShowEmailModal(!showEmailModal);
      // };
    
    return(
        <>
        <MuiGrid xs={12} sm={filterSm} style={ (windowWidth <=600 && showFliter === false) ? {display: 'none'}:{}}>
            {filter}
        </MuiGrid>
        <MuiGrid xs={12} sm={bodySm} style={showFliter === true ? {display: 'none'}: {}}>
            {body}
        </MuiGrid>
        <div style={{position: 'relative'}}>
            {windowWidth <=600 ? (windowWidth <=600 && showFliter === false ? 
            <FloatingButton onClick={()=>setShowFilter(true)} label={'Filter'}/>
            : 
            <FloatingButton onClick={()=>setShowFilter(false)} label={'Close'}/>
            ): ''
            }
        </div>

        </>
    )
}