import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserCard } from "../../components/Container";
import { getTickets } from "../../actions/ticket";
import { Ticket } from "./Components";
import { UserButton } from "../../components/Button";
import { BookedFlightDetail } from "../BookedFlight/Components";
import { Filter } from "../Common";
import MuiGrid from "../../components/Grid/item";
import FilterFloatingButton from "../Flights/Components/filterButton";
import MuiTypography from "../../components/Typography";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { RouteConstant } from "../../utils/routeConstant";
import { Link } from "react-router-dom";
import { setIsBookingInProcess } from "../../actions/bookFlight";
import { UserDetailPopup } from "components/afterLogin/dashboard/UserDetailPopup";
import { CommonMenu } from "components/afterLogin/dashboard/CommonMenu";
import { useAppSelector } from "flight-react/hooks/reduxHooks";
import { LiveFlightTicketQueueExcel } from "./LiveFlightTicketQueueExcel";
export const ViewTicketScreen = (props) => {
  const dispatch = useDispatch();
  const { tickets } = useSelector((state) => state.ticket);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedUser, setSelectedUser] = useState();
  const [isCustomerPopupOpen, setIsCustomerPopupOpen] = useState(false);
  const user = useAppSelector((state) => state.user);

  const setFlight = (selectedFlight) => {
    setSelectedFlight(selectedFlight);
  };

  const handleBack = () => {
    setSelectedFlight(null);
  };

  useEffect(() => {
    let payload;

    if (props.changeRequests) {
      payload = {
        changeRequests: props.changeRequests,
      };
    }
    dispatch(getTickets(payload));

    localStorage.setItem("isBookingInProcess", false);
    dispatch(setIsBookingInProcess(false));

    try {
      let browserHistory = JSON.parse(localStorage.getItem("browserHistory"));

      if (browserHistory && browserHistory.length > 0) {
        if (
          browserHistory[browserHistory.length - 3].includes(
            RouteConstant.TRANSACTION_SCREEN
          )
        ) {
          // if user has came from payment screen
          // alert("back exists")
        }
      }
    } catch (error) {
      // Browserhistory is not present in localstorage
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CommonMenu />

      <UserCard>
        {selectedFlight ? (
          <>
            <MuiGrid>
              <UserButton label="Back" onClick={handleBack} />
            </MuiGrid>
            <MuiGrid container spacing={2}>
              <BookedFlightDetail selectedFlight={selectedFlight} />
            </MuiGrid>
          </>
        ) : (
          tickets && (
            <MuiGrid container spacing={2}>
              <FilterFloatingButton
                bodySm={8}
                body={
                  <>
                    <MuiGrid container>
                      <MuiGrid item xs={12}>
                        <MuiTypography
                          align="center"
                          variant="h4"
                          body={
                            <div className="d-flex justify-content-center">
                              {props.changeRequests
                                ? "Change Requests"
                                : "Air Ticket Queue"}
                              {user.userType === "staff" && (
                                <div>
                                  <LiveFlightTicketQueueExcel
                                    tickets={tickets}
                                  />
                                </div>
                              )}
                            </div>
                          }
                        />
                      </MuiGrid>
                    </MuiGrid>
                    {user.userType === "staff" && (
                      <UserDetailPopup
                        open={isCustomerPopupOpen}
                        handleClose={() => {
                          setIsCustomerPopupOpen(false);
                          setSelectedUser(undefined);
                        }}
                        selectedUser={selectedUser}
                      />
                    )}
                    {tickets.length > 0 ? (
                      <>
                        {tickets.map((c, indx) => (
                          <Ticket
                            key={indx}
                            flightDetail={c}
                            setFlight={setFlight}
                            handleSelectUser={
                              user.userType === "staff"
                                ? (userD) => {
                                    setIsCustomerPopupOpen(true);
                                    setSelectedUser(userD);
                                  }
                                : undefined
                            }
                            ticketIndex={indx}
                          />
                        ))}
                      </>
                    ) : (
                      <MuiGrid container>
                        <MuiGrid item xs={12}>
                          <div
                            style={{
                              margin: 15,
                              textAlign: "center",
                            }}
                          >
                            <ReportProblemIcon
                              style={{
                                fontSize: 30,
                                color: "red",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />
                            <MuiTypography
                              align="center"
                              variant="h5"
                              body={"No tickets yet..."}
                            />
                            <Link to={RouteConstant.SEARCH_FLIGHT}>
                              <UserButton
                                label="Book a Flight"
                                fullWidth={false}
                                className="mt-3"
                              />
                            </Link>
                          </div>
                        </MuiGrid>
                      </MuiGrid>
                    )}
                  </>
                }
                filterSm={4}
                filter={<Filter action={getTickets} />}
              />
            </MuiGrid>
          )
        )}
      </UserCard>
    </>
  );
};

export default ViewTicketScreen;
