import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        zIndex: 1,
      },
    },
   
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      left: theme.spacing(2),
    },
  }));

  export default function FloatingActionButtons({onClick,label}) {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <Fab 
        variant="round"
        onClick={onClick}
        className={classes.fab}
        size='medium'
        color={label === 'Close'? 'secondary':'primary'}
        >
          {label === 'Filter' && <FilterListRoundedIcon/>}
          {label === 'Close' && <CloseRoundedIcon/>}
        </Fab>
      </div>
    );
  }