import { useAppSelector } from "flight-react/hooks/reduxHooks";
import { useEffect } from "react";

export const useEjsHeaderFooter = (siteDateLoding: boolean) => {
  const siteData = useAppSelector((state) => state.siteData);

  function createDomElem<K extends keyof HTMLElementTagNameMap>(
    tagName: K,
    attributes: Partial<HTMLElementTagNameMap[K]>
  ) {
    const elem = document.createElement(tagName);

    Object.entries(attributes).forEach(([key, value]) => {
      elem.setAttribute(key, value);
    });

    if (tagName === "link") {
      document.head.appendChild(elem);
    } else {
      document.body.appendChild(elem);
    }
  }

  useEffect(() => {
    /**
     * Only rendering header footer once the siteData loading is complete
     */
    if (siteData?.brandingSettings?.ssrElements && !siteDateLoding) {
      let headerType = "header_V1";
      let footerType = "footer_V1";

      if (siteData?.brandingSettings?.ssrElements?.header) {
        headerType = siteData?.brandingSettings?.ssrElements.header;
      }

      if (siteData?.brandingSettings?.ssrElements?.footer) {
        footerType = siteData?.brandingSettings?.ssrElements.footer;
      }

      createDomElem("script", {
        src: `https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js`,
        type: "text/javascript",
      });

      createDomElem("link", {
        rel: "stylesheet",
        href: `/api/assets/css/ejsHeaderFooter/header/${headerType}.css`,
      });

      createDomElem("link", {
        rel: "stylesheet",
        href: `/api/assets/css/ejsHeaderFooter/footer/${footerType}.css`,
      });

      siteData?.scriptUrls?.forEach((scriptUrl: string) => {
        createDomElem("script", {
          src: scriptUrl,
        });
      });
    }
  }, [siteData, siteDateLoding]);

  return;
};
