import { useAppSelector } from "flight-react/hooks/reduxHooks";
import React from "react";

export const Footer: React.FC<{}> = () => {
  const siteData = useAppSelector((state) => state.siteData);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: siteData?.ssrHtml?.footer,
        }}
      ></div>
    </>
  );
};
