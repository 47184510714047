import { postRequest } from "components/common/ajax";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IVisaCategories } from "./types";
import { ListGroup } from "react-bootstrap";

export const VisaCategory: React.FC<{
  match: { params: { country?: string } };
}> = (props) => {
  const country = props?.match?.params?.country;
  const [visaCategories, setVisaCategories] = React.useState<IVisaCategories[]>(
    []
  );

  useEffect(() => {
    getVisaCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVisaCategories = async () => {
    const res = await postRequest("visa/getVisaCategory", {
      countryName: country,
    });

    if (!res.success) {
      return toast.error(
        res?.errorMessage || "Could not fetch visa categories"
      );
    }

    setVisaCategories(res?.result);
  };

  return (
    <div className="container d-flex align-items-center flex-column">
      <h1 className="h3  mt-4 fw-bold">{country} - Visa Categories</h1>

      <p>Select visa category</p>

      <ListGroup
        style={{ maxWidth: 600, width: 300 }}
        className="my-2 shadow rounded"
      >
        {visaCategories.map((visaCategory) => (
          <Link
            to={`/visa/${country}/${visaCategory.visaCategory}/${visaCategory._id}`}
            style={{ textDecoration: "none" }}
          >
            <ListGroup.Item
              key={visaCategory._id}
              className="fw-bold "
              variant="light"
            >
              {visaCategory.visaCategory}
            </ListGroup.Item>
          </Link>
        ))}
      </ListGroup>
    </div>
  );
};
