import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTicketDetails, updateBookingDetails } from "../../actions/ticket";
import { ETicket } from "./Components";
import { useReactToPrint } from "react-to-print";
import useForm from "../../hooks/useForm";
import { renderToString } from "react-dom/server";
import auth from "../../libs/auth";
import { sendMail } from "../../actions/common";
import { AppConstant } from "../../utils/constant";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SaveIcon from "@material-ui/icons/Save";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import PrintOutlined from "@material-ui/icons/PrintOutlined";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import { makeStyles } from "@material-ui/core";
import { useAppSelector } from "flight-react/hooks/reduxHooks";
import { RouteConstant } from "flight-react/utils/routeConstant";
const queryString = require("query-string");

const useStyles = makeStyles((theme) => ({
  primaryColor: {
    color: theme.palette.primary.main,
  },
}));

export default function ETicketScreen(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { bookingSlug } = useParams();

  const history = useHistory();

  const location = useLocation();
  const parsedParams = queryString.parse(location.search);
  const bookingId = atob(bookingSlug).split("___")[0];

  const [editFee, setEditFee] = useState(false);
  const hidePayment = parsedParams.hp === "true";
  const [editDiscount, setEditDiscount] = useState(false);
  // const [selectedBottomButton, setSelectedBottomButton] = useState();
  const [hidePaymentState, setHidePaymentState] = useState(
    hidePayment || false
  );

  const [mergeTxnFeeTaxes, setMergeTxnFeeTaxes] = useState(false);
  const [state, setState] = useState({
    passengers: [],
    baggages: [],
    meals: [],
  });

  const userData = useAppSelector((state) => state.user);

  const {
    state: transactionDetail,
    handleStateChange,
    handleChange,
    errors,
  } = useForm({}, {});
  const componentRef = useRef();
  const { ticketDetail } = useSelector((state) => state.ticket);

  const {
    passenger: initPassengers,
    fare: { txnFee: initFee, discount: initDiscount },
  } = ticketDetail ? ticketDetail : { fare: {} };

  useEffect(() => {
    handleStateChange({
      txnFee: initFee,
      discount: initDiscount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFee, initDiscount]);

  useEffect(() => {
    if (ticketDetail?.fare) {
      setMergeTxnFeeTaxes(ticketDetail.fare.userMergeTxnFeeTaxes || false);
    }
  }, [ticketDetail?.fare]);

  useEffect(() => {
    if (initPassengers)
      setState((prev) => ({
        ...prev,
        passengers: initPassengers.map((c) => ({
          ticketNo: c.ticket?.ticketNumber,
          paxName: `${c.firstName} ${c.lastName}`,
          validatingAirline: c.ticket?.validatingAirline,
        })),
        baggages: initPassengers.map((c) => c.baggage).flat(1),
        meals: initPassengers.map((c) => c.mealDynamic).flat(1),
      }));
  }, [initPassengers]);

  useEffect(() => {
    const decodeSlug = atob(bookingSlug);
    dispatch(getTicketDetails({ decodeSlug }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userData?.id) {
      setHidePaymentState(true);
    }
  }, [userData]);

  const toggleFee = () => {
    setEditFee(!editFee);
  };

  const toggleDiscount = () => {
    setEditDiscount(!editDiscount);
  };

  const toggleShowPayment = () => {
    setHidePaymentState((old) => !old);

    if (parsedParams.print && parsedParams.hp) {
      history.push(
        `${RouteConstant.E_TICKET}/${bookingSlug}?print=${
          parsedParams.print
        }&hp=${!hidePaymentState}`
      );
    }
  };

  const handleComponentPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    if (parsedParams.print) {
      handleComponentPrint();
      return;
    }

    let queryString;
    if (parsedParams.pax) {
      queryString = `&print=${true}&hp=${hidePaymentState}`;
    } else {
      queryString = `?print=${true}&hp=${hidePaymentState}`;
    }

    window.open(
      window.location.href + queryString,
      // `${
      //   RouteConstant.E_TICKET
      // }/${bookingSlug}?print=${true}&hp=${hidePaymentState}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (parsedParams.print && ticketDetail) {
      // window.print();
      setTimeout(() => {
        handleComponentPrint();
      }, 0);
    }
  }, [parsedParams.print, ticketDetail]);

  const toggleMergeTxnFeeTaxes = () => {
    setMergeTxnFeeTaxes(!mergeTxnFeeTaxes);
  };

  const handleUpdateFee = () => {
    const { txnFee } = transactionDetail;

    dispatch(
      updateBookingDetails({
        bookingId,
        "fare.txnFee": txnFee,
        "fare.userMergeTxnFeeTaxes": mergeTxnFeeTaxes,
      })
    );
    toggleFee();
  };

  const handleUpdateDiscount = () => {
    const { discount } = transactionDetail;
    dispatch(
      updateBookingDetails({
        bookingId,
        "fare.discount": discount,
      })
    );
    toggleDiscount();
  };

  const handleEmail = () => {
    const htmlString = renderToString(
      <ETicket
        {...state}
        {...transactionDetail}
        ticketLogoUrl={userData.taLogoUrl}
        singlePax={parsedParams.pax}
        mergeTxnFeeTaxes={mergeTxnFeeTaxes}
        hidePayment={hidePaymentState}
        ticketDetail={ticketDetail}
      />
    );

    const { email } = auth.loggedInUserDetails;
    dispatch(
      sendMail({
        name: email,
        to: email,
        subject: AppConstant.TICKET_SUBJECT,
        text: AppConstant.TICKET_TEXT,
        html: htmlString,
      })
    );
  };

  useEffect(() => {
    if (ticketDetail) {
      console.log(ticketDetail);
    }
  }, [ticketDetail]);

  return (
    <div>
      <div className="">
        <ETicket
          ref={componentRef}
          ticketLogoUrl={userData.taLogoUrl}
          editDiscount={editDiscount}
          editFee={editFee}
          hidePayment={hidePaymentState}
          toggleMergeTxnFeeTaxes={toggleMergeTxnFeeTaxes}
          mergeTxnFeeTaxes={mergeTxnFeeTaxes}
          {...state}
          {...transactionDetail}
          singlePax={parsedParams.pax}
          handleChange={handleChange}
          errors={errors}
          ticketDetail={ticketDetail}
        />
      </div>

      <div className="row">
        <div className="col-12">
          <BottomNavigation showLabels>
            {!parsedParams.pax && userData?.id && !parsedParams.print && (
              <BottomNavigationAction
                className={!editFee ? classes.primaryColor : undefined}
                onClick={editFee ? handleUpdateFee : toggleFee}
                label={`${editFee ? "Update" : "Add"}  Markup`}
                icon={editFee ? <SaveIcon /> : <AddCircleOutlineIcon />}
              />
            )}
            {!parsedParams.pax && userData?.id && !parsedParams.print && (
              <BottomNavigationAction
                className={!editDiscount ? classes.primaryColor : undefined}
                onClick={editDiscount ? handleUpdateDiscount : toggleDiscount}
                label={`${editDiscount ? "Update" : "Add"}  Discount`}
                icon={
                  editDiscount ? (
                    <SaveIcon />
                  ) : (
                    <IndeterminateCheckBoxOutlinedIcon />
                  )
                }
              />
            )}
            {!parsedParams.pax && userData?.id && !parsedParams.print && (
              <BottomNavigationAction
                className={!hidePaymentState ? classes.primaryColor : undefined}
                onClick={toggleShowPayment}
                label={`${hidePaymentState ? "Show" : "Hide"} Price`}
                icon={
                  hidePaymentState ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )
                }
              />
            )}

            <BottomNavigationAction
              id="printButton"
              className={classes.primaryColor}
              label="Print"
              onClick={handlePrint}
              icon={<PrintOutlined />}
            />

            {userData?.id && !parsedParams.print && (
              <BottomNavigationAction
                className={classes.primaryColor}
                label="Email"
                onClick={handleEmail}
                icon={<EmailOutlined />}
              />
            )}
          </BottomNavigation>
        </div>
      </div>
    </div>
  );
}
