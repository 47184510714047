import React, { useEffect, useState } from "react";
import { Genders } from "../../utils/genders";
import { getPaxType } from "../../utils/paxType";
import { UserLabel } from "../../components/Label";
import { formatDisplayDate, mapJSONData } from "../../libs/functions";
import MuiGrid from "../../components/Grid/item";

export default function PassengerDetails({ passenger: initPassengers }) {
  const [passengers, setPassengers] = useState([]);

  useEffect(() => {
    if (initPassengers) {
      setPassengers(
        mapJSONData(initPassengers).map((c) => ({
          type: getPaxType(c.paxType),
          // eslint-disable-next-line eqeqeq
          gender: Genders.find((g) => g.value == c.gender)?.text,
          name: `${c.title} ${c.firstName} ${c.lastName}`,
          contactNo: `${
            c.contactNo != null ? c.contactNo : c.phoneCode + c.mobileNo
          }`,
          address: c.addressLine1,
          dateOfBirth: formatDisplayDate(c.dateOfBirth),
          city: c.city,
          country: c.countryCode,
          email: c.email,
          passportNo: c.passportNo,
          passportIssueDate: c.passportIssueDate
            ? formatDisplayDate(c.passportExpiry)
            : "",
          passportExpiry: c.passportExpiry
            ? formatDisplayDate(c.passportExpiry)
            : "",
          passportCountry: c.passportIssueCountryCode,
        }))
      );
    }
  }, [initPassengers]);

  return (
    <MuiGrid container spacing={1} justifyContent="center">
      {passengers.map((c, index) => (
        <React.Fragment key={`${index + 1} ${c.type}`}>
          <MuiGrid xs={12}>
            Passenger {index + 1} ({c.type})
          </MuiGrid>
          <MuiGrid xs={12} sm={6}>
            <UserLabel label="Name" value={c.name} />
          </MuiGrid>
          <MuiGrid xs={12} sm={6}>
            <UserLabel label="Gender" value={c.gender} />
          </MuiGrid>
          {c.dateOfBirth && (
            <MuiGrid xs={12} sm={6}>
              <UserLabel label="DOB" value={c.dateOfBirth} />
            </MuiGrid>
          )}
          <MuiGrid xs={12} sm={6}>
            <UserLabel label="Mobile" value={c.contactNo} />
          </MuiGrid>
          <MuiGrid xs={12} sm={6}>
            <UserLabel label="Email" value={c.email} />
          </MuiGrid>
          {c.city !== "NA" && (
            <MuiGrid xs={12} sm={6}>
              <UserLabel label="City" value={c.city} />
            </MuiGrid>
          )}
          <MuiGrid xs={12} sm={6}>
            <UserLabel label="Country" value={c.country} />
          </MuiGrid>
          {c.passportNo && (
            <MuiGrid xs={12} sm={6}>
              <UserLabel label="Passport No" value={c.passportNo} />
            </MuiGrid>
          )}
          {c.passportIssueDate && (
            <MuiGrid xs={12} sm={6}>
              <UserLabel label="Passport Exp" value={c.passportIssueDate} />
            </MuiGrid>
          )}
          {c.passportExpiry && (
            <MuiGrid xs={12} sm={6}>
              <UserLabel label="Passport Exp" value={c.passportExpiry} />
            </MuiGrid>
          )}
          {c.passportCountry && (
            <MuiGrid xs={12} sm={6}>
              <UserLabel
                label="Passport Issue Country Code"
                value={c.passportCountry}
              />
            </MuiGrid>
          )}
        </React.Fragment>
      ))}
    </MuiGrid>
  );
}
