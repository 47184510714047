import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GET_WL_USER_WALLET_BALANCE } from "redux/actions/commonActions";
import { RouteConstant } from "flight-react/utils/routeConstant";
import { useAppSelector } from "flight-react/hooks/reduxHooks";
import { TileMenuBoxes } from "./TileMenuBoxes";
import { ISubmenu, ITileMenu } from "./StaffMenu";
import useFormConstantLabel from "flight-react/hooks/useFormConstantLabel";

const crmForms = [
  "FlightsForm",
  "VisaEnquiry",
  "OtherEnquiry",
  "Insurance",
  "Bus",
  "Hotels",
  "Cruise",
  "Train",
];

export default function UserMenu() {
  const dispatch = useDispatch();
  const fdLabel = useFormConstantLabel("fixedDepartureLabel");
  const { userWalletBalance } = useAppSelector((state) => state.common);
  const user = useAppSelector((state) => state.user);
  const siteData = useAppSelector((state) => state.siteData);
  const getTicketsSubMenu = () => {
    const ticketSubMenu: ISubmenu[] = [
      // {
      //   link: RouteConstant.TICKET_QUEUE,
      //   title: "Live Flight Bookings",
      // },
      // {
      //   link: RouteConstant.FIXED_DEPARTURE_QUEUE,
      //   title: `Flight Deal Bookings`,
      // },
      {
        link: "/flights/ticketQueue",
        title: <>Ticket Queue</>,
        isRedirecting: true,
      },
      {
        link: "/flights/changeRequestQueue",
        title: <>Change Request Queue</>,
        isRedirecting: true,
      },
    ];
    // if (siteData.wlMeta.isFlightV2Enabled || true) {
    //   ticketSubMenu.push(

    //   );
    // }
    return ticketSubMenu;
  };

  const [userMenuItems, setUserMenuItems] = useState<Array<ITileMenu>>([
    {
      link: "/user/dashboard",
      title: <>My Profile</>,
      open: false,
      icon: "fas fa-home",
      submenus: [],
    },
    {
      link: "#",
      title: <>Tickets</>,
      icon: "fas fa-plane",
      submenus: getTicketsSubMenu(),
      open: false,
    },
    {
      link: "/crm",
      title: <>CRM</>,
      isRedirecting: true,
      icon: "fas fa-list-alt",
      submenus: [],
      open: false,
    },
    {
      link: "/user/transactionHistory",
      title: (
        <>
          Wallet
          {/* ₹{" "}{userWalletBalance.totalAmount
            ? parseFloat(userWalletBalance.totalAmount)?.toFixed(2)
            : 0} */}
        </>
      ),
      icon: "fas fa-wallet",
      submenus: [],
      open: false,
    },
  ]);

  useEffect(() => {
    let showCrm = false;
    const actModulesIds = siteData.activeModules.map((am) => {
      if (crmForms.includes(am.siteModule.module_name) && !showCrm) {
        showCrm = true;
      }

      return am.siteModuleId;
    });
    setUserMenuItems(
      userMenuItems.filter((item) => {
        if (item.link === "/crm") {
          return showCrm;
        }
        return !item.siteModuleId || actModulesIds.includes(item.siteModuleId);
      })
    );
  }, [siteData]);

  useEffect(() => {
    dispatch(
      GET_WL_USER_WALLET_BALANCE({
        userId: user.id,
      })
    );
  }, [user]); //eslint-disable-line react-hooks/exhaustive-deps

  return <TileMenuBoxes menus={userMenuItems} />;
}
