import { useSelector } from "react-redux";

const usePassenger = () => {
  const { searchBy } = useSelector((state) => state.searchFlight);

  const getPassengerType = () => {
    if (searchBy) {
      const { adultCount, infantCount, childCount } = searchBy;
      return [
        ...[...new Array(adultCount ? adultCount : 0)].map((c, index) => ({
          type: `Adult ${index + 1}`,
          paxType: 1,
          passengerType: "Adult",
          isInfant: false,
          isChild: false,
        })),
        ...[...new Array(childCount ? childCount : 0)].map((c, index) => ({
          type: `Child ${index + 1}`,
          paxType: 2,
          isChild: true,
          passengerType: "Child",
          isInfant: false,
        })),
        ...[...new Array(infantCount ? infantCount : 0)].map((c, index) => ({
          type: `Infant ${index + 1}`,
          paxType: 3,
          isInfant: true,
          passengerType: "Infant",
          isChild: false,
        })),
      ];
    }

    return [];
  };

  return { getPassengerType };
};

export default usePassenger;
