import axios from "axios";
import { toast } from "react-toastify";
import { getAuthToken, redirection401 } from "tools/functions";
export const postRequest = async (url, data) => {
  try {
    const siteDataString = localStorage.getItem("siteData");
    const siteId = siteDataString ? JSON.parse(siteDataString)._id : null;

    const result = await axios({
      url: `/api/${url}`,
      method: "POST",
      data,
      headers: {
        "auth-token": getAuthToken(),
        "site-id": siteId,
      },
    });

    return result.data;
  } catch (err) {
    // if 401 redirect to /logout
    if (err.response.status === 401) {
      redirection401();
      return;
    }
    if (err.response.status === 400 && err?.response?.data?.errorMessage) {
      toast.error(err.response.data.errorMessage);
    }
    console.log("POST REQUEST FAILED \n\n", err);
  }
};

export const getRequest = async (url, params) => {
  try {
    const result = await axios({
      url: `/api/${url}`,
      method: "GET",
      params,
      headers: {
        "auth-token": getAuthToken(),
      },
    });

    return result.data;
  } catch (err) {
    console.log("GET REQ FAILED \n", err);
  }
};
