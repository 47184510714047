import { postRequest } from "api/tools/httpRequests";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const queryString = require("query-string");

const PhonepayVerification: React.FC<{
  closeGateway: Function;
}> = () => {
  const { orderId } = useParams<any>(); //sippi order id

  const [paymentLoadingState, setPaymentLoadingState] = useState(false);

  const history = useHistory();
  const parsedParams = queryString.parse(history.location.search);

  const checkForVerifyPayment = async (
    txnId: string,
    paymentVerifiedTimes = 0
  ) => {
    // const urlParams = new URLSearchParams(window.location.search);
    setPaymentLoadingState(true);

    const paymentRes = await postRequest("payment/phonepe/verifyPayment", {
      orderId: txnId,
    });

    if (paymentRes.data.success) {
      history.push("/user/payments/" + orderId);
      toast.success("Payment Successful");
    } else {
      if (paymentVerifiedTimes < 5) {
        setTimeout(async () => {
          await checkForVerifyPayment(txnId, paymentVerifiedTimes + 1);
        }, 2000);
      } else {
        toast.error("Payment failed, Please contact support");
        history.push("/user/payments/" + orderId);
      }
    }
    setPaymentLoadingState(false);
  };

  useEffect(() => {
    if (parsedParams.transaction_id) {
      checkForVerifyPayment(parsedParams.transaction_id);
    }
  }, [parsedParams.transaction_id]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 text-center mt-2">
          <Spinner animation="border" />
          <p className="mt-3">Verifying Payment from Bank</p>
          <p>Please donot refresh</p>
        </div>
      </div>
    </div>
  );
};
export default PhonepayVerification;
