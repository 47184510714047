export const FlightTime = [
  { text: "Any Time", value: "00:00:00" },
  {
    text: "Before 11AM",
    value: "08:00:00",
    startTime: "04:00:00",
    endTime: "11:00:00",
  },
  {
    text: "11AM to 4PM",
    value: "14:00:00",
    startTime: "11:00:00",
    endTime: "16:00:00",
  },
  {
    text: "4PM to 9PM",
    value: "19:00:00",
    startTime: "16:00:00",
    endTime: "21:00:00",
  },
  {
    text: "After 9PM",
    value: "01:00:00",
    startTime: "21:00:00",
    endTime: "04:00:00",
  },
];
