import React from "react";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  main: {
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-around",
    background: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "5px",
  },
}));

export default function FlightDetailSummary(props) {
  const { flight: fl } = props;

  const classes = useStyles();

  return (
    <div className={classes.main}>
      {fl.airline} - {fl.airlineCode} {fl.flightNumber}
      <br />
      Flight Status - {fl.flightStatus}
      <br />
      Cabin - {fl.cabinBaggage} &nbsp; &nbsp; Baggage - {fl.baggage}
      <div className={classes.flex}>
        <div>
          {fl.originCityName} ({fl.originAirportCode})
          <br />
          {fl.departureTime}
        </div>
        →
        <div>
          {fl.destinationCityName} ({fl.destinationAirportCode})
          <br />
          {fl.arrivalTime}
        </div>
      </div>
      {fl.stopPoint && (
        <div>
          <span className="text-danger">
            <span className="text-danger">
              Technical Stop at {fl.stopPoint}
              <br />
              Arrival: {moment(fl.stopPointArrivalTime).format("DD MMM")}{" "}
              {moment(fl.stopPointArrivalTime).format("HH:mm")}
              <br />
              Departure: {moment(fl.stopPointDepartureTime).format(
                "DD MMM"
              )}{" "}
              {moment(fl.stopPointDepartureTime).format("HH:mm")}
              <br />
            </span>{" "}
          </span>
        </div>
      )}
      <hr />
    </div>
  );
}
