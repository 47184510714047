import { siteDataTypes } from '../actionTypes/siteDataActionTypes'
const initialState = {

}
export default function SiteDataReducer(state = initialState, action) {
    switch (action.type) {

        case (siteDataTypes.SET_SITE_DATA):
            return action.payload;
        default:
            return state;
    }
}