import React from 'react'
import Link from '@material-ui/core/Link'

const LinkComponent = ({onClick,label})=> {
    return (
        <Link
        color='primary'
        onClick={onClick}
        variant='body1'
        >
            {label}
        </Link>
    )
}

export default LinkComponent;