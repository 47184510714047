export const getPaxType = (paxType) => {
  switch (paxType) {
    case 1:
    case "1":
      return "ADT";
    case 2:
    case "2":
      return "CHD";
    case 3:
    case "3":
      return "INF";
    default:
  }
};
