import { fork } from "redux-saga/effects";
import searchFlight from "./searchFlight";
import bookFlight from "./bookFlight";
import ticket from "./ticket";
import common from "./common";

export function* flightRootSaga() {
  yield fork(searchFlight);
  yield fork(bookFlight);
  yield fork(ticket);
  yield fork(common);
}
