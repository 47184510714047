import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import Topmenu from "components/common/header/TopNavbar";
import { Footer } from "../footer/Footer";

function useLockMode() {
  const siteData = JSON.parse(localStorage.getItem("siteData"));
  let lockMode =
    siteData.brandingSettings &&
    siteData.brandingSettings.form &&
    siteData.brandingSettings.form.lockMode;
  return [lockMode ? lockMode : false];
}

export default function PublicRoute({ comp: Component, ...rest }) {
  const [visible, setVisibility] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [lockMode] = useLockMode();

  useEffect(() => {
    if (lockMode === true && user.id === undefined && rest.locked) {
      history.push("/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <div style={{ minHeinght: "100vh" }}>
      <Topmenu
        toggleSlider={() => setVisibility(!visible)}
        sliderVisible={visible}
      />
      {rest.children ? (
        <Route
          path={rest.path}
          exact={rest.exact}
          locked={rest.exact}
          children={rest.children}
        />
      ) : (
        <Route
          path={rest.path}
          exact={rest.exact}
          locked={rest.exact}
          component={Component}
        />
      )}
      <Footer />
    </div>
  );
}
