import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";

// import Flights from "components/flights/index";
// import FixedDeparture from "components/fixedDepartures/fixedDepartures";

import PublicRoute from "./PublicRoutes/index";
// import PackageDetails from "../packages/packageDetails/index";

// import UserHome from "components/userHome";
// import Signup from "components/signup/Signup";
// import SignupSuccess from "components/signup/SignupSuccess";
// import Login from "components/login/Login";
// import Logout from "components/login/Logout";
// import Error404 from "components/common/tools/Error404";
import SecureRoute from "./SecureRoute";
// import Dashboard from "components/afterLogin/dashboard/Dashboard";
// import ManagePackageImages from "components/afterLogin/packages/manageImages/index.js";
// import GeneratePassword from "components/generatePassword";
import AlertMessage from "./tools/AlertMessage";

import { Route } from "react-router-dom";
// import Payments from "components/afterLogin/payments";
// import StaffRoutes from "./StaffRoutes";
// import UserProfile from "../afterLogin/dashboard/userProfile";
// import BecomeSupplier from "../afterLogin/dashboard/becomeSupplier";
import useScroll from "hooks/useScroll";
// import UserTransaction from "../../components/afterLogin/dashboard/userTransactions";
// import TermsAndConditions from "components/staticPages/TermsAndConditions";
// import PrivacyPolicy from "components/staticPages/PrivacyPolicy";
// import { RefundCancellationRefund } from "components/staticPages/RefundCancellationRefund";
import { paths } from "components/common/RouteConstants";
// import { BillingDetails } from "components/afterLogin/payments/BillingDetails";
// import { APIPartnerProgram } from "components/staticPages/APIPartnerProgram";
import { Spinner } from "./tools/Spinner";
import { CashfreeSuccess } from "components/afterLogin/payments/paymentGateways/cashfree/CashfreeSuccess";
import "flight-react/App.scss";
import { OnlyWithoutLoginRoute } from "./OnlyWithoutLoginRoute/OnlyWithoutLoginRoute";
import PhonepayVerification from "components/afterLogin/payments/paymentGateways/phonepe/PhonepeVerification";
import { V2HomePage } from "components/userHome/V2HomePage";
import Homepage from "components/userHome/homepage";
import { VisaCategory } from "visa/VisaCategory";
import { VisaChecklist } from "visa/VisaChecklist";
import { ETicketScreen } from "flight-react/containers/ViewTicket";
import { RouteConstant } from "flight-react/utils/routeConstant";
import { PermissionsEnum } from "types/RBAC/managePermission.types";
const ListPackages = lazy(() => import("./package/listPackages/index"));
const ListActivities = lazy(
  () => import("components/activities/listActivities/listActivities")
);
const Login = lazy(() => import("components/login/Login"));
const FixedDeparture = lazy(
  () => import("components/fixedDepartures/fixedDepartures")
);
const Flights = lazy(() => import("components/flights/index"));
const PackageDetails = lazy(() => import("../packages/packageDetails/index"));
const Signup = lazy(() => import("components/signup/Signup"));
const SignupSuccess = lazy(() => import("components/signup/SignupSuccess"));
const Logout = lazy(() => import("components/login/Logout"));
const GeneratePassword = lazy(() => import("components/generatePassword"));
const ManagePackageImages = lazy(
  () => import("components/afterLogin/packages/manageImages/index.js")
);
const Referral = lazy(
  () => import("../afterLogin/dashboard/staffDashboard/Referrals/Referral")
);
// const UserHome = lazy(() => import("components/userHome/homepage"));
const Dashboard = lazy(
  () => import("components/afterLogin/dashboard/Dashboard")
);

const UserProfile = lazy(() => import("../afterLogin/dashboard/userProfile"));
const BecomeSupplier = lazy(
  () => import("../afterLogin/dashboard/becomeSupplier")
);
const UserTransaction = lazy(
  () => import("../afterLogin/dashboard/userTransactions")
);
const TermsAndConditions = lazy(
  () => import("components/staticPages/TermsAndConditions")
);
const PrivacyPolicy = lazy(
  () => import("components/staticPages/PrivacyPolicy")
);
const StaffRoutes = lazy(() => import("./StaffRoutes"));
const Error404 = lazy(() => import("components/common/tools/Error404"));

const Error403 = lazy(() => import("components/common/tools/Error403"));

const RefundCancellationRefund = lazy(
  () => import("components/staticPages/RefundCancellationRefund")
);
const BillingDetails = lazy(
  () => import("components/afterLogin/payments/BillingDetails")
);
const APIPartnerProgram = lazy(
  () => import("components/staticPages/APIPartnerProgram")
);
const Payments = lazy(() => import("components/afterLogin/payments"));

const DmcPublicDriverJobSheet = lazy(
  () => import("dmcMaster/jobSheet/DMCDriverJobSheet")
);
const FixedDeparturePrint = lazy(
  () => import("flight-react/containers/FdQueue/FDTicket")
);

export default function Routes() {
  useScroll();
  return (
    <>
      <AlertMessage />

      <Suspense fallback={<Spinner loading="true" />}>
        <Switch>
          <PublicRoute path="/packages" comp={ListPackages} locked exact />
          <PublicRoute path="/activities" comp={ListActivities} locked exact />
          <PublicRoute path="/payments" comp={Payments} />
          {/* in the below route comp = undefined because it either accepts children or comp prop */}
          <PublicRoute
            exact
            // path={`${E_TICKET}/:bookingId`}
            path={`${RouteConstant.E_TICKET}/:bookingSlug`}
            comp={ETicketScreen}
          />
          <PublicRoute
            exact
            path={`${RouteConstant.PRINT_FIXED_DEPARTURE}/:fdSlug`}
            // path={`${PRINT_FIXED_DEPARTURE}/:fdid`}

            comp={FixedDeparturePrint}
          />
          <PublicRoute path="/flight" locked comp={undefined}>
            <Flights />
            <Route exact path="/flight/login" component={Login} />
          </PublicRoute>
          {/* Cashfree Success */}
          <SecureRoute
            path="/1/success"
            component={CashfreeSuccess}
            exact
            noMenu
          />
          <PublicRoute path="/fixed-departures" locked comp={undefined}>
            <FixedDeparture />
          </PublicRoute>
          <PublicRoute
            path="/packages/:packageName/:_id"
            comp={PackageDetails}
            locked
          />
          <PublicRoute path="/visa/:country" comp={VisaCategory} exact locked />
          <PublicRoute
            path="/visa/:country/:category/:categoryId"
            comp={VisaChecklist}
            locked
            exact
          />
          <OnlyWithoutLoginRoute exact path="/signup" comp={Signup} />
          <PublicRoute exact path="/signup_success" comp={SignupSuccess} />
          <OnlyWithoutLoginRoute exact path="/login" comp={Login} />
          <PublicRoute exact path="/logout" comp={Logout} />
          <PublicRoute
            exact
            path="/generate_password"
            comp={GeneratePassword}
          />
          {/* <PublicRoute exact path="/open-icici" comp={IciciPaymentGateway} />  */}

          <SecureRoute path="/staff" component={StaffRoutes} />
          <SecureRoute
            exact
            path="/user/dashboard/userProfile"
            component={UserProfile}
          />
          <SecureRoute
            exact
            path={paths.USER_DASHBOARD}
            component={Dashboard}
          />
          <SecureRoute
            exact
            path="/user/transactionHistory"
            component={UserTransaction}
          />
          <SecureRoute
            exact
            path="/user/supplier/becomeSupplier"
            component={BecomeSupplier}
          />
          <SecureRoute
            exact
            path="/user/dashboard/referral"
            component={Referral}
          />

          <SecureRoute
            exact
            path="/user/package/manage_package_images/:_id"
            component={ManagePackageImages}
          />

          <SecureRoute
            exact
            path="/user/payments/:orderId?"
            component={Payments}
          />
          <SecureRoute
            exact
            path="/phonepe/verify/:orderId?"
            component={PhonepayVerification}
          />
          <SecureRoute
            exact
            path="/user/billing-details"
            component={BillingDetails}
          />

          <PublicRoute
            exact
            path="/terms_and_conditions"
            comp={TermsAndConditions}
          />
          <PublicRoute exact path="/privacy_policy" comp={PrivacyPolicy} />
          <PublicRoute
            exact
            path="/cancellation_return_refund_policy"
            comp={RefundCancellationRefund}
          />
          <PublicRoute
            exact
            path="/api-partner-program"
            comp={APIPartnerProgram}
          />
          <PublicRoute path="/" comp={V2HomePage} exact locked />
          {/* <PublicRoute path="/" comp={Homepage} exact locked /> */}
          <PublicRoute
            path="/dmc/driver/:driverAccessToken"
            comp={DmcPublicDriverJobSheet}
            exact
            locked
          />
          <PublicRoute exact path="/403" comp={Error403} />
          <PublicRoute exact path="*" comp={Error404} />
        </Switch>
      </Suspense>
    </>
  );
}
