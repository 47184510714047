import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import { TextInput } from ".";
import _ from "lodash";

const CustomAutoComplete = ({
  label,
  getOptions,
  options: initOptions,
  value,
  errors,
  name,
  handleChange,
  labelKey,
  subTitleKey,
  valueKey,
  customClass = "",
  isBootstrapDesign = false,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const loading = open && options.length === 0;
  const dispatch = useDispatch();

  const onChange = (event, value) => {
    handleChange(name, value ? value[valueKey] : null);
    setSelectedOption(value);
  };

  const handleInputChange = (event, inputValue) => {
    setInputValue(inputValue);
    // setSelectedOption(null);
  };

  useEffect(() => {
    if (initOptions) {
      setOptions(initOptions);
    }
  }, [initOptions]);

  useEffect(() => {
    if (!value) {
      setInputValue("");
    } else {
      if (value) {
        let selectedOption = options.find((airp) => {
          return airp.cityCode === value;
        });
        if (selectedOption) {
          onChange(_, selectedOption);
        } else {
          setInputValue(value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    dispatch(getOptions({ searchBy: inputValue }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, open]);

  // React.useEffect(() => {

  // }, [open]);

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      // e.preventDefault();
      if (options.length > 0) {
        onChange(_, options[0]);
        // console.log(inputValue)
      }
    }
  };

  return (
    <Autocomplete
      open={open}
      className={customClass}
      onOpen={() => {
        if (inputValue.length > 0 && !selectedOption) {
          setOpen(true);
        } else if (selectedOption) {
          // alert("selected")
          // setInputValue(selectedOption[labelKey])
          setInputValue("");
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) =>
        `${option[labelKey]} (${option[subTitleKey]})`
      }
      getOptionSelected={(option, value) =>
        // eslint-disable-next-line eqeqeq
        option[valueKey] == value[valueKey] ||
        // eslint-disable-next-line eqeqeq
        option[labelKey] == value[labelKey]
      }
      renderOption={(option) => (
        <React.Fragment>
          {subTitleKey && (
            <>
              {option[subTitleKey]}
              &nbsp;
            </>
          )}
          <span> - {option[labelKey]}</span>
        </React.Fragment>
      )}
      onChange={onChange}
      value={selectedOption}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      options={options}
      loading={loading}
      onSelect={(val) => {
        console.log(val);
      }}
      onKeyDown={handleKeyDown}
      renderInput={(params) => (
        <TextInput
          inputProps={params}
          label={label}
          name={name}
          value={inputValue}
          handleChange={handleInputChange}
          errors={errors}
          size="small"
        />
      )}
    />
  );
};

export default CustomAutoComplete;
