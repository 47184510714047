import React from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { ErrorHelperList } from './ErrorHelperText';

export const DiscountMarkupTextField : React.FC<{
    name: string;
    label: string;
    value: {
        value: string | number;
        error:  Array<string>;
        validation: Array<string>;
    };
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
    textFieldProps?: TextFieldProps;
}> = (props) => {

    return (
        <>
            <TextField
                {...props.textFieldProps}
                name={props.name}
                value={props.value.value}
                label={props.label}
                // fullWidth
                size="small"
                error={props.value.error.length > 0}
                required={props.value.validation.includes("required")}
                onChange={props.onChange}
                variant="outlined"
            />
            <ErrorHelperList errors={props.value.error} />
        </>
    )
}
