export const CashfreeSuccess = () => {


  // useEffect(() => {
  //   setLoadingState(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div>
      <h1>Payment Success</h1>
    </div>
  );
};
