import { postRequest } from "components/common/ajax";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IVisaChecklist } from "./types";
import { Badge } from "react-bootstrap";

export const VisaChecklist: React.FC<{
  match: {
    params: { country?: string; category?: string; categoryId?: string };
  };
}> = (props) => {
  const country = props?.match?.params?.country;
  const category = props?.match?.params?.category;
  const categoryId = props?.match?.params?.categoryId;

  const [checklists, setChecklists] = React.useState<IVisaChecklist[]>([]);
  const [selectedChecklist, setSelectedChecklist] = React.useState<
    IVisaChecklist | undefined
  >(undefined);

  const [message, setMessage] = useState<{
    uriEncoded: string;
    plainText: string;
  }>({
    uriEncoded: "",
    plainText: "",
  });

  useEffect(() => {
    getVisaChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const msg = `Visa Checklist for ${country} - ${category} 
      -- Link: ${window.location.href}`;

    setMessage({
      uriEncoded: encodeURIComponent(msg),
      plainText: msg,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChecklist]);

  const findChecklistByCountry = (
    checklists: IVisaChecklist[],
    countryCode: string
  ) => {
    return checklists.find(
      (checklist) => checklist.nationality_countryId.countryCode === countryCode
    );
  };

  const getVisaChecklist = async () => {
    const res = await postRequest("visa/getVisaChecklist", {
      categoryId: categoryId,
    });

    if (!res.success) {
      return toast.error(res?.errorMessage || "Could not fetch visa checklist");
    }

    setChecklists(res?.result);
    const indianChecklist = findChecklistByCountry(res?.result, "IN");
    if (indianChecklist) {
      setSelectedChecklist(indianChecklist);
    } else if (res?.result?.length) {
      setSelectedChecklist(res?.results[0]);
    } else {
      toast.error("No checklist found for this category");
    }
  };

  return (
    <div className="container">
      <h2 className="h4 mt-2">
        <Badge>
          {country} - {category}
        </Badge>
      </h2>
      <div className="d-flex justify-content-between">
        <div style={{ maxWidth: "500px", width: 300 }}>
          <label>
            <b>Nationality</b>
          </label>

          <select className="form-select">
            {checklists.map((checklist) => (
              <option
                key={checklist._id}
                value={checklist.nationality_countryId._id}
              >
                {checklist.nationality_countryId.countryName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <a
            href={`https://wa.me/?text=${message.uriEncoded}`}
            className="btn btn-success m-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i> &nbsp;Share
          </a>
          <button
            className="btn btn-primary m-2"
            onClick={() => {
              navigator.clipboard.writeText(message.plainText);
              toast.success("Copied to clipboard");
            }}
          >
            Copy Link
          </button>
        </div>
      </div>
      {selectedChecklist && (
        <div
          dangerouslySetInnerHTML={{ __html: selectedChecklist?.ckeditor }}
          style={{ maxWidth: "100vw" }}
        ></div>
      )}
    </div>
  );
};
