const UserLabel = ({ label, value, prefix }) => {
  return (
    <div className="container-fluid my-2">
      <div className="row">
        <div className="col-6 col-md-5">
          <p>
            {prefix} {label}</p>
          {/* <Typography variant="body2" body={label} /> */}
        </div>
        <div className="col-6  col-md-7">
          <p>{value}</p>
          {/* <Typography noWrap variant="body2" body={value} /> */}
        </div>
      </div>
    </div>
  );
};

export default UserLabel;
