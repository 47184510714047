import React from "react";
import { UserHeader } from "../../../components/Header";
import { FlightDetail, PassengerDetail } from "../../Common";
import { FareRuleScreen } from "../../FareDetails";
import MuiGrid from "../../../components/Grid/item";

export default function BookedFlightDetail({ selectedFlight }) {
  return (
    <>
      <MuiGrid xs={12}>
        <UserHeader title="Flight Information" />
      </MuiGrid>
      <MuiGrid xs={12}>
        <FlightDetail selectedFlight={selectedFlight} />
      </MuiGrid>
      <FareRuleScreen fareRules={selectedFlight?.fareRules} />
      <MuiGrid xs={12}>
        <UserHeader title="Passengers Detail" />
      </MuiGrid>
      <MuiGrid xs={12}>
        <PassengerDetail passenger={selectedFlight?.passenger} />
      </MuiGrid>
    </>
  );
}
