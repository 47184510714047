import React from "react";
import MuiTypography from "../Typography";
import Paper from "@material-ui/core/Paper";
import MuiGrid from "../Grid/item";
import MuiGridContainer from "../Grid/Container";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: { padding: "1rem", margin: "0 auto" },
  paper: { padding: "0.5rem" },
  text: { textAlign: "center" },
}));

export default function UserCard({ children, title, subTitle = undefined }) {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <MuiGridContainer spacing={1} justifyContent="center">
          {title && (
            <>
              <MuiGrid item xs={12} sm={6}>
                <MuiTypography
                  variant="h5"
                  body={title}
                  className={`${classes.text} after-heading center`}
                />
              </MuiGrid>
              <MuiGrid item xs={12}></MuiGrid>
            </>
          )}
          <MuiGrid item xs={12} sm={6}>
            {subTitle && (
              <MuiTypography
                variant="h6"
                className={classes.text}
                body={subTitle}
              />
            )}
          </MuiGrid>
          {children}
        </MuiGridContainer>
      </Paper>
    </Container>
  );
}
