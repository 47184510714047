import { postRequest } from "components/common/ajax";
import { useAppSelector } from "flight-react/hooks/reduxHooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { UPDATE_PHONE_NUMBER } from "redux/actions/userActions";

export const useInvalidPhoneNumber = () => {
  const [showPhoneInvalidModal, setShowPhoneInvalidModal] = useState(false);
  const [updatingPhoneNumber, setUpdatingPhoneNumber] = useState(false);
  const user = useAppSelector((state) => state.user);
  const dispatch = useDispatch();

  const updatePhoneNumberFx = async (phoneDetails) => {
    setUpdatingPhoneNumber(true);
    const phoneNumbers = [
      {
        label: "Primary",
        phoneNumber: phoneDetails?.phone,
        phoneCountryCode: phoneDetails?.phoneCode,
      },
    ];
    await postRequest("user/changeMyPhoneDetails", {
      phoneNumbers,
    })
      .then((r) => {
        if (r.success) {
          toast.success("Successfully updated phone number");
          dispatch(UPDATE_PHONE_NUMBER(r.result));
        } else {
          throw new Error(r?.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(err?.message || "Unable to update phone number");
        console.log(err);
      })
      .finally(() => {
        setUpdatingPhoneNumber(false);
      });
  };

  useEffect(() => {
    const phoneObj = user?.phoneNumbers?.find((pn) => pn.label === "Primary");

    const isInvalid =
      !phoneObj?.phoneCountryCode || phoneObj?.phoneCountryCode === "INVALID";
    if (user.id && isInvalid) {
      const timer = setTimeout(() => {
        setShowPhoneInvalidModal(true);
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowPhoneInvalidModal(false);
    }
  }, [user]);

  return { showPhoneInvalidModal, updatingPhoneNumber, updatePhoneNumberFx };
};
