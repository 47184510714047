import React, { useState, useEffect } from "react";
import {
  getCustomers,
  toggleBlockCustomers,
  toggleFlightSearchExemption,
} from "api/user";
import {
  Typography,
  makeStyles,
  Button,
  Container,
  List,
  Divider,
  ListItem,
  Dialog,
  Chip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  Block,
  Create,
  Flight,
  MailOutline,
  Phone,
  Search,
} from "@material-ui/icons";
import { CustomerGroupChangeDialog } from "../CustomerGroupChangeDialog";
import { UserGroupsComponent } from "../UserGroups";
import { SET_LOADING } from "redux/actions/commonActions";
import { toast } from "react-toastify";
import { UserDetailPopup } from "../UserDetailPopup";
import Pagination from "@material-ui/lab/Pagination";
import { FullScreenLoader } from "../flightApis/AirlineDiscountMarkup/components/FullScreenLoader";
import { MarkupAndDiscountApi } from "api/flightApis/MarkupAndDiscount.api";
import ApiCustomerModal from "./ApiCustomerModal";
import { getMyApiConsumers } from "api/supplier";
import { UserSearch } from "../walletReport/UserSearch";
import { ChangeDetailsPopup } from "../ChangeDetailsPopup";
import Menu, { MenuItem, SubMenu } from "rc-menu";
import "./userMenuActions.css";
import { SelectRBACUserGroupModal } from "./SelectRBACUserGroupModal";
import { useAppSelector } from "flight-react/hooks/reduxHooks";
import { Form, Spinner } from "react-bootstrap";
import { postRequest } from "api/tools/httpRequests";

export enum SupplierCategoryEnum {
  FixedDeparture = "FixedDeparture",
  Flights = "Flights",
  TourPackages = "TourPackages",
}

const useStyles = makeStyles((theme) => ({
  thStyle: {
    fontWeight: "bold",
  },
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function CustomersList() {
  const classes = useStyles();
  const history = useHistory();
  // const [customers, setCustomers] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const siteData = useAppSelector((state) => state.siteData);

  const [customerTypeState, setCustomerTypeState] = useState<
    "customer" | "consumer"
  >("customer");
  const [customerPaginaion, setCustomerPaginationState] = useState<any>();
  const [apiConsumerListState, setApiConsumerListState] = useState<any>();
  const [currentPageState, setCurrentPageState] = useState(1);
  const [isCustomerGroupOpen, setIsCustomerGroupOpen] = useState(false);
  const [changeGroupDialogOpen, setChangeGroupDialogOpen] = useState(false);
  const { _id, isSuperAdmin } = useSelector((state: any) => state.user);

  const [selectedUser, setSelectedUser] = useState<any>();

  const [isCustomerPopupOpen, setIsCustomerPopupOpen] = useState(false);
  const [showChangeDetailsPopupOpen, setShowChangeDetailsPopupOpen] =
    useState(false);
  const [selectedLegalEntityId, setSelectedLegalEntityId] = useState<number>();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [filteredUserId, setFilteredUserId] = useState<number>();
  const [selectedUserLEGroupMappings, setSelectedUserLEGroupMappings] =
    useState<Array<any>>([]);

  const [selectedUserLEConfig, setSelectedUserLEConfig] = useState<{
    isTdsDeductable: boolean;
  }>({
    isTdsDeductable: false,
  });
  const [showApiCustomerModal, setShowApiCustomerModal] = useState(false);

  const userGroupsState = useSelector((state: any) => state.userGroups);

  const setUserData = (userDetails) => {
    setFilteredUserId(userDetails?.id);
  };

  const [showSelectPermissionGroupModal, setShowSelectPermissionGroupModal] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getCustomersFromDb();
    // getUserGroups()
  }, [currentPageState, filteredUserId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomersFromDb = async () => {
    setLoadingState(true);
    try {
      const result = await getCustomers({
        staffId: _id,
        page: currentPageState,
        userId: filteredUserId,
      });
      setCustomerPaginationState(result.data);
      // alert(JSON.stringify(result.data, null, 2));
    } catch (error) {
      console.log(error);
    }
    setLoadingState(false);
  };

  const getSupplierConsumers = async () => {
    setLoadingState(true);
    try {
      const supplierConsumers = await getMyApiConsumers();
      if (supplierConsumers.data.success) {
        setApiConsumerListState(supplierConsumers.data.result);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingState(false);
  };

  const setCustomerEmail = (email) => {
    console.log(email);
    history.push(`/staff/walletReport/?email=${email}`);
  };

  const toggleBlock = async (customerId, isBlocked) => {
    dispatch(SET_LOADING(true));
    try {
      const isUpdated = await toggleBlockCustomers({
        customerId,
        isBlocked,
      });

      if (isUpdated.data.success) {
        toast.success(
          `Customer successfully ${isBlocked ? "Unblocked" : "blocked"}`
        );
        setSelectedUser(undefined);
        setIsCustomerPopupOpen(false);
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error("Some error occurred");
    }

    await getCustomersFromDb();
    dispatch(SET_LOADING(false));
  };

  const showCustomerDetails = (customer) => {
    setSelectedUser(customer);
    setIsCustomerPopupOpen(true);
  };

  const toggleFlightSearchExemptionAction = async (
    userId: number,
    exempt: boolean
  ) => {
    dispatch(SET_LOADING(true));
    try {
      const isUpdated = await toggleFlightSearchExemption({
        customerId: userId,
        exempt: exempt,
      });

      if (isUpdated.data.success) {
        toast.success(
          `Customer successfully ${
            exempt ? "exempted from search" : "removed from search exemption"
          }`
        );
        setSelectedUser(undefined);
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error("Some error occurred");
    }

    await getCustomersFromDb();
    dispatch(SET_LOADING(false));
  };

  const handlePageChange = (event, value) => {
    if (value !== currentPageState) {
      setCurrentPageState(value);
    }
  };

  const getLegalEntityGroupMapping = async (LEId) => {
    // console.log({ LEId });
    dispatch(SET_LOADING(true));
    const response =
      await MarkupAndDiscountApi.getConsumerLEIdGroupMappingWithOwnerLEGroups(
        LEId
      );
    dispatch(SET_LOADING(false));
    if (!response.success) return toast.error(response.errorMessage);
    else {
      //map selected groups with groups modal
      setSelectedUserLEGroupMappings(response.result.userGroups);
      setSelectedUserLEConfig(response.result.leRelationConfig);
    }
  };

  const onFlightHoldSwitch = async (
    isFlightHoldAllowed: boolean,
    consumerSiteId: string,
    supplierListId: number
  ): Promise<boolean> => {
    const response = await postRequest(
      "supplier/supplierOnlyAccess/setFlightHoldSetting",
      {
        isFlightHold: isFlightHoldAllowed,
        consumerSiteId,
        supplierListId,
      }
    );

    if (response?.data?.success) {
      toast.success("setting saved successfully");
    }
    return response?.data?.success || false;
  };

  return (
    <>
      <FullScreenLoader show={loadingState} />
      {showChangeDetailsPopupOpen && (
        <ChangeDetailsPopup
          onClose={(shouldRefresh?: boolean) => {
            setShowChangeDetailsPopupOpen(false);
            setSelectedUser(undefined);
            if (shouldRefresh) {
              getCustomersFromDb();
            }
          }}
          selectedUser={selectedUser}
        />
      )}
      <UserDetailPopup
        showChangeDetails={() => {
          setIsCustomerPopupOpen(false);
          setShowChangeDetailsPopupOpen(true);
        }}
        open={isCustomerPopupOpen}
        handleClose={() => {
          setIsCustomerPopupOpen(false);
          setSelectedUser(undefined);
        }}
        selectedUser={selectedUser}
        handleToggleBlock={toggleBlock}
      />

      <Dialog
        maxWidth="md"
        open={isCustomerGroupOpen}
        onClose={() => setIsCustomerGroupOpen(false)}
      >
        <UserGroupsComponent />
      </Dialog>

      <CustomerGroupChangeDialog
        isB2B={
          selectedUser?.userType === "travelAgent" ||
          selectedUser?.userType === "staff" ||
          customerTypeState === "consumer"
        }
        open={changeGroupDialogOpen}
        LEGroupMappings={selectedUserLEGroupMappings}
        LERelationConfig={selectedUserLEConfig}
        getLegalEntityGroupMapping={getLegalEntityGroupMapping}
        onClose={() => setChangeGroupDialogOpen(false)}
        onChange={() => {
          if (customerTypeState === "consumer") {
            getSupplierConsumers();
          } else {
            getCustomersFromDb();
          }
        }}
        legalEntityId={selectedLegalEntityId}
        selectedGroupId={selectedGroupId}
      />
      <Container>
        <div className="text-center">
          <button
            className="btn btn-outline-primary"
            onClick={() => setIsCustomerGroupOpen(true)}
          >
            {" "}
            <i className="fas fa-sliders-h"></i>&nbsp; Markup Groups
          </button>{" "}
          <button
            className="btn btn-outline-success "
            onClick={() => setShowApiCustomerModal(true)}
          >
            <i className="fas fa-project-diagram"></i>&nbsp; API Customer
            Requests
          </button>
          <div className="text-center">
            <ul className="nav nav-pills justify-content-center mt-3">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    customerTypeState === "customer" ? "active" : ""
                  }`}
                  // aria-current="page"
                  onClick={() => {
                    getCustomersFromDb();
                    setCustomerTypeState("customer");
                  }}
                >
                  Site Customers
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link  ${
                    customerTypeState === "consumer" ? "active" : ""
                  }`}
                  onClick={() => {
                    getSupplierConsumers();
                    setCustomerTypeState("consumer");
                  }}
                >
                  Api Consumers
                </button>
              </li>
            </ul>
          </div>
          <p className="mt-4">
            Total Number of customers:{" "}
            {customerTypeState === "customer"
              ? customerPaginaion?.totalRecords
              : apiConsumerListState?.length || 0}{" "}
            Users
          </p>
        </div>

        {customerTypeState === "customer" ? (
          <>
            <UserSearch
              setFilterCustomer={(users) => {
                setCustomerPaginationState(users);
              }}
              setUserData={setUserData}
            />
            {customerPaginaion?.data?.records?.length > 0 ? (
              <>
                <List className={classes.root}>
                  {customerPaginaion?.data.records.map((c, index) => {
                    // const email = c.customer
                    //   ? c.customer.email
                    //   : c.company
                    //   ? c.company.primaryEmail
                    //   : "";
                    // const phone = c.customer
                    //   ? c.customer.phone
                    //   : c.company
                    //   ? c.company.primaryPhone
                    //   : "";
                    const email = c.email;
                    const phone = c?.phoneNumbers?.find(
                      (pn) => pn.label === "Primary"
                    )?.phoneNumber;
                    return (
                      <>
                        <ListItem
                          alignItems="flex-start"
                          className="mt-3 shadow"
                        >
                          <div className="container" style={{ fontSize: 12 }}>
                            <div className="row  align-items-center">
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12 text-center">
                                <React.Fragment>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => showCustomerDetails(c)}
                                  >
                                    <b>
                                      {c && c.companyName
                                        ? `${c.companyName} (#${c.users_id})`
                                        : `${(
                                            c.firstName +
                                            " " +
                                            c.lastName
                                          ).toUpperCase()}
                                           (#${c.users_id})`}
                                    </b>
                                    {/* {c.isBlocked ? (
                                      <p
                                        className={`ml-2 text-center`}
                                        style={{ color: "red" }}
                                      >
                                        <Block
                                          style={{
                                            fontSize: 20,
                                          }}
                                        />
                                      </p>
                                    ) : (
                                      ""
                                    )} */}
                                    {c.groupName ? (
                                      <div>
                                        <Chip
                                          label={c.groupName}
                                          size="small"
                                          color="primary"
                                        />
                                      </div>
                                    ) : (
                                      <p>
                                        <small>Default Group Applicable</small>
                                      </p>
                                    )}
                                  </div>
                                  ({c.userType})<br />
                                  {/* <div>
                                    <Button
                                      onClick={() => {
                                        setSelectedUser(c);
                                        setSelectedGroupId(
                                          c.userGroup?.groupId
                                        );
                                        setSelectedLegalEntityId(
                                          c.legalEntityId
                                        );
                                        getLegalEntityGroupMapping(
                                          c.legalEntityId
                                        );
                                        setChangeGroupDialogOpen(true);
                                      }}
                                    >
                                      {
                                        userGroupsState?.userGroups.find(
                                          (userGroup) =>
                                            c.userGroup?.groupId ===
                                            userGroup.id
                                        )?.groupName
                                      }{" "}
                                      <Create />
                                    </Button>
                                  </div> */}
                                  {c.userGroupName && (
                                    <div className="permission-group-name text-center">
                                      <div className="badge bg-dark">
                                        Permission Group : {c.userGroupName}{" "}
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              </div>

                              <div className=" col-lg-3 col-md-3 col-sm-6 col12 text-center ">
                                <small className="text-center">
                                  <MailOutline style={{ fontSize: 12 }} />{" "}
                                  {email}
                                  <br />
                                  {phone && (
                                    <Phone style={{ fontSize: 12 }} />
                                  )}{" "}
                                  {phone}
                                </small>
                              </div>

                              {/* <div item xs={2} lg={false} md={false}></div> */}
                              <div className=" col-lg-3 col-md-4 col-sm-12 col-12 row">
                                <div
                                  className={`d-flex justify-content-center`}
                                >
                                  <div className="d-inline-block mx-3">
                                    {c.status === "verified" ? (
                                      <div
                                        className={`text-center text-success`}
                                      >
                                        <MailOutline />
                                        <br />
                                        Verified
                                      </div>
                                    ) : (
                                      <div
                                        className={`text-center text-danger`}
                                      >
                                        <MailOutline />
                                        <br />
                                        Not Verified
                                      </div>
                                    )}
                                  </div>
                                  {c.flightLookToBook?.is_exempted ? (
                                    <div className="d-inline-block mx-3">
                                      <div
                                        className={`text-center text-success`}
                                      >
                                        <Search />
                                        <br />
                                        Exempted
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {c.isBlocked ? (
                                    <div className="d-inline-block mx-3">
                                      <div
                                        className="text-center"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          showCustomerDetails(c);
                                          // toggleBlock(c._id, c.isBlocked);
                                        }}
                                      >
                                        <small
                                          className={`text-center `}
                                          style={{ color: "red" }}
                                        >
                                          <Block />
                                          <br />
                                          Blocked
                                        </small>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="text-center">
                                  Registration Date:&nbsp;
                                  {moment(c.createdAt).format("DD MMMM, YYYY")}
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-2 col-sm-12 col-12 mt-1 text-center text-lg-right">
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={() =>
                                    setCustomerEmail(
                                      c && c.email
                                      // c && c.customer
                                      //   ? c.customer.email
                                      //   : c && c.company
                                      //   ? c.company.primaryEmail
                                      //   : ""
                                    )
                                  }
                                >
                                  <i className="fas fa-hand-holding-usd"> </i>
                                  &nbsp; Add Money
                                </button>
                                <Menu
                                  triggerSubMenuAction="click"
                                  className="user-actions-menu"
                                  style={{ cursor: "pointer" }}
                                >
                                  <SubMenu title="Actions">
                                    <MenuItem
                                      onClick={() => {
                                        showCustomerDetails(c);
                                      }}
                                    >
                                      View Details
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedUser(c);
                                        setSelectedGroupId(
                                          c.userGroup?.groupId
                                        );
                                        setSelectedLegalEntityId(
                                          c.legalEntityId
                                        );
                                        getLegalEntityGroupMapping(
                                          c.legalEntityId
                                        );
                                        setChangeGroupDialogOpen(true);
                                      }}
                                    >
                                      Change Markup Group
                                    </MenuItem>

                                    {isSuperAdmin &&
                                      siteData.wlMeta.canShowPermissionMenu && (
                                        <MenuItem
                                          onClick={() => {
                                            setSelectedUser(c);
                                            // setSelectedGroupId(
                                            //   c.userGroup?.groupId
                                            // );
                                            // setSelectedLegalEntityId(
                                            //   c.legalEntityId
                                            // );
                                            // getLegalEntityGroupMapping(
                                            //   c.legalEntityId
                                            // );
                                            // setChangeGroupDialogOpen(true);
                                            setShowSelectPermissionGroupModal(
                                              true
                                            );
                                          }}
                                        >
                                          Change Permission Group
                                        </MenuItem>
                                      )}
                                    <MenuItem
                                      onClick={() => {
                                        toggleFlightSearchExemptionAction(
                                          c.users_id,
                                          !c.flightLookToBook?.is_exempted
                                        );
                                      }}
                                    >
                                      {c.flightLookToBook?.is_exempted
                                        ? "Remove L2B Exemption"
                                        : "Exempt L2B"}
                                    </MenuItem>
                                  </SubMenu>
                                </Menu>
                                {/* To Be Deleted Later */}
                                {/* <Button
                                  variant="text"
                                  color="primary"
                                  onClick={() => {
                                    showCustomerDetails(c);
                                  }}
                                >
                                  {c.isBlocked ? "Unblock" : "Block"} User
                                </Button> */}
                                {/* <Button
                                  variant="text"
                                  color="primary"
                                  onClick={() => {
                                    toggleFlightSearchExemptionAction(
                                      c.users_id,
                                      !c.flightLookToBook?.is_exempted
                                    );
                                  }}
                                >
                                  {c.flightLookToBook?.is_exempted
                                    ? "Remove L2B Exemption"
                                    : "Exempt L2B"}
                                </Button> */}
                              </div>
                            </div>
                            {/* {c.userGroupName && (
                              <div className="permission-group-name text-center">
                                <div className="badge bg-dark">
                                  Permission Group Name : {c.userGroupName}{" "}
                                </div>
                              </div>
                            )} */}
                          </div>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    );
                  })}
                </List>
                <div
                  style={{
                    marginBottom: 50,
                  }}
                >
                  <Pagination
                    style={{
                      float: "right",
                      marginTop: 15,
                    }}
                    color="primary"
                    count={customerPaginaion?.totalPages || 0}
                    page={currentPageState}
                    onChange={handlePageChange}
                  />
                </div>
              </>
            ) : (
              <Typography> No Reults found!</Typography>
            )}
          </>
        ) : (
          <>
            {/* API COnsumers List */}
            {apiConsumerListState?.length > 0 ? (
              <>
                <List className={classes.root}>
                  {apiConsumerListState.map((c, index) => {
                    // const email = c.customer
                    //   ? c.customer.email
                    //   : c.company
                    //   ? c.company.primaryEmail
                    //   : "";
                    // const phone = c.customer
                    //   ? c.customer.phone
                    //   : c.company
                    //   ? c.company.primaryPhone
                    //   : "";
                    const email = c.wl_adminEmail;
                    const phone = c.wl_adminContact;

                    return (
                      <>
                        <ListItem
                          alignItems="flex-start"
                          className="mt-3 shadow"
                        >
                          <div className="container" style={{ fontSize: 12 }}>
                            <div className="row justify-content-center align-items-center">
                              <div className="col-md-3 col-sm-6 text-center">
                                <React.Fragment>
                                  <div>
                                    {c.wl_companyName}({c.wl_domain})
                                  </div>
                                  {c.leGroup_groupName && (
                                    <div>
                                      <Chip
                                        label={c.leGroup_groupName}
                                        size="small"
                                        color="primary"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <Button
                                      onClick={() => {
                                        setSelectedUser(c);
                                        setSelectedLegalEntityId(
                                          c.wl_legalEntityId
                                        );
                                        getLegalEntityGroupMapping(
                                          c.wl_legalEntityId
                                        );
                                        setChangeGroupDialogOpen(true);
                                      }}
                                    >
                                      {
                                        userGroupsState?.userGroups.find(
                                          (userGroup) =>
                                            c.userGroup?.groupId ===
                                            userGroup.id
                                        )?.groupName
                                      }{" "}
                                      <Create />
                                    </Button>
                                  </div>
                                </React.Fragment>
                              </div>

                              <div className="col-md-3">
                                {email}
                                <br />
                                {phone}
                              </div>

                              <div className="col-md-3 col-sm-6 text-center ">
                                <small className="text-center">
                                  {c.sup_category}
                                </small>
                              </div>

                              {c.sup_category ===
                                SupplierCategoryEnum.Flights && (
                                <div className="col-md-3 col-sm-6 text-center">
                                  <FlightHoldSwitchButton
                                    flightHoldAllowed={
                                      c.sc_map_isFlightHoldAllowed
                                    }
                                    onSwitchChange={(isFlightHold) => {
                                      return onFlightHoldSwitch(
                                        isFlightHold,
                                        c.wl_mongoDb_siteId,
                                        c.sc_map_supplierListId
                                      );
                                    }}
                                  />

                                  {/* <div className="col-md-3 col-sm-6 text-center">
                                  <Form>
                                     <label htmlFor="">Hold Allowed</label>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      checked={c.sc_map_isFlightHoldAllowed}
                                      onChange={async (e) => {
                                        setLoadingState(true);
                                        const response = await postRequest(
                                          "supplier/supplierOnlyAccess/setFlightHoldSetting",
                                          {
                                            isFlightHold: e.target.checked,
                                            consumerSiteId: c.wl_mongoDb_siteId,
                                            supplierListId:
                                              c.sc_map_supplierListId,
                                          }
                                        );

                                        if (response.data.success) {
                                          toast.success(
                                            "setting saved successfully"
                                          );
                                        } else {
                                          toast.error(
                                            "error occurred while saving"
                                          );
                                        }
                                        setLoadingState(false);
                                      }}
                                    />
                                  </Form>
                                </div> */}
                                </div>
                              )}

                              <div className="col-md-2 mt-1 text-center text-lg-right">
                                {/* <button
                                  className="btn btn-success btn-sm"
                                  onClick={() =>
                                    setCustomerEmail(
                                      c && c.customer
                                        ? c.customer.email
                                        : c && c.company
                                        ? c.company.primaryEmail
                                        : ""
                                    )
                                  }
                                >
                                  <i
                                    className="fas fa-hand-holding-usd"
                                    style={{ fontSize: 20 }}
                                  >
                                    {" "}
                                  </i>
                                  &nbsp; Add Money
                                </button> */}
                              </div>
                            </div>
                          </div>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    );
                  })}
                </List>
                {/* <div
                  style={{
                    marginBottom: 50,
                  }}
                >
                  <Pagination
                    style={{
                      float: "right",
                      marginTop: 15,
                    }}
                    color="primary"
                    count={customerPaginaion?.totalPages || 0}
                    page={currentPageState}
                    onChange={handlePageChange}
                  />
                </div> */}
              </>
            ) : (
              <Typography> No Reults found!</Typography>
            )}
          </>
        )}
      </Container>
      {showApiCustomerModal && (
        <ApiCustomerModal
          show={showApiCustomerModal}
          handleClose={() => setShowApiCustomerModal(false)}
        />
      )}

      {showSelectPermissionGroupModal && (
        <SelectRBACUserGroupModal
          show={showSelectPermissionGroupModal}
          onClose={() => setShowSelectPermissionGroupModal(false)}
          userId={selectedUser?.users_id}
          // selectedUser={selectedUser}
        />
      )}
    </>
  );
}

export const FlightHoldSwitchButton: React.FC<{
  flightHoldAllowed: boolean;
  onSwitchChange: (isFlightHold: boolean) => Promise<boolean>;
}> = (props) => {
  const [isFlightHold, setIsFlightHold] = useState(props.flightHoldAllowed);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div>
        <label htmlFor="">Flights Hold Allowed</label>
        <div className="d-flex justify-content-center">
          <Form.Check
            type="switch"
            id="custom-switch"
            role="button"
            checked={isFlightHold}
            onChange={async (e) => {
              setIsLoading(true);
              const checked = e.target.checked;
              const success = await props.onSwitchChange(checked);
              setIsLoading(false);

              if (success) {
                setIsFlightHold(checked);
              }
            }}
          />
          {isLoading && <Spinner size="sm" />}
        </div>
      </div>
    </>
  );
};
