import { takeLatest, call, put } from "redux-saga/effects";
import CommonAPI from "../api/CommonAPI";
import callAPI from "./helper";
import { Common } from "../actionTypes/common";
import { toast } from "react-toastify";

function* sendEmail(action) {
  yield call(callAPI, CommonAPI.sendEmail, action, setResult);
}

function* setResult(result) {
  toast.success("Email successfully sent.")
  yield put({
    type: Common.SET_API_RESPONSE,
    result: { message: "Email sent", success: true },
  });
}
 
export default function* rootSaga() {
  yield takeLatest(Common.SEND_EMAIL, sendEmail);
}
